import _ from "lodash"
import React from "react"
import styled from "styled-components"
import { useApp } from "../../../store/app/selectors"
import Top from "./top"
import Column from "./column"
import Drawer from "../../features/drawer"
import GoToItem from "./go_to_item"
import { Button } from "../../elements"
import HomeIcon from "@material-ui/icons/Home"

// ========================================= //
// Style
// ========================================= //

const WIDTH = 250

const TopSpacer = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.section.border};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`

const Main = styled.div`
  /* display: flex;
  flex: 3 3 auto; */
  width: ${WIDTH}px;
  /* display: block; */
  /* height: 100%;
  overflow: hidden; */
  height: 100%;
  overflow: hidden;
`

const Home = styled(Button.NavigateTo)`
  opacity: 1;
  & svg {
    transition: all 0.1s;
    transition-delay: 0s;
    /* opacity: 0; */
  }
  &.active {
    opacity: 0.2;
    svg {
      transition: all 0.3s;
      transition-delay: 0.2s;
    }
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Navigator = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [activeIDs] = useApp(["activeIntentions"])
  const activeID = _.last(activeIDs)

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <Top z={201} />
      <Drawer path="show.navigator" side="left" z={200} className={className}>
        <TopSpacer>
          <Home
            name="Go home"
            Icon={HomeIcon}
            itemID={"intentions"}
            size="small"
          />
          <GoToItem />
        </TopSpacer>
        <Main>
          <Column id={activeID} width={WIDTH} />
        </Main>
      </Drawer>
    </>
  )
}

export default Navigator
