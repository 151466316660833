import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import ItemOptionsMenu from "../../elements/item_options_menu"
import SetMenuItemOpen from "../../elements/set_menu_item_open"
import SetSetting from "../../elements/set_setting"
import SetActive from "../../elements/set_active"
import BaseItem, {
  CardStyled,
  showOnCardHover,
  verticalCenter,
} from "../../elements/base_item"
import ToggleArrow from "../../elements/set_active_button"
import Name from "../../elements/name"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"

// ========================================= //
// Style
// ========================================= //

const BaseItemStyled = styled(BaseItem)`
  margin: 0;
  border-radius: 0;
  /* padding: 5px 10px;  */
  /* margin-top: -1px; */
  grid-template-columns: 20px auto 20px 30px;
  border-right: none;
  border-left: none;
  border-top: none;
  box-shadow: none;
  cursor: pointer;
  &:active {
    z-index: 10;
  }
`

const NameStyled = styled(Name)`
  grid-area: name;
  font-size: 12px;
`

const hoverColor = css`
  &:hover {
    color: rgb(93, 149, 171);
    background: none;
  }
`

const ToggleArrowStyled = styled(ToggleArrow)`
  grid-area: arrow;
  height: 100%;
  ${verticalCenter};
  ${showOnCardHover};
  ${hoverColor}
  opacity: ${props => (props.expanded ? 1 : 0)};
  transition: all 0.3s;
  ${props =>
    props.expanded &&
    css`
      transform: rotate(180deg);
    `}
  
`

const ActiveMark = styled.div`
  grid-area: arrow;
  transition: all 0.3s;
  ${props =>
    props.isOpen &&
    css`
      border-right: 5px solid ${props => props.theme.element.item.active};
      opacity: 1;
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const Item = ({ id, parentID, isOpen = () => {}, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [expanded] = useApp(["activeIntentions"], ids => _.includes(ids, id))

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <ItemOptionsMenu id={id} parentID={parentID} useContextMenu={true}>
      <SetSetting path={"currentItemID"} value={id}>
        <BaseItemStyled cardOptions={{}}>
          <NameStyled id={id} tagName="span" listID={"writer-list"} />
          <ToggleArrowStyled id={id} parentID={parentID} expanded={expanded}>
            <KeyboardArrowRightIcon />
          </ToggleArrowStyled>
          <ActiveMark isOpen={isOpen(id)} />
        </BaseItemStyled>
      </SetSetting>
    </ItemOptionsMenu>
  )
}

export default Item
