import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItem } from "../../../store/items/selectors"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import SearchItems from "../../elements/search_items"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50vw - 75;
  padding: 10px 20px 10px 20px;
  h1,
  h2 {
    font-weight: normal;
    margin: 5px 0 10px 0;
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
    border-bottom: 1px black solid;
    border-bottom: 1px #ebebeb solid;
    padding-bottom: 5px;
  }
`

const Form = styled("form")`
  margin-bottom: 20px;
  padding: 20px;
  background: #f5f5f5;
  border: 1px #e8e7e7 solid;
  input {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    border: 1px #f3eeee solid;
  }
`

const Results = styled.div`
  opacity: 0;
  transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      max-height: inherit;
    `}
  & > div {
    font-size: 14px;
    /* padding: 5px; */
    line-height: 25px;
    width: 600px;
    /* display: flex;
    align-items: center; */
  }
  strong {
    font-size: 12px;
  }
  button {
    margin-top: 20px;
    color: #65a3da;
    border-color: #65a3da;
  }
`

const AlreadyAdded = styled(CheckCircleIcon)`
  color: #6ca56c;
  font-size: 15px;
  margin-left: 5px;
  margin-bottom: -3px;
`

// ========================================= //
// Main Component
// ========================================= //

const Config = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [settings, actions] = useItem([id, "view", "philpapers"])
  const [vals, setVals] = useState(
    _.defaults({}, settings, {
      cors_api_url: "https://cors-anywhere.herokuapp.com/",
      papersID: false,
      philosophersID: false,
    })
  )

  console.log(vals)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleChange = (event) => {
    const { name, value } = event.target
    set(name, value)
  }

  const set = (name, value) => {
    setVals(_.merge({}, vals, { [name]: value }))
  }

  const handleSave = (event) => {
    event.preventDefault()
    actions.updateItem({ id, view: { philpapers: vals } })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className}>
      <Inner>
        <Form onSubmit={handleSave}>
          <input
            type="text"
            placeholder="CORS api url "
            name={"cors_api_url"}
            value={vals.cors_api_url}
            onChange={handleChange}
          />
          <SearchItems
            label="Select parent item for papers"
            defaultValue={vals.papersID}
            onChange={(item) => set("papersID", item.id)}
          />
          <SearchItems
            label="Select parent item for philosophers"
            defaultValue={vals.philosophersID}
            onChange={(item) => set("philosophersID", item.id)}
          />
          <Button variant="outlined" type="submit" value="Submit">
            Save Settings
          </Button>
        </Form>
      </Inner>
    </Container>
  )
}

export default Config
