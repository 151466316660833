import _ from "lodash"
import React from "react"
import { Editor, Transforms, Range, Point } from "slate"

// ========================================= //
// Character/element bindings
// ========================================= //

const SHORTCUTS = {
  "*": "bold",
  "-": "list-item",
  "+": "list-item",
  ">": "block-quote",
  "#": "heading-one",
  "##": "heading-two",
  "###": "heading-three",
  "####": "heading-four",
  "#####": "heading-five",
  "######": "heading-six",
}

// ========================================= //
// Main plugin
// ========================================= //

const withShortcuts = (editor) => {
  const { deleteBackward, insertText } = editor

  // set the render elements
  _.map(SHORTCUTS, (type) => (editor.plugins.elements[type] = MarkdownElements))

  // ========================================= //
  // Insert
  // ========================================= //

  editor.insertText = (text) => {
    const { selection } = editor

    if (text === " " && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)
      const type = SHORTCUTS[beforeText]

      if (type) {
        Transforms.select(editor, range)
        Transforms.delete(editor)
        Transforms.setNodes(
          editor,
          { type },
          { match: (n) => Editor.isBlock(editor, n) }
        )

        if (type === "list-item") {
          const list = { type: "bulleted-list", children: [] }
          Transforms.wrapNodes(editor, list, {
            match: (n) => n.type === "list-item",
          })
        }

        return
      }
    }

    insertText(text)
  }

  // ========================================= //
  // Backward
  // ========================================= //

  editor.deleteBackward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          block.type !== "paragraph" &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, { type: "paragraph" })

          if (block.type === "list-item") {
            Transforms.unwrapNodes(editor, {
              match: (n) => n.type === "bulleted-list",
              split: true,
            })
          }

          return
        }
      }

      deleteBackward(...args)
    }
  }

  return editor
}

// ========================================= //
// Custom elements
// ========================================= //

export const MarkdownElements = ({ attributes, children, element }) => {
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>
    case "heading-four":
      return <h4 {...attributes}>{children}</h4>
    case "heading-five":
      return <h5 {...attributes}>{children}</h5>
    case "heading-six":
      return <h6 {...attributes}>{children}</h6>
    case "list-item":
      return <li {...attributes}>{children}</li>
    case "italic":
      return <i {...attributes}>{children}</i>
    default:
      return <p {...attributes}>{children}</p>
  }
}

export default withShortcuts
