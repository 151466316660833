import _ from "lodash"
import React from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { useApp } from "../../../store/app/selectors"

// ========================================= //
//  Main component
// ========================================= //

const Hotkeys = () => {
  // ========================================= //
  // State
  // ========================================= //

  const [app, actions] = useApp()
  const [navigator] = useApp("show.navigator")

  // ========================================= //
  // Hotkey hooks
  // hook docs:
  // https://johannesklauss.github.io/react-hotkeys-hook/
  // key bind docs:
  // https://github.com/jaywcjlove/hotkeys/
  // ========================================= //

  // Show search
  useHotkeys(
    "ctrl+/, command+/,/",
    () => actions.setSetting({ show: { search: !app.show.search } }),
    {},
    [app.show.search]
  )

  // Show item's Details pane
  useHotkeys(
    "ctrl+i, command+i,i",
    () => actions.setSetting({ show: { item: { info: !app.show.item.info } } }),
    {},
    [app.show.item.info]
  )

  // Show Navigator
  useHotkeys(
    "ctrl+n, command+n,n",
    (e) => {
      e.preventDefault()
      return actions.setSetting({ show: { navigator: !app.show.navigator } })
    },
    {},
    [app.show.navigator]
  )

  // Toggle Fullscreen
  useHotkeys(
    "ctrl+f, command+f,f",
    (e) => {
      e.preventDefault()
      const showBoth = !navigator
      console.log("fullscreen clicked", navigator, showBoth)
      actions.setSetting({
        show: {
          navigator: showBoth,
          item: { info: showBoth },
        },
      })
    },
    {},
    [app.show.navigator]
  )

  // ========================================= //
  // Render
  // ========================================= //

  return null
}

export default Hotkeys
