import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
// import { LinkElement } from "./plugins/with_auto_link"

// ========================================= //
// Main
// ========================================= //

const Leaf = ({ plugins, ...props }) => {
  let El
  _.find(plugins, (L) => (El = L(props)), El)
  return El ? <El {...props} /> : <BasicLeaf {...props} />
}

// ========================================= //
// Default/basic leaf
// ========================================= //

export const BasicLeaf = ({ attributes, leaf, children }) => {
  return (
    <span
      {...attributes}
      style={{
        fontWeight: leaf.bold ? "bold" : "normal",
        fontStyle: leaf.italic ? "italic" : "normal",
        textDecoration: leaf.underlined ? "underline" : "none",
      }}
    >
      {children}
    </span>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Leaf.propTypes = {
  /**
   * An object (key: type val: Component) of plugin interface elements
   */
  plugins: PropTypes.object.isRequired,
}

Leaf.defaultProps = {
  plugins: {},
}

export default Leaf
