import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import DragDropContainer from "../elements/drag_drop_container"
import ItemDraggable from "./item_draggable"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  ${props => props.empty && emptyStateStyle}
  display: flex;
  min-height: 100px;
`

const emptyStateStyle = css`
  margin-bottom: 15px;
  & .smooth-dnd-container {
    /* Changing props in here causes a an issue with reference */
    /* Using a dark transparent color for now */
    /* border: 2px #00000030 dashed; */
    margin: 0 20px 10px 0;
    &:before {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #00000030;
      font-size: 13px;
      content: '${props => props.placeholder }'; 
    }
  }
`

const DragDropContainerStyled = styled(DragDropContainer)`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  overflow: auto;
  height: 100%;
  width: 100%;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

// ========================================= //
// Main Component
// ========================================= //

const List = ({
  listID,
  parentID,
  itemIDs,
  onDrop,
  isEmpty,
  placeholder,
  droppableParams = {},
  ItemComp,
  itemParams = {},
  className
}) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [empty] = useItems([itemIDs],)

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container
      className={className}
      empty={isEmpty}
      placeholder={placeholder || "Drag items here"}
    >
      <DragDropContainerStyled
        items={itemIDs}
        onDrop={onDrop}
        {...{ listID, parentID, droppableParams }}
      >
        {_.map(itemIDs, (itemID, index) => (
          <ItemDraggable
            key={parentID + listID + itemID}
            listIndex={index}
            id={itemID}
            parentID={parentID}
            listID={listID || parentID}
            ItemComp={ItemComp}
            itemParams={itemParams}
          />
        ))}
      </DragDropContainerStyled>
    </Container>
  )
}

export default List
