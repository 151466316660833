import _ from "lodash"
import React, { useState, useCallback, useMemo, useRef, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import CloudDoneIcon from "@material-ui/icons/CloudDone"
import CloudQueueIcon from "@material-ui/icons/CloudQueue"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  position: absolute;
  right: 32px;
  opacity: 0.3;
  top: 5px;
  z-index: 10000;
`

const fadeInOut = keyframes`
  from {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const Unsaved = styled(CloudQueueIcon)`
  opacity: 0.3;
`

const Saved = styled(CloudDoneIcon)`
  opacity: 0;
  animation: ${fadeInOut} 2s linear;
`

// ========================================= //
// Main Component
// ========================================= //

const Status = ({ isDirty }) => {
  // ========================================= //
  // State
  // ========================================= //

  const render = useRef(0)

  // ========================================= //
  // Manage Focus
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // don't show if dirty or upon first loading
  const showSaved = !isDirty && render.current > 1
  render.current++

  // ========================================= //
  // Render
  // ========================================= //

  return <Container>{showSaved && <Saved />}</Container>
}

export default Status
