import _ from "lodash"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useApp } from "../../../../../../store/app/selectors"
import { useDomains } from "../../../../../../store/domains/selectors"

// ========================================= //
//  Main component
// ========================================= //

const Element = ({ editor }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [domainID] = useApp("currentDomain.id")
  const [autoLinks] = useDomains([domainID, "autoLinks"])

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    editor.autoLinks = _.reduce(
      autoLinks,
      (all, link, key) => {
        // fix for old format MIGRATEFN
        all[key] = _.isString(link)
          ? { itemID: link, auto: true, expand: false }
          : link
        return all
      },
      {}
    )
  }, [autoLinks])

  // ========================================= //
  // Render
  // ========================================= //

  return null
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Element.propTypes = {
  /**
   * The ID of the item to display
   */
  // id: PropTypes.string.isRequired,
}

Element.defaultProps = {}

export default Element
