import _ from "lodash"
import React, { useState } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"
import { useDomains } from "../../store/domains/selectors"
import { useItems } from "../../store/items/selectors"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined"
import GroupIcon from "@material-ui/icons/Group"

const SelectContainer = styled.div`
  display: flex;
  flex: 1;
`

const SelectStyled = styled(Select)`
  /* width: 100%; */
  /* display: flex;
  flex: 1; */
  display:flex;
  flex: 1;
  /* background: red;
  width: 200px; */
  /* display: block; */
  /* margin: 0px 0px 20px 0; */
  /* position: inherit; */
`
const ShareOutlinedIconStyled = styled(ShareOutlinedIcon)`
  margin: 25px 0 0 10px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    color: #588cb1;
  }
`

const MenuItemStyled = styled(MenuItem)`
  padding-right: 70px;
`

const GroupIconStyled = styled(GroupIcon)`
  margin-left: 10px;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0, -55%);
  font-size: 20px;
`

// ========================================= //
// Main Component
// ========================================= //

const DomainSelector = ({ onChange }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [domainID, { setCurrentDomain }] = useApp(["currentDomain", "id"])
  const [domains] = useDomains()
  const [, { clearItems }] = useItems(false)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleChange = ({ target: { value: id } }) => {
    if (id === "create") return handleCreate()
    clearItems()
    setCurrentDomain(domains[id])
    if (onChange) onChange()
  }

  const handleCreate = () => {
    alert("not working yet")
  }

  const handleShare = () => {
    alert("not working yet")
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <SelectContainer>
      <SelectStyled
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        // open={open}
        //onClose={handleClick}
        // onOpen={handleOpen}
        value={domainID}
        onChange={handleChange}
      >
        {_.map(domains, (domain, key) => (
          <MenuItemStyled key={key} value={domain.id}>
            {domain.name}{" "}
            {/* {(domain.shared || domain.sharing) && <GroupIconStyled />} */}
          </MenuItemStyled>
        ))}
        <MenuItemStyled value="create">create new domain</MenuItemStyled>
      </SelectStyled>
      {/* <ShareOutlinedIconStyled onClick={handleShare} /> */}
    </SelectContainer>
  )
}

export default DomainSelector
