import _ from "lodash"

const setActiveIntention = (state, action) => {
  const { id, parentID, path, remove, reset } = action.payload
  let { index } = action.payload
  const intentions = state.activeIntentions

  console.log("here", action.payload)

  if (reset) {
    state.activeIntentions = ["intentions"]
  } else if (remove) {
    console.log("remove called")
    index = _.indexOf(state.activeIntentions, id)
    state.activeIntentions.length = index
  } else if (path) {
    state.activeIntentions = path
  } else {
    if (_.isUndefined(index)) {
      //if (!parentID) return state

      const parentMissing = parentID && !_.includes(intentions, parentID)
      if (parentMissing) intentions.push(parentID)

      const parentIndex = parentID
        ? _.indexOf(intentions, parentID)
        : intentions.length
      index = parentIndex + 1
    }

    intentions[index] = id
    intentions.length = index + 1
  }

  if (!intentions.length) state.activeIntentions = ["intentions"]

  return state
}

export default setActiveIntention
