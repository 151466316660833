import _ from "lodash"
import { trackChange } from "./utils/track_changes"
import manageLinks from "./utils/manage_links"
import { removeNullFields } from "../../../utils"

const updateItem = (state, action) => {
  const { fields, path, value, options = {} } = action.payload

  // !!Rerwrite this once updateItem & updateItemOptions are merged
  const overwrite = action.payload.overwrite || options.overwrite || false
  const publish = action.payload.publish || options.publish || true

  const { id } = fields
  let item

  if (path) {
    item = _.cloneDeep(state[id])
    _.set(item, path, value)
  } else {
    item = overwrite ? fields : _.merge({}, state[id], fields)
  }

  // setting any field to null will inform the update to remove it
  item = removeNullFields(_.cloneDeep(item))

  state = manageLinks(state, _.cloneDeep(state[id]), _.cloneDeep(item))

  item.updatedAt = Date.now()
  state[id] = item

  publish && trackChange(item)

  return state
}

export default updateItem
