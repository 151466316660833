import { takeEvery } from "redux-saga/effects"
import signIn from "./sign_in"
import signOut from "./sign_out"
import authenticate, { stopAuthentication } from "./authenticate"
import validate from "./validate"

function* itemsSagas() {
  yield takeEvery("SIGN_IN_USER", signIn)
  yield takeEvery("SIGN_OUT_USER", signOut)
  yield takeEvery("STOP_AUTHENTICATION", stopAuthentication)
  yield takeEvery("AUTHENTICATE_USER", authenticate)
  yield takeEvery("VALIDATE_USER", validate)
}

export default itemsSagas
