import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import Nested from '../views/nested'
import Kanban from "../views/kanban"
import Writer from "../views/writer"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Inner = styled.div`
  /* padding: 20px 20px 20px 20px; */
  min-width: 50vw - 75;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.3s;
  opacity: 0;
  margin-top: 10px;
  ${props =>
    props.show &&
    css`
      opacity: 1;
      margin-top: 0;
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const Panel2 = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [view] = useApp("sections.panel2")
  const [id] = useApp(
    ["activeIntentions"],
    ids => _.nth(ids, -2) || _.last(ids)
  )
  const [viewComp, viewCompSet] = useState(view)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {    
    const transition = setTimeout(() => {      
      viewCompSet(view)
    }, 200)

    return () => clearTimeout(transition)
  }, [view])

  // ========================================= //
  // Render
  // ========================================= //
  
  return (
    <Container className={className}>
      <Inner show={view === viewComp}>
        {/* {viewComp === "nested" && <Nested id={id} />}
        {viewComp === "kanban" && <Kanban id={id} />} */}
        {viewComp === "writer" && <Writer id={id} />}
      </Inner>
    </Container>
  )
}

export default Panel2
