const updateItem = (db, usersCollection, userID, domainID, item) => {
  // userID = userID ? userID : auth().currentUser.uid
  return db
    .collection(usersCollection)
    .doc(userID)
    .collection('domains')
    .doc(domainID)
    .collection('items')
    .doc(item.id)
    .set(item)
    .catch(error => {
      console.log("Firebase error while saving:", error)
    })
}

export default updateItem
