import _ from "lodash"
import React from "react"
import styled from "styled-components"
import Views from "../views"
import Details from "./details"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
`

// ========================================= //
// Main Component
// ========================================= //

const View = ({ id, className }) => {
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className}>
      <Views />
      <Details />
    </Container>
  )
}

export default View
