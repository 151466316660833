import _ from "lodash"
import React, { useEffect } from "react"
import { useApp } from "../../../../store/app/selectors"
import { useUser } from "../../../../store/user/selectors"
import { useDomains } from "../../../../store/domains/selectors"

/**
 * Fetch domains for current user
 */

// ========================================= //
// Main Component
// ========================================= //

const FetchDomains = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [userID] = useUser(["id"])
  const [domainsLoaded] = useApp(["status", "domainsLoaded"])
  const [currentDomain, { setCurrentDomain }] = useApp(["currentDomain"])
  const [domains, { fetchDomains }] = useDomains()

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (!domainsLoaded) {
      console.log("fetching domains")
      fetchDomains({ userID })
    }
  }, [userID, domainsLoaded])

  // set current domain if none set
  useEffect(() => {
    if (domainsLoaded && _.size(domains) && !_.get(currentDomain, "id")) {
      const domain = _.last(_.values(domains))
      setCurrentDomain(domain)
    }
  }, [domainsLoaded, domains, currentDomain])

  // ========================================= //
  // Render
  // ========================================= //

  return children || null
}

export default FetchDomains
