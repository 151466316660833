import _ from "lodash"
import React, { useEffect } from "react"
import { useApp } from "../../../../store/app/selectors"
import { useUser } from "../../../../store/user/selectors"
import { useItem } from "../../../../store/items/selectors"

/**
 * Fetch items for current user and domain
 */

// ========================================= //
// Main Component
// ========================================= //

const FetchItems = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [userID] = useUser(["id"])
  const [domainsLoaded] = useApp(["status", "domainsLoaded"])
  const [itemsLoaded] = useApp(["status", "itemsLoaded"])
  const [currentDomain] = useApp(["currentDomain"])
  const [, { fetchItems }] = useItem(false)
  const domainID = _.get(currentDomain, "id")
  const ownerID = _.get(currentDomain, "ownerID", userID)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (domainsLoaded && domainID && ownerID) {
      fetchItems({ userID: ownerID, domainID })
    }
  }, [domainsLoaded, domainID, ownerID])

  // ========================================= //
  // Render
  // ========================================= //

  return (itemsLoaded && children) || null
}

export default FetchItems
