import _ from "lodash"
import React, { useState } from "react"
import styled, { css, keyframes } from "styled-components"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  width: 100%;
  height: 100%;
`

// const confetti = keyframes`
//     0% {
//       transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
//     }
//     25% {
//       transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
//     }
//     50% {
//       transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
//     }
//     75% {
//       transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
//     }
//     100% {
//       transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
//     }
// `

const Paper = styled.div`
  width: 15px;
  height: 15px;
  background-color: #f2d74e;
  position: absolute;
  z-index: 100000;
  left: 50%;
  opacity: 0;
  animation: confetti 5s ease-in-out -2s 2;
  transform-origin: left top;

  &:nth-child(1) {
    background-color: #f2d74e;
    left: 10%;
    animation-delay: 0;
  }
  &:nth-child(2) {
    background-color: #95c3de;
    left: 20%;
    animation-delay: -5s;
  }
  &:nth-child(3) {
    background-color: #ff9a91;
    left: 30%;
    animation-delay: -3s;
  }
  &:nth-child(4) {
    background-color: #f2d74e;
    left: 40%;
    animation-delay: -2.5s;
  }
  &:nth-child(5) {
    background-color: #95c3de;
    left: 50%;
    animation-delay: -4s;
  }
  &:nth-child(6) {
    background-color: #ff9a91;
    left: 60%;
    animation-delay: -6s;
  }
  &:nth-child(7) {
    background-color: #f2d74e;
    left: 70%;
    animation-delay: -1.5s;
  }
  &:nth-child(8) {
    background-color: #95c3de;
    left: 80%;
    animation-delay: -2s;
  }
  &:nth-child(9) {
    background-color: #ff9a91;
    left: 90%;
    animation-delay: -3.5s;
  }
  &:nth-child(10) {
    background-color: #f2d74e;
    left: 100%;
    animation-delay: -2.5s;
  }

  @keyframes confetti {
    0% {
      opacity: 1;
      transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    }
    25% {
      transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
    }
    50% {
      transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
    }
    75% {
      transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
    }
    100% {
      transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
    }
  }
`

// ========================================= //
// Main Module
// ========================================= //

const Confetti = () => {
  return (
    <Container>
      {_.times(40, (i) => (
        <Paper key={i} />
      ))}
    </Container>
  )
}

export default Confetti
