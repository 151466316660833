import _ from 'lodash'
const proxies = ["https://cors-anywhere.herokuapp.com/"]
const scihub = "https://sci-hub.tw/"

export const fetchUrl = (url, onSuccess, onError, type = "text") => {
  fetch(proxies[0] + url)
    .then((response) => response[type]())
    .then((content) => onSuccess(content))
    .catch(onError || console.log)
}

export const getPDF = (source, onSuccess, onError) => {
  fetchUrl(
    source,
    (blob) => {
      if (onSuccess) onSuccess()
      const file = window.URL.createObjectURL(blob)
      window.open(file, "_blank")
    },
    onError,
    "blob"
  )
}

export const getPaper = (src, onSuccess, onError) => {
  if (_.includes(src, "philpapers")) {
    console.log("getting pdf:", src)
    getPDF(src, onSuccess, onError)
  } else {
    console.log("getting scihub:", scihub + src)
    fetchUrl(scihub + src, (html) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, "text/html")
      console.log("doc", doc)

      const pdfSource = (() => {
        try {
          return doc.querySelector("#article iframe").src
        } catch (e) {
          console.log(e)
          return false
        }
      })()

      if (pdfSource) {
        getPDF(pdfSource, onSuccess, onError)
      } else {
        return onSuccess()
      }
    })
  }
}
