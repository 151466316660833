import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItem } from "../../../store/items/selectors"
import DropDownMenu from "../../elements/drop_down_menu"
import TuneIcon from "@material-ui/icons/Tune"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  ${(props) =>
    props.last &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.section.border};
    `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 10px 10px 20px;
  border-top: 1px solid ${(props) => props.theme.section.border};
  border-bottom: 1px solid ${(props) => props.theme.section.border};
  cursor: pointer;
  svg {
    transition: all 0.3s;
    opacity: 0.3;
    cursor: pointer;
    /* font-size: 1.2em; */
    position: absolute;
    right: 10px;
  }
  svg:hover {
    opacity: 1;
  }
  transition: all 0.3s;
  ${(props) =>
    !props.show &&
    !props.last &&
    css`
      border-bottom: 1px white solid;
    `}
`

const Inner = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
  ${(props) =>
    props.show &&
    css`
      max-height: 500px;
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const Panel = ({ className, title, button, children, last }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [show, setShow] = useState(false)

  // ========================================= //
  // Functions
  // ========================================= //

  const toggle = () => {
    setShow(!show)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className} last={last}>
      <Header onClick={toggle} show={show} last={last}>
        <span>{title}</span>
        {show && button}
      </Header>
      <Inner show={show}>{children}</Inner>
    </Container>
  )
}

export default Panel
