import _ from "lodash"
import React, { useEffect } from "react"
import { useApp } from "../../../../store/app/selectors"
import { useDomains } from "../../../../store/domains/selectors"

/**
 * New accounts or accounts with no main domain
 * will need to have the main domain created
 */

// ========================================= //
// Main Component
// ========================================= //

const FirstDomain = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [domainsLoaded] = useApp(["status", "domainsLoaded"])
  const [domainCount, { createDomain }] = useDomains(false, (state) =>
    _.size(state.domains)
  )

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (domainsLoaded && !domainCount) {
      console.log("no domains found, creating first...")
      createDomain({ name: "Main" })
    }
  }, [domainsLoaded, domainCount])

  // ========================================= //
  // Render
  // ========================================= //

  return children || null
}

export default FirstDomain
