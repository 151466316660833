import _ from "lodash"
import React, { memo } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"

// ========================================= //
// Style
// ========================================= //

const Button = styled.div` 
  cursor: pointer;
  outline: none;
  height: 30px;
`

// ========================================= //
// Main Component
// ========================================= //

const ToggleButton = ({ id, parentID, children, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const findInActive = (intentions, state) => _.includes(intentions, id)
  const [isActive, { displayIntention }] = useApp(
    ["activeIntentions"],
    findInActive
  )

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    displayIntention({ id, parentID, remove: isActive })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Button className={className} onClick={handleClick}>
      {children}
    </Button>
  )
}

export default memo(ToggleButton)
