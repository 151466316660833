import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useApp } from "../../../store/app/selectors"
import styled, { css } from "styled-components"
import { Button } from "../../elements"
import { motion, AnimatePresence } from "framer-motion"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

// ========================================= //
// Styles
// ========================================= //

const Go = styled(Button.NavigateTo)`
  svg {
    transform: rotate(90deg);
  }
`

// ========================================= //
//  Main component
// ========================================= //

const GoToItem = ({}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [activeID] = useApp(["activeIntentions"], _.last)
  const [currentItemID] = useApp("currentItemID")
  const show = currentItemID && activeID !== currentItemID

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "auto" }}
          transition={{ ease: "easeOut", duration: 0.2 }}
          exit={{ opacity: 0, width: 0 }}
        >
          <Go
            name="Navigate to current item"
            Icon={ExitToAppIcon}
            itemID={currentItemID}
            size="small"
            sepLeft={true}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

GoToItem.propTypes = {}

GoToItem.defaultProps = {}

export default GoToItem
