import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import SetSetting from "../../elements/set_setting"
import Modal from "../../features/modal"
import { AddParentModal } from "../../features/add_parent"

import SetMenuItemOpen from "../../elements/set_menu_item_open"
import SetNav from "../../elements/set_active"
import BaseItem, {
  CardStyled,
  showOnCardHover,
  verticalCenter,
} from "../../elements/base_item"
import ToggleArrow from "../../elements/set_active_button"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import Name from "../../elements/name"
import Description from "../../elements/description"
// import Item from "./item"
import Item from "../../features/item/index"
import List from "./list"

import AddToList from "../../features/add_to_list"
import HomeIcon from "@material-ui/icons/Home"
import AddIcon from "@material-ui/icons/Add"
import NavigationIcon from "@material-ui/icons/Navigation"
import SearchIcon from "@material-ui/icons/Search"
import { Button } from "../../elements"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import DisplayStateAnimated from "../../elements/display_state/display_state_animated"

// ========================================= //
// Style
// ========================================= //

// const visible = css`
//   transition: all 0.3s;
//   overflow: auto;
//   max-height: 300px;
//   border-bottom: 1px solid ${(props) => props.theme.section.border};
// `

const Container = styled.div`
  /* THIS IS JUST NOT WORKING RIGHT */
  /* display: flex;
  flex-direction: column; */
  /* flex: 1; */
  /* height: 100%; */
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  /* flex-grow: 1;
  flex-basis: auto; */
`
/* transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
  ${({ show }) => _.get(show, "parents") && visible} */
// `

const ParentHeader = styled.div`
  /* border: 1px dashed blue; */

  display: flex;
  /* flex: 1; */
  /* max-height: 30px; */
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.section.border};
`

const Title = styled.div`
  opacity: 0.5;
  text-align: left;
  padding-left: 17px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px;
`

const ListWrapper = styled.div`
  display: flex;
  /* overflow: auto;
  height: 100%; */
`

// ========================================= //
// Main Component
// ========================================= //

const Parents = ({ id, inTransition, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [showNav] = useApp("show.navigator")
  const [show, setShow] = useState(false)

  const [showAdd, setShowAdd] = useState(false)
  // const [selectedID] = useApp("currentItemID")
  const [parentIDs] = useItem([id, "parentIDs"], false, [])

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleForm = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowAdd(!showAdd)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <Container>
        <DisplayStateAnimated paths={["show.parents"]}>
          <Container>
            <ParentHeader>
              <Title>Parents</Title>
              {id !== "intentions" && (
                <Button
                  name="Add parent"
                  Icon={AddIcon}
                  size="small"
                  onClick={handleForm}
                  show={show ? 1 : 0}
                />
              )}
            </ParentHeader>

            {/* <ListWrapper> */}
            <List
              isDragDisabled={true}
              listID={id}
              parentID={id}
              childID={id}
              itemIDs={parentIDs}
              ItemComp={Item}
              width={250}
              itemParams={{
                inTransition: false,
                isHeader: false, //(itemID) => false, //itemID === latestID,
                // isOpen: itemID => itemID === selectedID,
              }}
            />
            {/* </ListWrapper> */}
          </Container>
        </DisplayStateAnimated>
      </Container>
      <AddParentModal
        id={id}
        title="Add item to:"
        isOpen={showAdd}
        onClose={() => setShowAdd(false)}
        searchParams={{
          id,
        }}
      />
    </>
  )
}

export default Parents
