import _ from "lodash"
import React, { useRef, useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { useItem, useItemsPath } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import { useDomains } from "../../../store/domains/selectors"
import { GetState } from "../../elements"

// ========================================= //
//  Utility
// ========================================= //

// move this to a util file?
const removeUndefined = (obj) => {
  const reducer = (r, v, k) => {
    if (!_.isUndefined(v)) {
      r[k] = _.isPlainObject(v) ? removeUndefined(v) : v
    }
    return r
  }
  return _.reduce(obj, reducer, {})
}

// ========================================= //
//  Main component
// ========================================= //

export const LayoutContext = React.createContext({})

const LayoutWrapper = ({ id, view, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [item] = useItem(id)
  const [currentDomain] = useApp("currentDomain")
  const [views, { updateDomain }] = useDomains([currentDomain, "views"])
  const [blocks, setBlocks] = useState(view.blocks || {})
  const [activeBlock, setActiveBlock] = useState(false)
  const [droppingItem, setDroppingItem] = useState({})
  const blocksArray = _.values(blocks)
  const layouts = {
    lg: blocksArray,
    md: blocksArray,
    sm: blocksArray,
    xs: blocksArray,
    xxs: blocksArray,
  }

  // const field = useRef(null)
  // const section = useRef(null)
  // const options = _.reduce(
  //   item,
  //   (result, field, key) => {
  //     if (_.includes(["name", "description"], key)) {
  //       result[key] = field
  //     } else if (key === "fields") {
  //       _.map(field, (f, v) => (result[v] = f))
  //     }
  //     return result
  //   },
  //   {}
  // )

  // ========================================= //
  // Effects
  // ========================================= //

  // keep component in sync with store
  useEffect(() => setBlocks(view.blocks || []), [view.blocks])

  // ========================================= //
  // Functions
  // ========================================= //

  const addBlock = (block) => {
    setBlocks({ ...blocks, [block.id]: block })
    setDroppingItem({})
  }

  const removeBlock = (id) => {
    //blocks[id] // ...
    setBlocks((blocks) => _.reject(blocks, { id }))
  }

  const updateBlock = (id, changes = {}) => {
    if (!blocks[id]) return console.log('NOT FOUND');
    console.log('updating:', id);
    const updated = _.merge({}, blocks[id], changes)
    setBlocks({ ...blocks, [id]: updated })
  }

  // const setDropComponent = ({ type, h, w }) => {
  //   console.log("called setDropComponent")
  //   setDroppingItem({ type, h, w })
  // }

  const setBlock = (key, type) => {
    // setSections(
    //   _.merge({}, sections, {
    //     [key]: {
    //       type: type,
    //       id: key,
    //       style: {},
    //       // field: options[key],
    //       // label,
    //     },
    //   })
    // )
  }

  const setField = (key, label = true) => {
    console.log("SETTING", key, label)
    // setSections(
    //   _.merge({}, sections, {
    //     [field.current]: {
    //       type: "field",
    //       id: key,
    //       style: {},
    //       // field: options[key],
    //       label,
    //     },
    //   })
    // )
    // setShowConfigure(false)
    // field.current = false
  }

  const save = () => {
    // return alert("save clicked!")
    // const cleanLayout = _.map(layout, removeUndefined)
    // THIS WILL ONLY MERGE; IT WILL NOT REPLACE.
    // updateDomain({
    //   id: currentDomain.id,
    //   views: {
    //     [view.id]: {
    //       ...view,
    //       blocks: {
    //         gridItems,
    //       }
    //       gridItems: cleanLayout,
    //       sections,
    //     },
    //   },
    // })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <GetState paths={["show.viewSettings"]}>
      {({ show }) => (
        <LayoutContext.Provider
          value={{
            blocks,
            addBlock,
            updateBlock,
            removeBlock,
            activeBlock,
            setActiveBlock,
            droppingItem,
            setDroppingItem,
            layouts,
            save,
            isEditing: show.viewSettings,
          }}
        >
          {children}
        </LayoutContext.Provider>
      )}
    </GetState>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

LayoutWrapper.propTypes = {}

LayoutWrapper.defaultProps = {}

export default LayoutWrapper
