import _ from "lodash"
import traverse from './traverse'

const isSelfOrChild = (state, parent, item, log = false) => {
  // Don't add to self
  if (parent.id === item.id) {
    if (log) console.log(`can't add to self`)
    return true
  }

  if (_.includes(parent.itemIDs, item.id)) {
    if (log) console.log('already a child');
    return true
  }

  // Don't add to own children
  if (traverse(state, parent.parentIDs, item.id)) {
    if (log) console.log(`can't add to a descendent`)
    return true
  }
}

export default isSelfOrChild
