import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects"
import firebase from "../../firebase"
import root from "window-or-global"

function* signIn(action) {
  try {
    console.log("signIn saga called", action)
    const { email } = action.payload
    firebase.signIn(
      email,
      () => {
        root.localStorage.setItem("emailForSignIn", email)
        // setMessage("Check your email for your sign in link.")
      },
      (error) => {
        console.log("SIGN IN ERROR:", error.code)
        // setMessage("Something went wrong!" + error.code)
      }
    )
    yield put({ type: "STOP_AUTHENTICATION" })
  } catch (error) {}
}

export default signIn
