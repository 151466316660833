import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
// import { useDomains } from "../../../store/domains/selectors"
// import { useItem } from "../../../store/items/selectors"
import BreadcrumbsFrom from "./breadcrumbs_from"
import AddColumn from "./add_column"
import StatusSettings from "./status_settings"
// import Name from "../../elements/name"
import ColumnsByField from "./columns_by_field"
import ColumnsByItems from "./columns_by_items"
import BlankStatusColumn from "./blank_status_column"
import ViewArea from "../../sections/view_area"
import TopBar from "../../features/top_bar"

const columnsBy = {
  status: ColumnsByField,
  field: ColumnsByField,
  items: ColumnsByItems,
}

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  padding: 0px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: auto;
  background: #e4e4e42b;
`

const Top = styled(TopBar)`
  display: flex;
  justify-content: space-between;
`

const Options = styled.div`
  /* margin-top: 2px; */
  display: flex;
`

const Columns = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  margin-top: 20px;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Kanban = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [columnType] = useApp("views.board.columnType")
  const [currentItemID] = useApp("views.board.path", (ids) => _.last(ids) || id)
  const [parentID] = useApp(
    ["views.board.path"],
    (ids) => _.nth(ids, -2) || _.first(ids)
  )

  // ========================================= //
  // Render
  // ========================================= //

  const ColumnsByType = columnsBy[columnType]

  return (
    <ViewArea center={false}>
      <Container className={className} data-body-scroll-lock-ignore={true}>
        <Top fixed={true} center={false}>
          <BreadcrumbsFrom id={id} />
          <Options>
            {/* <AddColumn columnType={columnType} /> */}
            <StatusSettings />
          </Options>
        </Top>
        <Columns>
          {ColumnsByType && (
            <ColumnsByType id={currentItemID} parentID={parentID} />
          )}

          {/* <BlankStatusColumn intentionID={id} /> */}
        </Columns>
      </Container>
    </ViewArea>
  )
}

export default Kanban
