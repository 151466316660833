import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import "./style.css"
import DisplayState from "../../elements/display_state"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  transition: opacity 0.3s;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  display: inherit;
  opacity: 0.4;
`

// ========================================= //
// Lil dude
// ========================================= //

const Dino = () => (
  <div className="freeze">
    <span className="cry">RoOar!</span>
    <div className="wrapper">
      <div className="dinosaur run">
        <div className="body">
          <div className="head">
            <div className="head-mask"></div>
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="eye"></div>
          </div>
          <div className="hand">
            <div className="elbow">
              <div className="forearm"></div>
            </div>
          </div>
          <div className="hand" id="second-hand">
            <div className="elbow">
              <div className="forearm"></div>
            </div>
          </div>
          <div className="leg">
            <div className="knee">
              <div className="foreleg"></div>
            </div>
          </div>
          <div className="leg" id="second-leg">
            <div className="knee">
              <div className="foreleg"></div>
            </div>
          </div>
          <div className="body-mask">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className="mark"></div>
          <ul className="tail">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

// ========================================= //
//  Main component
// ========================================= //

//https://codepen.io/Craaftx/pen/YROxzd
const Dinosaur = () => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <DisplayState
      paths={["currentItemID", "show.navigator"]}
      Component={Container}
      hardHide={true}
      reverse={true}
    >
      <Dino />
    </DisplayState>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Dinosaur.propTypes = {}

Dinosaur.defaultProps = {}

export default Dinosaur
