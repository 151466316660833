import _ from "lodash"
import addToItem from "./add_to_item"
import removeFromItem from "./remove_from_item"

const updateItemParents = (state, action) => {
  const { id, parentIDs } = action.payload
  const item = state[id]

  const removed = _.difference(item.parentIDs, parentIDs)
  const added = _.difference(parentIDs, item.parentIDs)

  _.map(added, parentID => {
    state = addToItem(state, {
      payload: { id, parentID },
    })
  })

  _.map(removed, parentID => {
    state = removeFromItem(state, {
      payload: { id, parentID },
    })
  })

  return state
}

export default updateItemParents
