import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../store/items/selectors"
import { useApp } from "../../store/app/selectors"
import BaseItem, {
  CardStyled,
  showOnCardHover,
  verticalCenter,
} from "../elements/base_item"
import Name from "../elements/name"
import ChildCount from "../elements/child_count"
// import SetStatusBoard from "../elements/set_status_board"
import ToggleArrow from "../elements/set_active_button"
import DownArrowButton from "../elements/down_arrow_button"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import SetMenuItemOpen from "../elements/set_menu_item_open"
// import StatusTagSelector from "../elements/status_tag_selector"

// ========================================= //
// Style
// ========================================= //

const hoverColor = css`
  &:hover {
    color: rgb(93, 149, 171);
    background: none;
  }
`

const NameStyled = styled(Name)`
  grid-area: name;
`

const ToggleArrowStyled = styled(ToggleArrow)`
  grid-area: arrow;
  height: 100%;
  ${verticalCenter};
  ${showOnCardHover};
  ${hoverColor}
  opacity: ${props => props.expanded ? 1 : 0};
`

const compact = css`
  max-height: 0;
`

const Bottom = styled.div`
  grid-area: bottom;
  border-top: 1px #efefef solid;
  display: grid;
  grid-template-columns: 40px 40px auto 20%;
  grid-template-rows: auto;
  grid-template-areas: "menu board status children";
  transition: all 0.3s;
  max-height: 30px;
  overflow: hidden;

  /* ${CardStyled}:not(:hover) &,
  ${CardStyled}:active & {
    ${props => props.showCompact && compact}
  } */
  
  ${compact}
`

const ChildCountStyled = styled(ChildCount)`
  grid-area: children;
  font-size: 10px;
  ${verticalCenter};
`

// const StatusTagSelectorStyled = styled(StatusTagSelector)`
//   grid-area: status;
//   ${verticalCenter};
//   font-size: 10px;
//   color: #c1cfde;
//   &:hover {
//     /* color: #6e7f93; */
//   }
// `

const DownArrowButtonStyled = styled(DownArrowButton)`
  grid-area: menu;
  padding: 0;
  background: none;
  ${showOnCardHover};
  ${hoverColor}
`

// const SetStatusBoardStyled = styled(SetStatusBoard)`
//   grid-area: board;
//   padding: 0;
//   ${showOnCardHover};
//   ${hoverColor};
// `

// ========================================= //
// Main Component
// ========================================= //

const Item = ({ id, parentID, listID }) => {
  // ========================================= //
  // State
  // ========================================= //  

  // write a filter selector to combine these.
  // const [completed] = useItem([id], item => item.statusID === "completed")
  // const [deleted] = useItem([id, "isDeleted"])
  //const [showCompleted] = useApp(["showCompleted"])
  const completed = false
  const showCompleted = true
  const [showCompact] = useApp(["showCompact"])
  const [expanded] = useApp(["activeIntentions"], ids => _.includes(ids, id))
  const isHidden = completed && !showCompleted && listID !== 'parentList' && listID !== "completed"
  const [hide, setHide] = useState(false)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (isHidden && !hide) return fadeOut()
    if (hide) setHide(false)
  }, [showCompleted, completed])

  // ========================================= //
  // Functions
  // ========================================= //

  const fadeOut = () => {
    const timer = setTimeout(() => setHide(true), 300)
    return () => clearTimeout(timer)
  }

  // ========================================= //
  // Render
  // ========================================= //

  // if (hide || deleted) return null
  if (hide) return null
  

  return (
    <SetMenuItemOpen id={id}>
      <BaseItem cardOptions={{ isHidden, showCompact, expanded }}>
        <NameStyled id={id} tagName="span" listID={listID} />
        <ToggleArrowStyled id={id} parentID={parentID} expanded={expanded}>
          <KeyboardArrowRightIcon />
        </ToggleArrowStyled>
        <Bottom showCompact={showCompact}>
          {/* <SetStatusBoardStyled id={id} parentID={parentID} /> */}
          {/* <StatusTagSelectorStyled id={id} /> */}
          <ChildCountStyled id={id} />
          <DownArrowButtonStyled id={id} parentID={parentID} listID={listID} />
        </Bottom>
      </BaseItem>
    </SetMenuItemOpen>
  )
}

export default Item
