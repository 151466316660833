import _ from "lodash"
import { Transforms, Text, Node, Range } from "slate"
import Element from "./element"

// ========================================= //
//  Main component
// ========================================= //

const withAutoLinks = (editor) => {
  // get editor functions to extend
  const {
    insertText,
    extended: { link: get },
  } = editor

  // ========================================= //
  // Register render logic
  // ========================================= //

  editor.plugins.components.autoLinks = Element

  // ========================================= //
  // Extend existing editor functions
  // ========================================= //

  editor.insertText = (character) => {
    const currentWord = editor.extended.getCurrentWord()

    // call default editor function
    insertText(character)

    if (!currentWord) return

    // check if current word is an auto-linking term
    const link = __searchLinks(currentWord)

    // create auto-link
    if (link) __createAutoLink(link)
  }

  editor.extended.link.get = (field) => {
    // extend with_links/index
    const all = get()

    all.auto = _.get(editor.autoLinks, [all.text, "auto"], false)
    all.expand = _.get(editor.autoLinks, [all.text, "expand"], false)
    console.log("GOT ALL", all)
    return field ? all[field] : all
  }

  // ========================================= //
  // Add editor functions
  // ========================================= //

  // ========================================= //
  //  Supporting functions
  // ========================================= //

  const __searchLinks = (key) => {
    return _.has(editor.autoLinks, key) ? key : false
  }

  const __createAutoLink = (key) => {
    const link = editor.autoLinks[key]
    
    if (!link) return
    // copy cursor location
    const location = _.cloneDeep(editor.selection)
    // modify location to wrap around link text before space
    location.anchor.offset -= _.size(key) + 1 // start
    location.focus.offset -= 1 // end
    // create link node
    editor.extended.link.create(link, location)
  }

  // ========================================= //
  // Return extended editor
  // ========================================= //

  return editor
}

export default withAutoLinks
