import React from "react"
import { createGlobalStyle } from "styled-components"
import { Provider } from "react-redux"
import store from "../store"
import Authentication from "../components/devices/common/authentication"
import LoadingOverlay from "../components/features/loading_overlay"
import MultipleTabCheck from "../components/sections/multiple_tab_check"
import DeviceContent from "../components/devices/common/device_content"

// ========================================= //
// Styles
// ========================================= //

const GlobalStyle = createGlobalStyle`
  HTML, body {
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: #3e3e3e;
    /* overscroll-behavior-x: none; */
    /* overflow: hidden; */
    background: #f2f5f9;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Dashboard = () => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Provider store={store}>
      <GlobalStyle />
      <MultipleTabCheck>
        {/* <LoadingOverlay /> */}
        <Authentication>
          <DeviceContent />
        </Authentication>
      </MultipleTabCheck>
    </Provider>
  )
}

export default Dashboard
