import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"
import Loading from "../elements/loading"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  position: fixed;
  min-height: 100vh;
  width: 100%;
  transition: all 0.3s;
  opacity: ${props => props.opacity};
  background: white;
  z-index: 100;
`

// ========================================= //
// Main Component
// ========================================= //

const LoadingOverlay = () => {
  // ========================================= //
  // State
  // ========================================= //

  const [loading] = useApp(["loading"])
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (loading) return
    const timer = setTimeout(() => setShow(false), 310)
    return () => clearTimeout(timer)
  }, [loading])

  // ========================================= //
  // Render
  // ========================================= //

  return show && (
    <Container opacity={loading ? 1 : 0}>
      <Loading />
    </Container>
  )
}

export default LoadingOverlay
