import _ from "lodash"
import React, { useState, useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import Slider from "@material-ui/core/Slider"

// ========================================= //
// Style
// ========================================= //

const SliderStyled = styled(Slider)``

// ========================================= //
// Support
// ========================================= //

const parse = (value) => {
  // validate string type and remove 'px' 
  value = _.isString(value) ? _.replace(value, /px/g, '') : ""
  // split into an array
  value = _.split(value, " ")
  // enforce array length to match padding sides
  value = _.size(value) === 4 ? value : [0, 0, 0, 0]
  // enforce number type for each value
  return _.map(value, _.toNumber)
}

// ========================================= //
// Main Component
// ========================================= //

const Padding = ({ onChange, value }) => {
  // ========================================= //
  // State
  // ========================================= //

  const sides = ["top", "right", "bottom", "left"]
  const padding = _.zipObject(sides, parse(value))

  // ========================================= //
  // Functions
  // ========================================= //

  const handleChange = (side, val) => {
    padding[side] = val
    onChange(_.join(_.values(padding), "px "))
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      {_.map(padding, (val, side) => (
        <div key={side}>
          Padding {_.capitalize(side)}:
          <SliderStyled
            valueLabelDisplay="auto"
            aria-label="padding slider"
            value={val || 0}
            step={1}
            min={0}
            max={100}
            onChange={(e, v) => handleChange(side, v)}
          />
        </div>
      ))}
    </>
  )
}

export default Padding
