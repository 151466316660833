export function fetchDomains(payload) {
  return {
    // Saga
    type: "FETCH_DOMAINS",
    payload,
  }
}

export function loadDomains(payload) {
  return {
    type: "LOAD_DOMAINS",
    payload,
  }
}

export function loadDomain(payload) {
  return {
    type: "LOAD_DOMAIN",
    payload,
  }
}

export function createDomain(payload) {
  return {
    type: "CREATE_DOMAIN",
    payload,
  }
}

export function updateDomain(fields, overwrite = false) {
  console.log('updateDomain called');
  return {
    type: "UPDATE_DOMAIN",
    payload: { fields, overwrite },
  }
}

export function shareDomain(payload) {
  return {
    type: "SHARE_DOMAIN",
    payload,
  }
}
