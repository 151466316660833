import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../store/items/selectors"
// import EditableField from "../elements/editable_field"
import CustomField, { CustomFieldNew } from "../elements/custom_field"
// import CustomFieldSetType from "../elements/custom_field_set_type"
import AddIcon from "@material-ui/icons/Add"
import DragDropContainer from "../elements/drag_drop_container"
import { Draggable } from "react-smooth-dnd"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  /* margin-top: 20px; */
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  border-top: 1px solid ${props => props.theme.section.border};
  border-bottom: 1px solid ${props => props.theme.section.border};
  svg {
    transition: all 0.3s;
    opacity: 0.3;
    cursor: pointer;
  }
  svg:hover {
    opacity: 1;
  }
`

const Inner = styled.div`
  padding: 0 0px;
`

const Field = styled.div`
  /* padding: 10px 20px; */
  font-size: 11px;
  cursor: grab;
  background: white;
  ${Inner}:last-child {
    border-bottom: none;
  }
  &:last-of-type {
    border-bottom: none;
  }
  &:active {
  }
`

const NewField = styled(Field)`
  transition: all 0.3s;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0px;
  ${props =>
    props.show &&
    css`
      opacity: 1;
      max-height: 100px;
      padding: 10px 0;
    `};
`

const AddField = styled.div`
  display: flex;
  align-items: center;
  color: grey;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s;
  opacity: 0.2;
  ${Container}:hover & {
    opacity: 1;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const ItemFields = ({ id }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [fields] = useItem([id, "fields"], fields =>
    _.sortBy(_.values(fields), "index")
  )
  const [showNew, setShowNew] = useState(false)
  const [newFieldID, setNewFieldID] = useState(false)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleNewFieldBlur = fieldID => {
    // if (!field.name.length && !value.length) setShowNew(false)
    setShowNew(false)
    setNewFieldID(fieldID)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <Header>
        <span>Fields</span>
        {/* <CustomFieldSetType id={id} fieldID={false}> */}
        <AddIcon onClick={() => setShowNew(!showNew)} />
        {/* </CustomFieldSetType> */}
      </Header>
      <Inner>
        <NewField show={showNew}>
          {showNew && <CustomFieldNew id={id} onBlur={handleNewFieldBlur} />}
        </NewField>
        <DragDropContainer
          parentID={id}
          listID="fields"
          items={_.map(fields, "id")}
        >
          {_.map(fields, field => (
            <Draggable
              key={id + field.id}
              droppableParams={{ field }}
              render={() => (
                // <DragDiv>
                <Field>
                  <CustomField
                    // key={id + field.id}
                    id={id}
                    fieldID={field.id}
                    isNew={field.id === newFieldID}
                    onBlur={() => setNewFieldID(false)}
                  />
                </Field>
                // </DragDiv>
              )}
            />
          ))}
        </DragDropContainer>
      </Inner>
    </Container>
  )
}

export default ItemFields
