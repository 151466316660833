import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useApp } from "../../../store/app/selectors"

// ========================================= //
// Styles
// ========================================= //

const Wrapper = styled.div``

// ========================================= //
//  Main component
// ========================================= //

/**
 * A listener wrapper that will add the provided settings paths listeners
 * to the provided component and pass the current values as props.
 */

const DisplayState = ({
  paths,
  Component,
  hardHide,
  reverse,
  children,
  className,
  ...props
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [state] = useApp(false, (state) => _.pick(state.app, paths))

  // ========================================= //
  // Functions
  // ========================================= //

  const shouldRender = (obj) => {
    if (!hardHide) return true

    return _.some(obj, (v, k) => (_.isObject(v) ? shouldRender(v) : !!v))
  }

  // ========================================= //
  // Render
  // ========================================= //

  const Container = Component || Wrapper

  let willRender = shouldRender(state)
  if (reverse) willRender = !willRender

  return willRender ? (
    <Container {...state} {...props} className={className}>
      {children}
    </Container>
  ) : null
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

DisplayState.propTypes = {
  /**
   * The app state path to listen to
   */
  paths: PropTypes.array.isRequired,
  /**
   * The wrapper component. If none provided, a basic div with an active class added will be returned.
   */
  Component: PropTypes.elementType,
  /**
   * If setting is false or undefined, don't render children
   */
  hardHide: PropTypes.bool,
  /**
   * If paths are set, and hardHide set to true, hide children on true settings
   */
  reverse: PropTypes.bool,
}

DisplayState.defaultProps = {
  Component: "div",
  hardHide: false,
  reverse: false,
}

export default DisplayState
