import _ from "lodash"
import React, { memo, useMemo } from "react"
import { useItem } from "../../store/items/selectors"
import { useApp } from "../../store/app/selectors"
import EditableField from "./editable_field"

// ========================================= //
// Main Component
// ========================================= //

const Name = ({
  id,
  listID,
  tagName = "span",
  editable,
  length = 1000,
  className,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [name] = useItem([id, "name"])  
  const [isEditable, { setSetting }] = useApp(
    "editableNameID",
    nID => nID === id + listID
  )

  if (isEditable) console.log(name, isEditable)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleBlur = () => {
    console.log("blurring", name)

    if (isEditable) setSetting({ editableNameID: false })
  }

  // ========================================= //
  // Render
  // ========================================= //

  const CustomTag = `${tagName}`

  return isEditable || editable ? (
    <EditableField
      className={className}
      hasFocus={isEditable}
      {...{ id, field: "name", tagName }}
      onBlur={handleBlur}
    />
  ) : (
    <CustomTag className={className}>
      {_.truncate(name, {
        length,
      })}
    </CustomTag>
  )
}

export default memo(Name)
