import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import ContentEditable from "react-sane-contenteditable"
import Modal from "../../features/modal"
import SearchItems from "../search_items"
import ShareIcon from "@material-ui/icons/Share"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  svg {
    transition: all 0.3s;
    opacity: 0;
    font-size: 15px;
    margin-left: 10px;
  }
  &:hover svg {
    opacity: 1;
  }
`

const Field = styled(ContentEditable)`
  outline: none;
  cursor: text;
  font-size: 15px;
  display: flex;
  &[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: #d2d2d2;
    font-weight: normal;
  }
`

const LinkContainer = styled.div`
  width: 500px;
`

const Results = styled.div`
  margin-top: 20px;
  strong {
    font-size: 12px;
  }
`

const LinkedField = styled.div`
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.3s;
  &:hover {
    padding-left: 4px;
    color: ${props => props.theme.element.text.hover};
  }
  border-bottom: 1px solid ${props => props.theme.section.border};
`

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldSearch = ({
  id,
  fieldID,
  link,
  isOpen,
  type = "link",
  title,
  canCreate = true,
  onSave,
  onDismiss,
  setFocus = true,
  onClose,
  children
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [linkedItem, actions] = useItem(link ? [link.id] : false)
  const [item, itemSet] = useState(linkedItem)
  const [showModal, showModalSet] = useState(isOpen)

  // ========================================= //
  // Effect
  // ========================================= //

  // useEffect(() => {
  //   itemSet(linkedItem)
  // }, [linkedItem])

  useEffect(() => {
    if (item && type === "select") {
      onSave(item.id)
    }
  }, [item])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleLinkSearch = selected => {
    itemSet(selected)
  }

  const handleFieldSelect = path => {
    console.log('SELECTED');
    const link = { id: item.id, path }
    onSave ? onSave(link) : save(link)
    closeModal()
  }

  const save = link => {
    actions.setFields({
      id,
      fields: {
        [fieldID]: {
          type,
          value: link,
        },
      },
    })
  }

  const closeModal = (e, reason) => {
    showModalSet(false)
    if (reason && onDismiss) onDismiss()
    if (onClose) onClose()
  }

  // ========================================= //
  // Render
  // ========================================= //  

  return (
    <Modal title={title || "Find item to link"} open={showModal} onClose={closeModal}>
      <LinkContainer>
        <SearchItems
          defaultValue={link && link.id}
          setFocus={setFocus}
          onChange={handleLinkSearch}
          canCreate={canCreate}
        />
        {item && type === "link" && (
          <Results>
            <strong>Pick a field:</strong>

            {_.map(_.pick(item, ["name"]), (value, name) => (
              <LinkedField
                key={item.id + name}
                onClick={() => handleFieldSelect(name)}
              >
                <strong>{name}:</strong> {value}
              </LinkedField>
            ))}
            {item.fields &&
              _.map(
                _.filter(item.fields, f => f.type !== "link"),
                field => (
                  <LinkedField
                    key={item.id + "field" + field.name}
                    onClick={() => handleFieldSelect(["fields", field.name])}
                  >
                    <strong>{field.name}:</strong> {_.toString(field.value)}
                  </LinkedField>
                )
              )}
          </Results>
        )}
      </LinkContainer>
      {children}
    </Modal>
  )
}

export default CustomFieldSearch
