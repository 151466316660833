import _ from "lodash"
import updateItemType from './update_item_type'

const updateModelMiddleware = store => next => action => {
  if (action.type === "LOAD_ITEM") {
    action.payload.item = updateItemType(action.payload.item)
  }

  return next(action)
}

export default updateModelMiddleware
