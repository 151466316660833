const authenticate = auth => {
  return new Promise((resolve, reject) => {
    auth().onAuthStateChanged(user => {
      // User is signed in.
      user ? resolve(user) : reject('something went wrong in authentication')
    })
  })
}

export default authenticate
