import _ from "lodash"
import React, { useState, useEffect } from "react"
import { useEditor } from "slate-react"
import PropTypes from "prop-types"

// ========================================= //
//  Main component
// ========================================= //
let identity = 0

const UseLink = (itemID) => {
  // ========================================= //
  // State
  // ========================================= //

  const [isSelected, setIsSelected] = useState(false)
  const refID = useState(identity++)
  const editor = useEditor()

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    editor.extended.link.onLinkChange(itemID, refID, setIsSelected)
    return () => {
      editor.extended.link.onLinkChange(itemID, refID, false)
    }
  })

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return isSelected
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

UseLink.propTypes = {
  /**
   * The ID of the item to display
   */
  itemID: PropTypes.string.isRequired,
}

UseLink.defaultProps = {}

export default UseLink
