import _ from 'lodash'
import React from "react"
import styled, { css } from "styled-components"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"

// ========================================= //
// Style Components
// ========================================= //

const Container = styled.div`
  margin: 20px;
  min-width: 300px;
`

const Title = styled.h2`
  font-weight: normal;
  padding: 0;
  margin: 0 0 15px 0;
  font-size: 16px;
`

const DialogStyled = styled(Dialog)`
  .MuiDialog-container {
    ${({ theme }) =>
      _.get(theme, "device.isTabletOrMobileDevice") &&
      css`
        align-items: flex-start;
      `}
  }
  & > .MuiBackdrop-root {
    background: #ffffffb5;
  }
  .MuiPaper-elevation10 {
    border: 1px #e8e8e8cf solid;
    box-shadow: 0px 11px 15px -7px rgba(148, 148, 148, 0.2),
      0px 24px 38px 3px rgba(214, 214, 214, 0.14),
      0px 0px 13px 8px rgba(181, 181, 181, 0.12);
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Modal = ({ title, children, open, onClose, className }) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <DialogStyled
      className={className}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      style={{ top: "0%" }}
      PaperProps={{ elevation: 10 }}
    >
      <Container>
        {title && <Title>{title}</Title>}
        {children}
      </Container>
    </DialogStyled>
  )
}

export default Modal
