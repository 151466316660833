import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import { useDomains } from "../../../store/domains/selectors"
import SetMenuItemOpen from "../../elements/set_menu_item_open"
import SetActive from "../../elements/set_active"
import BaseItem, {
  CardStyled,
  showOnCardHover,
  verticalCenter,
} from "../../elements/base_item"
import ToggleArrow from "../../elements/set_active_button"
import AddToList from "../../features/add_to_list"
import Name from "../../elements/name"
import Description from "../../elements/description"
import Item from "./item"
import Panel from "./panel"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import AddIcon from "@material-ui/icons/Add"
import { Button } from "../../elements"
import { ButtonBase } from "@material-ui/core"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import HeightIcon from "@material-ui/icons/Height"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  /* flex: 1 0 auto; */
  display: flex;
  min-width: 250px;
  /* min-height: 300px; */
  scroll-snap-align: end;
  flex-direction: column;
  /* height: 100%; */
  /* border-top: 1px solid ${(props) => props.theme.section.border}; */
  /* border-left: 1px solid ${(props) => props.theme.section.border}; */
`

const Title = css`
  font-size: 12px;
  font-weight: bold;
`

const Header = styled.div`
  ${Title};
  padding: 10px 10px 10px 20px;
  border-top: 1px solid ${(props) => props.theme.section.border};
  border-bottom: 1px solid ${(props) => props.theme.section.border};
`

const NameStyled = styled(Name)`
  ${Title};
`

const List = styled.div`
  /* flex-grow: 1; */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  & > :first-child {
    margin-top: 1px;
    border-top: 1px solid ${(props) => props.theme.section.border};
  }
`

const Entry = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 20px;
  font-size: 12px;
  span {
    display: flex;
    align-items: center;
    span {
      padding-right: 10px;
    }
    svg {
      transform: rotate(90deg);
    }
  }
`

const AddToListStyled = styled(AddToList)`
  justify-self: flex-end;
`

const Empty = styled.div`
  padding: 20px;
  color: #dedede;
  font-size: 12px;
  font-weight: bold;
`

// ========================================= //
// Main Component
// ========================================= //

const LinkedTerms = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [terms, { updateItem }] = useItem([id, "linkedTerms"])
  const [domainID] = useApp("currentDomain.id")
  const [autoLinks, { updateDomain }] = useDomains([domainID, "autoLinks"])

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleAdd = () => {
    const term = prompt(
      "Which word or phrase would you like to automatically have link back to this item when found within other items?"
    )
    if (term && !_.get(autoLinks, term)) {
      const link = {itemID: id, expand: true}
      updateDomain({
        id: domainID,
        autoLinks: { [term]: link },
      })
      updateItem({ id, linkedTerms: { [term]: link } })
    }
  }

  const handleEdit = (term, link) => {
    console.log('edit:', term, link);
    alert('Link edit menu coming soon...')
  }

  const handleRemove = (term) => {
    updateDomain({
      id: domainID,
      autoLinks: { [term]: null },
    })
    updateItem({ id, linkedTerms: { [term]: null } })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Panel title="Linking Terms" button={<AddIcon onClick={handleAdd} />}>
      {!_.size(terms || []) && <Empty>No linking terms</Empty>}
      <List>
        {_.map(terms, (link, term) => (
          <Entry key={_.camelCase(term)} onClick={() => handleEdit(term, link)}>
            <span>
              <span>{term}</span>
              {link.expand && (
                <>
                  (<HeightIcon fontSize="small" />)
                </>
              )}
            </span>
            <Button
              name="Remove term"
              Icon={DeleteForeverIcon}
              size="medium"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleRemove(term)
              }}
            />
          </Entry>
        ))}
      </List>
    </Panel>
  )
}

export default LinkedTerms
