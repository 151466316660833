import _ from "lodash"

const traverse = (state, parentIDs, id) => {
  if (!parentIDs) return false
  if (_.includes(parentIDs, id)) return true

  return _.find(parentIDs, parentID =>
    traverse(state, state[parentID].parentIDs, id)
  )
}

export default traverse
