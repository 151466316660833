import _ from "lodash"
import React from "react"
import styled from "styled-components"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  background: #e7eaef;
  border-right: 1px #d8e0ef solid;
  display: flex;
  flex-direction: column;
`

// ========================================= //
// Main Component
// ========================================= //

const MainNavigation = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className}>

    </Container>
  )
}

export default MainNavigation
