import _ from "lodash"

const getItemsOnce = (
  db,
  usersCollection,
  source = "server",
  userID,
  domainID
) => {
  return new Promise((resolve, reject) => {

    console.log('ONCE CALLED, source:', source);
    
    db.collection(usersCollection)
      .doc(userID)
      .collection("domains")
      .doc(domainID)
      .collection("items")
      .get({
        source, // "cache" or "server"
      })
      .then(querySnapshot => {
        // resolve(snapshot.data())
        const items = []
        querySnapshot.forEach(doc => items.push(doc.data()))
        console.log("TOTAL:", _.size(items))
        console.log("Completed:", _.size(_.filter(items, { completed: true })))
        console.log("Deleted:", _.size(_.filter(items, { isDeleted: true })))

        resolve(items)
      })
  })
}
export default getItemsOnce
