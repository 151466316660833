import _ from "lodash"
import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { useItem } from "../../store/items/selectors"
import ContentEditable from "react-sane-contenteditable"

// ========================================= //
// Style Components
// ========================================= //

const ContentEditableStyled = styled(ContentEditable)`
  &[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #d2d2d2;
  }
  width: 100%;
  outline: none;
  cursor: text;
  /* Exception for input areas */
  /* https://gist.github.com/netpoetica/a1234ae1d4d2988f03ef */
  -webkit-touch-callout: default !important;
  -webkit-user-select: text !important;
`

// ========================================= //
// Main Component
// ========================================= //

const EditableField = ({
  id,
  field,
  tagName = "div",
  disabled = false,
  className,
  placeholder = "...",
  onSave = false,
  onBlur = false,
  hasFocus = false,
  allowNewLine = false,
  maxLength = 500,
  isCustom = false,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const path = isCustom ? [id, "fields", field] : [id, field]
  const [value, actions] = useItem(id ? path : false)
  const [content, setContent] = useState(value || "")
  const inputRef = useRef(null)

  useEffect(() => {
    // value has changed externally
    if (value && value !== content) {
      if (inputRef) {
        // I've tried som many content editable plugins
        // and they have all had issues.
        // with this one, calling setContent will auto focus
        // and if there are more than one name fields on the screen
        // the other will take focus just for a store update...
        // so, when the store updates, set inner html manually
        // console.log('manually setting');

        inputRef.current.ref.innerHTML = value
      }
    }
  }, [value])

  // ========================================= //
  // Functions
  // ========================================= //

  const save = () => {
    // nothing has changed
    if (value === content) return
    if (onSave) return onSave(content)
    isCustom
      ? actions.setFields(_.set({ id }, ["fields", field], content))
      : actions.updateItem(_.set({ id }, [field], content))
  }

  const handleBlur = (event) => {
    if (onBlur) onBlur(content)
    save()
  }

  const handleChange = (event, text) => {
    if (!text) return
    setContent(_.size(text) ? text : value)
  }

  const handlePaste = (text) => {
    handleChange(false, text)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <ContentEditableStyled
      ref={inputRef}
      tagName={tagName}
      className={className}
      placeholder={placeholder}
      focus={hasFocus}
      caretPosition="end"
      content={content}
      editable={!disabled}
      maxLength={maxLength}
      multiLine={allowNewLine}
      onChange={handleChange}
      onPaste={handlePaste}
      onBlur={handleBlur}
      // onFocus={() => console.log("focused:", field, value)}
    />
  )
}

export default EditableField
