import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { DisplayState } from "../../elements"
import { Margin } from "./styles"

// ========================================= //
//  Main component
// ========================================= //

const TopBar = ({ children, center, fixed, className }) => {
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <DisplayState
      paths={["show.navigator", "show.item.info"]}
      Component={Margin}
      className={className}
      center={center}
      fixed={fixed}
    >
      {children}
    </DisplayState>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

TopBar.propTypes = {}

TopBar.defaultProps = {
  fixed: false,
  center: true,
}

// ========================================= //
// Default export
// ========================================= //

export default TopBar
