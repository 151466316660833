import Box, { exampleProps as boxProps } from "../../features/stats/box"
import Button, { exampleProps as buttonProps } from "../../elements/button"

// ========================================= //
// Load Components and example props
// ========================================= //

export const COMPONENTS = {
  box: { Comp: Box, exampleProps: boxProps },
  button: { Comp: Button, exampleProps: buttonProps },
}
