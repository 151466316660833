import _ from "lodash"
import React, { memo } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"
import { useItemsPath } from "../../store/items/selectors"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div` 
  cursor: pointer;
  outline: none;
`

// ========================================= //
// Main Component
// ========================================= //

const SetActive = ({ navToID = false, id, parentID, reset = false, children, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [path] = useItemsPath(navToID)
  const findInActive = (intentions, state) => _.includes(intentions, id)
  const [isActive, { displayIntention }] = useApp(
    ["activeIntentions"],
    findInActive
  )

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()    
    displayIntention({ path: _.size(path) ? path : false, id, parentID, remove: isActive, reset })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className} onClick={handleClick}>
      {children}
    </Container>
  )
}

export default memo(SetActive)
