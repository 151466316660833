import _ from 'lodash'

// set app status based on status of store reducers
const setStatus = (store, action) => {
  if (!_.includes(["LOAD_DOMAINS", "LOAD_ITEMS"], action.type)) return

  const statusParam = (() => {
    switch (action.type) {
      case "LOAD_DOMAINS":
        return "domainsLoaded"
      case "LOAD_ITEMS":
        return "itemsLoaded"
      default:
        return false
    }
  })()

  store.dispatch({
    type: "SET_APP_STATUS",
    payload: { status: { [statusParam]: true } },
  })

  const state = store.getState().app

  if (state.status.domainsLoaded && state.status.itemsLoaded) {
    store.dispatch({
      type: "SET_LOADING",
      payload: false,
    })
  }
}

export default setStatus