import _ from "lodash"
import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { useApp } from "../../../../store/app/selectors"
import { Drawer } from "../../../features"
import Button from "@material-ui/core/Button"
import Box, { exampleProps } from "../../../features/stats/box"
import { Section, Modules, Spacer, Bottom } from "./style"
import Droppable from "./droppable"
import { LayoutContext } from "../layout_context"
import { COMPONENTS } from "../all_components"

// ========================================= //
//  Main component
// ========================================= //

const Controls = ({}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [isEditing, { setSetting }] = useApp("show.viewSettings")
  const { save } = useContext(LayoutContext)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    // disable editing on final close
    return () => isEditing && stopEditing()
  }, [isEditing])

  // ========================================= //
  // Functions
  // ========================================= //

  const stopEditing = () => {
    setSetting({ show: { viewSettings: false } })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Drawer
      path="show.viewSettings"
      side="right"
      z={150}
      disableDiscovery={true}
    >
      <Section>
        <strong>View Builder</strong>
      </Section>
      <Section>
        <p>Drag any of the blocks below over to your view</p>
      </Section>
      <Section>
        <strong>Modules</strong>
      </Section>

      <Modules>
        {_.map(COMPONENTS, ({ Comp, exampleProps }, name) => (
          <Droppable type={name}>
            <Comp {...exampleProps} />
          </Droppable>
        ))}
      </Modules>

      <Section>
        <strong>Fields</strong>
      </Section>

      <Spacer />

      <Bottom>
        <Button variant="outlined" onClick={save}>
          Save
        </Button>

        <Button variant="outlined" color="secondary" onClick={stopEditing}>
          Close
        </Button>
      </Bottom>
    </Drawer>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Controls.propTypes = {}

Controls.defaultProps = {}

export default Controls
