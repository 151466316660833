import _ from "lodash"
import styled, { css } from "styled-components"
import { hslToRgb } from "@material-ui/core"

const toHSL = (hsla = { a: 0, s: 0, l: 0 }, offsets = {}) => {
  const h = _.round(hsla.h + _.get(offsets, "h", 0))
  const s = _.round(hsla.s * 100 + _.get(offsets, "s", 0))
  const l = _.round(hsla.l * 100 + _.get(offsets, "l", 0))
  const a = hsla.a + _.get(offsets, "a", 0)

  return `hsla(${h}, ${s}%, ${l}%, ${a})`
}

const defaults = {
  colors: {
    primary: { h: 224, s: 0.41, l: 0.9, a: 0.98 },
    border: { h: 224, s: 0.41, l: 0.9, a: 0.98 },
    background: { h: 0, s: 0, l: 1, a: 1 },
    text: { h: 0, s: 0, l: 0.25, a: 0.88 },
  },
}

const setTheme = theme => {
  const colors = _.merge({}, defaults.colors, theme.colors)

  const section = {
    background: colors.background && toHSL(colors.background),
    border: colors.border
      ? toHSL(colors.border)
      : toHSL(colors.primary, { s: -15, l: -10 }),
    shadow: colors.shadow
      ? toHSL(colors.shadow)
      : toHSL(colors.border, { s: -20, l: -30 }),
  }

  const element = {
    text: {
      standard: toHSL(colors.primary, { s: -20, l: -40 }),
      hover: toHSL(colors.accent),
    },
    menu: {
      text: toHSL(colors.primary, { s: -20, l: -40 }),
    },
    item: {
      border: toHSL({ h: 0, s: 0, l: 0.91, a: 0.45 }),
      shadow: toHSL({ h: 0, s: 0, l: 0.62, a: 0.25 }),
      text: toHSL(colors.primary, { s: -20, l: -40 }),
      active: toHSL(colors.accent),
    },
  }

  theme = {
    toHSL,
    colors,
    section,
    element,
    css: {
      boxShadow: css`
        box-shadow: 0px 0px 10px -4px ${colors.two};
      `,
    },
  }

  // console.log("returning theme", theme)

  return theme
}

// merge a provided theme with the standard theme
export { setTheme }
