import _ from "lodash"
import React, { useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Modal from "../modal"
import AddParentSearch from "./add_parent_search"

// ========================================= //
// Styles
// ========================================= //

// ========================================= //
//  Main component
// ========================================= //

const AddParentModal = ({ id, title, isOpen, onClose, searchParams, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  title = searchParams.remove ? "Move item to:" : title

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return isOpen
    ? ReactDOM.createPortal(
        <Modal title={title} open={isOpen} onClose={onClose}>
          <AddParentSearch id={id} onClose={onClose} {...searchParams} />
          {children}
        </Modal>,
        document.body
      )
    : null
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

AddParentModal.propTypes = {
  /**
   * The id of the item to add to selected item
   */
  id: PropTypes.string.isRequired,
  /**
   * Is the modal open?
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Fn to call on close
   */
  onClose: PropTypes.func.isRequired,
  /**
   * The params to pass to search
   */
  searchParams: PropTypes.object,
}

AddParentModal.defaultProps = {
  title: "Add item to:",
  searchParams: {},
}

export default AddParentModal
