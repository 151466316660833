import _ from "lodash"
import firebase from "../"

const check = (store) => {
  setTimeout(() => {
    const { multipleTabs } = firebase.db
    const lastCheck = store.getState().app.multipleTabs
    const changed = lastCheck != multipleTabs

    // console.log(
    //   "CHECKING TABS AGAIN",
    //   "many?",
    //   multipleTabs,
    //   "last?", lastCheck,
    //   "changed",
    //   changed
    // )

    if (changed) {
      // console.log('dispatch tabs:', multipleTabs);
      store.dispatch({
        type: "MULTIPLE_TABS_OPEN",
        payload: multipleTabs,
      })
    }

    if (multipleTabs) check(store)
  }, 1000)
}

const singleTabCheckMiddleware = (store) => (next) => (action) => {
  if (action.type === "AUTHENTICATE_USER") {
    console.log("CHECKING TABS", firebase.db.multipleTabs)

    check(store)
  }

  return next(action)
}

export default singleTabCheckMiddleware
