import * as firebase from "firebase"

const app = firebase.initializeApp({
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
})

const { auth, analytics } = firebase

const db = app.firestore()

// Subsequent queries will use persistence, if it was enabled successfully
db.enablePersistence({ synchronizeTabs: true })
  .then(() => {
    db.multipleTabs = false
    console.log("Persistence layer enabled successfully")
  })
  .catch(function (err) {
    if (err.code == "failed-precondition") {
      console.log(`Failed to enable persistence`, err)
      db.multipleTabs = true
      // alert(`Ntntnl has been opened in another tab; Please close one. `)
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      console.log(`Persistence unimplemented`, err)
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

const disconnect = () => {
  db.disableNetwork().then(function () {
    console.log("disabled firebase listeners")
  })
}

export { auth, analytics, disconnect }
export default db
