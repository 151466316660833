import _ from "lodash"
import React, { useRef, useEffect, useLayoutEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItemsByStatusID } from "../../../store/items/selectors"
import List from "../../features/list"
import AddToList from "../../features/add_to_list"
import SetActive from "../../elements/set_active"
// import Item from './item'
import Item from "../../features/item"
import NewItem from "../../features/new_item"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  transition: all 0.3s;
  ${props =>
    props.visible &&
    css`
      width: 265px;
      margin-right: 20px;
      /* To be flush with edge */
      &:last-of-type {
        margin-right: 0px;
        width: 254px;
      }
    `}
`
const HR = styled.div`
  height: 1px;
  width: 252px;
  margin: 5px 0 20px 0;
  border-bottom: 1px #f7f7f7 solid;
`
const Name = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: ${props =>
    props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -30 })};
  width: 250px;
`

// ========================================= //
// Main Component
// ========================================= //

const ColumnNew = ({ parentID, listID, params, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [visible, actions] = useApp("views.board.showAddColumn")
  const elRef = useRef(null)

  // ========================================= //
  // Effects
  // ========================================= //

  useLayoutEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        elRef.current.parentElement.scroll({
          left: elRef.current.parentElement.scrollWidth,
          behavior: "smooth", // 👈
        })
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [visible])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClose = () => {
    actions.setSetting(_.set({}, "views.board.showAddColumn", false))
  }

  // ========================================= //
  // Rendering
  // ========================================= //

  return (
    <Container ref={elRef} className={className} visible={visible}>
      <NewItem
        listID={listID}
        parentID={parentID}
        fields={params}
        show={visible}
        onBlur={handleClose}
      />
      <HR />
    </Container>
  )
}

export default ColumnNew
