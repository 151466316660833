import _ from "lodash"
import React from "react"
import { useApp } from "../../../../store/app/selectors"
import FetchDomains from "./fetch_domains"
import FetchItems from "./fetch_items"
import FirstDomain from "./first_domain"
import FirstItem from "./first_item"

// ========================================= //
// Main Component
// ========================================= //

const InitializeData = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [loading] = useApp(["loading"])
  const loaded = !loading

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <FetchDomains />
      <FirstDomain />
      <FirstItem />
      <FetchItems>{loaded && children}</FetchItems>
    </>
  )
}

export default InitializeData
