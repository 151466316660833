import _ from "lodash"
import React, { forwardRef } from "react"
import { useSlate } from "slate-react"
import { ButtonSpan, IconSpan } from "./style"

const FormatButton = ({ format, Icon, ...props }) => {
  const editor = useSlate()

  return (
    <Button
      reversed
      active={editor.extended.format.has(format)}
      onMouseDown={(event) => {
        event.preventDefault()
        editor.extended.format.toggle(format)
      }}
    >
      <IconSpan {...props}>{Icon ? <Icon /> : format}</IconSpan>
    </Button>
  )
}

const Button = forwardRef(({ className, active, reversed, ...props }, ref) => (
  <ButtonSpan
    {...props}
    ref={ref}
    className={className}
    {...{ active, reversed }}
  />
))

export default FormatButton
