import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import usePhilosopher from "./use_philosopher"
import usePaper from "./use_paper"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import Button from "@material-ui/core/Button"
import { getPaper } from "./fetch_url"
import NavTo from "../../elements/nav_to"
import CircularProgress from "@material-ui/core/CircularProgress"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  opacity: 0;
  transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      max-height: inherit;
    `}
  & > div {
    font-size: 14px;
    /* padding: 5px; */
    line-height: 25px;
    width: 600px;
    /* display: flex;
    align-items: center; */
  }
  strong {
    font-size: 12px;
  }
  button {
    margin-top: 20px;
    color: #65a3da;
    border-color: #65a3da;
  }
`

const AlreadyAdded = styled(CheckCircleIcon)`
  color: #6ca56c;
  font-size: 15px;
  margin-left: 5px;
  margin-bottom: -3px;
`

// ========================================= //
// Main Component
// ========================================= //

const Paper = ({ id, philosophersID, papersID, fields }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [philosopher, createPhilosopher] = usePhilosopher(
    philosophersID,
    fields.philosopher
  )
  const [paper, createPaper] = usePaper(papersID, fields.name)
  const [addPaperNext, setAddPaperNext] = useState(false)
  const [gettingPaper, setGettingPaper] = useState(false)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (philosopher && addPaperNext) handleAddPaper()
  }, [philosopher])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleAddPaper = () => {
    if (!philosopher) {
      setAddPaperNext(true)
      return createPhilosopher()
    }
    setAddPaperNext(false)
    createPaper(philosophersID, philosopher.id, fields)
  }

  const handleGetPaper = () => {
    console.log(fields.download)

    setGettingPaper(true)
    const fn = () => setGettingPaper(false)
    getPaper(fields.download, fn)
  }

  const getValue = (key, value) => {
    if (key === "philosopher" && philosopher) {
      return <NavTo itemID={philosopher.id}>{value}</NavTo>
    } else if (key === "name" && paper) {
      return <NavTo itemID={paper.id}>{value}</NavTo>
    } else {
      return value
    }
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container show={fields}>
      {_.map(fields, (value, key) => (
        <div key={key}>
          <strong>{key}</strong>:{" "}
          {_.includes(["reference", "download"], key) ? (
            <a target="_blank" href={value}>
              {value}
            </a>
          ) : (
            <>{getValue(key, value)}</>
          )}
          <span>
            {key === "philosopher" && philosopher && <AlreadyAdded />}
            {key === "name" && paper && <AlreadyAdded />}
          </span>
        </div>
      ))}
      <Button
        variant="outlined"
        color="primary"
        disabled={!!paper}
        onClick={handleAddPaper}
      >
        {paper ? "Included" : "Add to Papers"}
      </Button>
      {fields.download && (
        <Button
          style={{ marginLeft: 10, color: "red", borderColor: "red" }}
          variant="outlined"
          color="secondary"
          disabled={gettingPaper}
          onClick={handleGetPaper}
        >
          {gettingPaper ? <CircularProgress /> : "Get Paper"}
        </Button>
      )}
    </Container>
  )
}

export default Paper
