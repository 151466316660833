import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import SetMenuItemOpen from "../../elements/set_menu_item_open"
import SetActive from "../../elements/set_active"
import BaseItem, {
  CardStyled,
  showOnCardHover,
  verticalCenter,
} from "../../elements/base_item"
import ToggleArrow from "../../elements/set_active_button"
import AddToList from "../../features/add_to_list"
import Name from "../../elements/name"
import Description from "../../elements/description"
import Item from "./item"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import Panel from "./panel"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  /* flex: 1 0 auto; */
  display: flex;
  min-width: 250px;
  /* min-height: 300px; */
  scroll-snap-align: end;
  flex-direction: column;
  /* height: 100%; */
  /* border-top: 1px solid ${(props) => props.theme.section.border}; */
  /* border-left: 1px solid ${(props) => props.theme.section.border}; */
`

const Title = css`
  font-size: 12px;
  font-weight: bold;
`

const Header = styled.div`
  ${Title};
  padding: 10px 10px 10px 20px;
  border-top: 1px solid ${(props) => props.theme.section.border};
  border-bottom: 1px solid ${(props) => props.theme.section.border};
`

const NameStyled = styled(Name)`
  ${Title};
`

const List = styled.div`
  /* flex-grow: 1; */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  & > :first-child {
    margin-top: 1px;
    border-top: 1px solid ${(props) => props.theme.section.border};
  }
`

const AddToListStyled = styled(AddToList)`
  justify-self: flex-end;
`

const Empty = styled.div`
  padding: 20px;
  color: #dedede;
  font-size: 12px;
  font-weight: bold;
`

// ========================================= //
// Main Component
// ========================================= //

const LinkedItems = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [linkedIDs] = useItem([id, "linkedIDs"])

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //
  // Referencing Items
  // Not referenced yet
  // Not referenced by other item
  // No items reference this

  return (
    <Panel title="Referenced by" last={true}>
      {!_.size(linkedIDs) && <Empty>No other items reference this</Empty>}
      <List>
        {_.map(linkedIDs, (itemID) => (
          <Item key={itemID} id={itemID} parentID={false} />
        ))}
      </List>
    </Panel>
  )
}

export default LinkedItems
