import _ from "lodash"

const getFieldByPath = (doc, path, type = "textContent") => {
  try {
    return doc.querySelector(path)[type]
  } catch (e) {
    return ""
  }
}

export const parsePaper = (html, entryIndex = 0) => {
  if (!html) return false
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")
  const list = doc.querySelector(".entryList")
  const all = list.querySelectorAll("li")
  const entry = all[entryIndex]
  // const first = list.querySelector("li")
  const reference = getFieldByPath(entry, ".citation a", "href")
  const name = _.trim(getFieldByPath(entry, ".articleTitle"), ".")
  const philosopher = getFieldByPath(entry, ".name")
  const published = getFieldByPath(entry, ".pubYear")
  const abstract = getFieldByPath(entry, ".abstract")
  const download = (() => {
    try {
      const source = getFieldByPath(entry, ".extras .options a", "href")
      const url = new URL(source)
      return url.searchParams.get("u")
    } catch (e) {
      // catches a malformed URI
      console.error("error parsing download link:", e)
      return false
    }
  })()

  return { reference, name, philosopher, published, abstract, download }
}
