import _ from "lodash"
import scheduler from "./scheduler"
import repeat from "./repeat"

const tasks = [repeat]
_.each(tasks, scheduler.addTask)

const tasksMiddleware = store => next => action => {
  // grab conditions from each intention when loaded
  // if (action.type === "LOAD_ITEM") {
  //   console.log('task middleward:', action.payload);
    
  // }

  // once data has loaded start scheduler
  if (action.type === "LOADED") {
    scheduler.run(store)
  }

  return next(action)
}

export default tasksMiddleware
