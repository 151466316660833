import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"

// ========================================= //
// Main
// ========================================= //

const Element = ({ plugins, children, ...props }) => {
  const El = plugins[props.element.type] || DefaultElement
  return <El {...props}>{children}</El>
}

const DefaultElement = ({ plugins, children, ...props }) => {
  const El = props.attributes["data-slate-inline"] ? `span` : `p`
  return <El {...props}>{children}</El>
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Element.propTypes = {
  /**
   * An array of plugin interface elements
   */
  plugins: PropTypes.object.isRequired,
}

Element.defaultProps = {
  plugins: {},
}

// const Element = (props) => {
//   let element
//   _.each([ImageElements, LinkedItemElements, MarkdownElements], (El) => {
//     element = El(props)
//     if (element) {
//       element = <El {...props} />
//       return false
//     }
//   })

//   return <>{element}</>
// }

export default Element
