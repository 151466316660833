import _ from "lodash"
import React, { useRef, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItem, useItemsPath } from "../../../store/items/selectors"
import Name from "../../elements/name"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import Results from "./results"
import { setTimeout, SpeechRecognitionResultList } from "window-or-global"
import { fetchUrl, getPDF } from "../phil_papers/fetch_url"
import { parseGoogle } from "./parser"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50vw - 75;
  padding: 10px 20px 10px 20px;
  h1,
  h2 {
    font-weight: normal;
    margin: 5px 0 10px 0;
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
    border-bottom: 1px black solid;
    border-bottom: 1px #ebebeb solid;
    padding-bottom: 5px;
  }
  svg {
    width: 20px;
  }
`

const Form = styled("form")`
  margin-bottom: 20px;
  padding: 20px;
  background: #f5f5f5;
  border: 1px #e8e7e7 solid;
  input {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    border: 1px #f3eeee solid;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Vocab = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const googleUrl = "https://www.google.com/search?q=define+"
  const baseUrl = "https://www.lexico.com/en/definition/"
  const [totalWords, { createItem }] = useItem([id, "itemIDs"], _.size)
  const [term, setTerm] = useState("")
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState(false)
  const [error, setError] = useState()

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {}, [])

  // ========================================= //
  // Functions
  // ========================================= //

  const addEntry = () => {}

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setResults(false)
    scrape()
    // setTimeout(() => {
    //   setLoading(false)
    //   setResults('YAY')
    // }, 1000)
  }

  const scrape = () => {
    const url = baseUrl + encodeURIComponent(term)
    // getPDF(url)
    fetchUrl(
      url,
      (html) => {
        // setResults(html)
        setLoading(false)
        setError(false)
        const parsed = parseGoogle(html)
        setResults(parsed)
      },
      (e) => {
        console.log(e)
        setLoading(false)
        setError(e.message)
      }
    )
  }

  // ========================================= //
  // Render˝
  // ========================================= //

  return (
    <Container className={className}>
      <Inner>
        <Name id={id} tagName={"h3"} />
        Total words: {totalWords}
        <Form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Word or phrase"
            value={term}
            onChange={({ target: { value } }) => setTerm(value)}
          />
          {!loading && (
            <Button variant="outlined" type="submit" value="Submit">
              Look Up
            </Button>
          )}
        </Form>
        {loading && <CircularProgress />}
        {error && <>{error}</>}
        {results && (
          <div
            dangerouslySetInnerHTML={{ __html: results }}
          />
        )}
        {/* <Results {...{ id, results }} /> */}
      </Inner>
    </Container>
  )
}

export default Vocab
