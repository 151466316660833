import _ from "lodash"
import options from "../../app/reducers/conditions"
import updateItem from "./update_item"
import repeatChildren from "./utils/repeat_children"
import updateStatus from "./update_status"

const add = (state, action) => {
  const { id, type, conditionID } = action.payload
  const item = state[id]
  const condition = options[type][conditionID]  

  item.conditions[type][condition.id] = _.cloneDeep(condition)
  item.conditions[type][condition.id].createdAt = Date.now()
  state = updateItem(state, { payload: { fields: item, overwrite: true } })
  state = updateStatus(state, { payload: { id } })
  return state
}

const run = (state, action) => {
  const { id, condition } = action.payload

  if (!condition.type) return state

  // only repeat actions taken after condition was added to intention
  const filterFn = item => item.completedAt > condition.createdAt
  state = repeatChildren(state, state[id], filterFn)

  state[id].conditions[condition.type][condition.id].lastRanAt = Date.now()
  return updateItem(state, { payload: { fields: state[id], overwrite: true } })
}

const remove = (state, action) => {
  const { id, type, conditionID } = action.payload
  const item = state[id]
  delete item.conditions[type][conditionID]
  state = updateItem(state, { payload: { fields: item, overwrite: true } })
  state = updateStatus(state, { payload: { id } })
  return state
}

export default { add, run, remove }
