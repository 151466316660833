import _ from "lodash"
import React, { useEffect } from "react"
import { useApp } from "../../../../store/app/selectors"
import { useUser } from "../../../../store/user/selectors"
import { useDomains } from "../../../../store/domains/selectors"
import { useItem } from "../../../../store/items/selectors"

/**
 * New accounts or accounts with no main domain item
 * will need to have it created
 */

// ========================================= //
// Main Component
// ========================================= //

const FirstItem = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [itemsLoaded] = useApp(["status", "itemsLoaded"])
  const [mainItem, { createItem }] = useItem("intentions")

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (itemsLoaded && !mainItem) {
      console.log("no items found, creating first...")
      createItem({ id: "intentions", name: "All", parentID: false }, false)
    }
  }, [itemsLoaded, mainItem])

  // ========================================= //
  // Render
  // ========================================= //

  return children || null
}

export default FirstItem
