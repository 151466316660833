import _ from "lodash"
import updateParents from "./utils/update_parents"
import updateItem from "./update_item"

const satisfyItem = (state, action) => {
  const { id, satisfied = true } = action.payload
  const item = state[id]

  _.merge(item, {
    satisfied,
    satisfiedAt: satisfied ? Date.now() : false,
  })

  state = updateItem(state, { payload: { fields: item } })
  state = updateParents(state, item)

  return state
}

export default satisfyItem
