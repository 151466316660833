import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import { useApp } from "../../../store/app/selectors"
import Modal from "../../features/modal"
import Confetti from "./confetti"
import Overlay from "../../elements/overlay"
import Button from "@material-ui/core/Button"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  position: relative;
  z-index: 1000001;
  transition: all 0.3s;
  height: ${(props) => (props.show ? 40 : 0)}px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #60a6c7;
  box-shadow: inset 0px -1px 1px #3b7e9e;
`

const hover = css`
  transition: all 0.3s;
  color: #437892;
  &:hover {
    color: white;
  }
`

const Close = styled(HighlightOffIcon)`
  position: absolute;
  left: 15px;
  cursor: pointer;
  ${hover}/* transition: all 0.3s;
  color: #437892;
  &:hover {
    color: white;
  } */
`

const Title = styled.div`
  margin-left: 30px;
  font-size: 13px;
  ${hover}
  color: #a5e2ff;
  cursor: pointer;
`

const Date = styled.div`
  font-size: 10px;
  color: #505050;
  font-weight: bold;
`

const Changes = styled.div`
  padding: 10px;
  font-size: 14px;
  line-height: 25px;
  h1 {
    font-size: 20px;
    color: #545454;
    font-weight: normal;
    margin: 5px 0 25px 0;
    border-bottom: 1px #e8e8e8 solid;
    padding-bottom: 5px;
  }
  strong {
    font-size: 10px;
  }
  ul {
    padding-left: 20px;
  }
`

const ShowAllButton = styled(Button)`
  color: ${(props) => props.theme.element.text.standard};
  border-color: ${(props) => props.theme.element.text.standard};
  margin: 10px;
`

// ========================================= //
// Main Component
// ========================================= //

const Notice = () => {
  // ========================================= //
  // State
  // ========================================= //

  const version = `0.0.30`
  const [lastVersion, actions] = useApp("notice")
  const [showLog] = useApp("show.updateLog")
  const [show, setShow] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [celebrate, setCelebrate] = useState(lastVersion != version)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastVersion !== version) setShow(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (showLog) setShowModal(showLog)
  }, [showLog])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClose = (e) => {
    actions.setSetting({ notice: version })
    actions.setSetting({ show: { updateLog: false } })
    setShow(false)
    setShowModal(false)
    setShowAll(false)
  }

  const handleOpen = (e) => {
    setShowModal(true)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      {show && celebrate && <Confetti />}
      {showModal && celebrate && <Confetti />}

      <Container show={show}>
        <Close onClick={handleClose} />
        <Title onClick={handleOpen}>Version {version} released</Title>
        {/* <Overlay visible={showModal} onClose={handleClose}> */}

        {/* </Overlay> */}
      </Container>

      <Modal open={showModal} onClose={handleClose}>
        <Changes>
          <Date>Friday, July 10</Date>
          <h1>Version 0.0.31 released</h1>
          <strong>Changes:</strong>
          <ul>
            <li>
              Improved speed of navigation
            </li>
            <li>Better animations while navigating (more like animYAYtions!)</li>
            <li>Added change log to main menu.</li>
            <li>
              Tons of other improvements "under the hood" that you'll never see
              but which I assure took days of my life away from me.
            </li>
          </ul>
        </Changes>

        {!showAll ? (
          <ShowAllButton variant="outlined" onClick={() => setShowAll(true)}>
            View past changes
          </ShowAllButton>
        ) : (
          <>
            <Changes>
              <Date>Sunday, June 14</Date>
              <h1>Version 0.0.30 released</h1>
              <strong>Changes:</strong>
              <ul>
                <li>
                  Removed the "feature" whereby items containing bullet lists
                  would crash the app.{" "}
                </li>
                <li>
                  Added version update release notes (this thing you're reading)
                </li>
                <li>Confetti</li>
              </ul>
            </Changes>
          </>
        )}
      </Modal>
    </>
  )
}

export default Notice
