import _ from "lodash"
import { Editor, Transforms, Text, Node, Range } from "slate"
import Element from "./element"

// ========================================= //
//  Main component
// ========================================= //

const withInlineSearch = (editor) => {
  const type = "inline-search"
  const trigger = "//"

  const {
    isVoid,
    isInline,
    insertText,
    extended: { onUpArrow, onDownArrow },
  } = editor

  // ========================================= //
  // Register render logic
  // ========================================= //

  // // set the render element
  // const render = (props) => {
  //   console.log("inside props", props.leaf)
  //   return props.leaf[type] && Element
  // }
  // editor.plugins.leaves[type] = render

  // set the render element
  editor.plugins.elements[type] = Element

  // ========================================= //
  // Extend existing editor functions
  // ========================================= //

  editor.isInline = (element) => {
    return element.type === type ? true : isInline(element)
  }

  // editor.isVoid = (element) => {
  //   return element.type === type ? true : isVoid(element)
  // }

  editor.insertText = (character) => {
    // call default editor function
    insertText(character)

    if (shouldTrigger(character)) showSearch()
  }

  // editor.extended.onUpArrow = (event) => {
  //   if (!editor.extended.inlineSearch.active) onUpArrow()
  //   event.preventDefault()
  // }

  // editor.extended.inlineSearch = {}
  // editor.extended.onDownArrow = (event) => {
  //   if (!editor.extended.inlineSearch.active) onDownArrow()
  //   event.preventDefault()
  // }

  // ========================================= //
  // Add editor functions
  // ========================================= //

  editor.extended.inlineSearch = {}

  editor.extended.inlineSearch.setLink = (itemID, path) => {
    // empty text leaf (contains partially search term)
    Transforms.delete(editor, path)
    const link = { itemID, expand: true }
    editor.extended.link.make(link, path)
  }

  editor.extended.inlineSearch.remove = (path) => {
    Transforms.removeNodes(editor, { at: path })
  }

  // ========================================= //
  //  Supporting functions
  // ========================================= //

  const shouldTrigger = (character) => {
    return character === "/" && editor.extended.getCurrentWord() === trigger
  }

  const showSearch = () => {
    // select the trigger pattern e.g. "//"
    editor.extended.selectCurrentWord(true)

    // insert a new node to show the item search
    const mention = { type, children: [{ text: " " }] }
    Transforms.insertNodes(editor, mention)

    // move the cursor before the space so that text remains in the new node
    // select the placeholder text
    // editor.extended.selectCurrentWord()
    Transforms.move(editor, { reverse: true })
  }

  // ========================================= //
  // Return extended editor
  // ========================================= //

  return editor
}

export default withInlineSearch
