import _ from "lodash"
import React from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div``
const ContainerOLD = styled.div`
  outline: none !important;
  &.card-ghost {
    /* border: 1px blue solid; */
  }
  &.drop-preview {
  }
  &:active:not(.card-ghost) {
    /* opacity: 0; */
    /* z-index: 0; */
  }
`

// ========================================= //
// Main Component
// ========================================= //

const SetMenuItemOpen = ({ id, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, { setMenuItem }] = useApp(["menuItem", "isOpen"])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    setMenuItem({ id, isOpen: true })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return <Container onClick={handleClick}>{children}</Container>
}

export default SetMenuItemOpen
