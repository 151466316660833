import _ from "lodash"
import React, { useState, useEffect } from "react"
import { searchDB } from "../search"
import useItem from "./use_item"

const useSearch = (options = { limit: 10 }) => {
  const [term, setTerm] = useState(false)
  const [results, setResults] = useState([])
  const [items] = useItem()

  useEffect(() => {
    searchDB.search(term, options).then((result) => {
      result = _.map(result, (itemID) => items[itemID])
      setResults(result)
    })
  }, [term])

  return [setTerm, results]
}

export default useSearch
