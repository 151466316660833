import styled, { css } from "styled-components/macro"

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  background: #fbfbfb;
  color: #5f5f5f;
  margin-right: 1px;
  transition: background 1s, color 0s;
  &:hover {
    background: #5f5f5f;
    color: white;
  }
`

export const Inner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`

export const effect = css`
  transition: all 0.3s;
  padding: 0;
  ${Container}:hover & {
    padding: 2px;
  }
`

export const Name = styled.h2`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: normal;
  ${effect}
  text-align: center;
`

export const Value = styled.div`
  font-size: 35px;
  margin: 10px;
  ${effect}
`
