const shareDomain = (db, usersCollection, userID, domain, email) => {
  if (!userID) return console.log("missing userID")
  if (!domain) return console.log("missing domain")

  db.collection(usersCollection)
    .doc(userID)
    .collection("domains")
    .doc(domain.id)
    .collection("invites")
    .doc(email)
    .set({ status: "sent" })
    .catch(error => {
      console.log("Firebase error while loading:", error)
    })
}

export default shareDomain
