import _ from "lodash"
import React, { useState } from "react"
import styled from "styled-components"
import { useItem } from "../../../store/items/selectors"
import FieldLabel from "./field_label"
import CustomFieldSearch from "./field_search"
import ShareIcon from "@material-ui/icons/Share"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  span {
    transition: all 0.3s;
    opacity: 0.4;
  }
  svg {
    transition: all 0.3s;
    opacity: 0;
    font-size: 15px;
    margin-left: 10px;
  }
  &:hover {
    span {
      opacity: 1;
    }
    svg {
      opacity: 1;
    }
  }
`

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldLink = ({
  id,
  fieldID,
  showLabel,
  defaults,
  isNew,
  onBlur,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  fieldID = _.get(defaults, "fieldID", fieldID)

  const [fieldValue, actions] = useItem([id, "fields", fieldID, "value"])
  const value =  _.get(defaults, "value", fieldValue)

  const linkedPath = value ? _.flatten([value.id, value.path]) : false

  const [linkedContent] = useItem(linkedPath)
  const content = _.get(linkedContent, "value", linkedContent)

  const [showModal, showModalSet] = useState(isNew)

  // ========================================= //
  // Functions
  // ========================================= //

  const save = value => {
    const field = _.has(defaults, "name") ? _.clone(defaults) : { type: "link" }
    field.value = value    
    actions.setFields({
      id,
      fields: {
        [fieldID]: field,
      },
    })
  }

  const handleDismiss = () => {
    if (!value) {
      // delete field on modal close if nothing selected
      actions.setFields({ id, fields: { [fieldID]: false } })
    }
    if (onBlur) onBlur()
    closeModal()
  }

  const closeModal = () => {
    showModalSet(false)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      {showLabel && <FieldLabel id={id} fieldID={fieldID} onBlur={onBlur} />}
      <Content onClick={() => showModalSet(true)}>
        {_.toString(content) || <span>Click to search items</span>}
        <ShareIcon />
      </Content>
      {showModal && (
        <CustomFieldSearch
          id={id}
          fieldID={fieldID}
          link={value}
          isOpen={showModal}
          onDismiss={handleDismiss}
          onSave={save}
          onClose={closeModal}
        />
      )}
    </Container>
  )
}

export default CustomFieldLink
