import React from "react"

// ========================================= //
// Main Component
// ========================================= //

const Mobile = () => {
  // ========================================= //
  // Render
  // ========================================= //

  return <>You are on a mobile device</>
}

export default Mobile
