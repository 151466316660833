import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItem, useItems } from "../../../store/items/selectors"
import ViewArea from "../../sections/view_area"
import { fetchUrl } from "./fetch_url"
import { parsePaper } from "./parse_paper"
import Config from "./config"
import Results from "./results"
import { TopBar } from "../../features"
import { allowTouch } from "../../_styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${allowTouch}
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50vw - 75;
  padding: 2px 20px 10px 20px;
  h1,
  h2 {
    font-weight: normal;
    margin: 5px 0 10px 0;
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
    border-bottom: 1px black solid;
    border-bottom: 1px #ebebeb solid;
    padding-bottom: 5px;
  }
`

const Form = styled("form")`
  margin-bottom: 20px;
  padding: 20px;
  background: #f5f5f5;
  border: 1px #e8e7e7 solid;
  input {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    border: 1px #f3eeee solid;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const PhilPapers = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const baseUrl = "https://philpapers.org/s/"
  const [settings] = useItem([id, "view", "philpapers"])
  const configured =
    _.has(settings, "cors_api_url") &&
    _.has(settings, "papersID") &&
    _.has(settings, "philosophersID")
  const { philosophersID, papersID } = configured ? settings : {}
  const [author, setAuthor] = useState("")
  const [title, setTitle] = useState("")
  const [results, setResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  // ========================================= //
  // Functions
  // ========================================= //

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setResults(false)
    scrape()
  }

  const scrape = () => {
    const url = baseUrl + encodeURIComponent(title + " " + author)
    fetchUrl(
      url,
      (html) => {
        setResults(html)
        setLoading(false)
        setError(false)
      },
      (e) => {
        console.log(e)
        setLoading(false)
        setError(e.message)
      }
    )
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <ViewArea center={true}>
      {!configured ? (
        <Config id={id} />
      ) : (
        <Container className={className}>
          <Inner>
            <TopBar fixed={true}>
              <h1>Search Philpapers</h1>
            </TopBar>
            <Form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Author"
                value={author}
                onChange={({ target: { value } }) => setAuthor(value)}
              />
              <input
                type="text"
                placeholder="Paper Title"
                value={title}
                onChange={({ target: { value } }) => setTitle(value)}
              />
              {!loading && (
                <Button variant="outlined" type="submit" value="Submit">
                  Search
                </Button>
              )}
            </Form>
            {loading && <CircularProgress />}
            {error && <>{error}</>}
            <Results {...{ id, philosophersID, papersID, results }} />
          </Inner>
        </Container>
      )}
    </ViewArea>
  )
}

export default PhilPapers
