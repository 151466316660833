import _ from "lodash"
import React from "react"
import LayoutContextWrapper from "./layout_context"
import Configure from "./configure"
import Controls from "./controls"
import { ViewArea } from "../../sections"
import { Container, NameHeader } from "./style"
import { TopBar } from "../../features"
import Layout from "./layout"

// ========================================= //
// Main Component
// ========================================= //

const Builder = ({ id, view = {}, className }) => {
  
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <LayoutContextWrapper id={id} view={view}>
      <Configure />
      <Controls />
      <ViewArea mobileFullWidth={true} className={className}>
        <Container>
          <TopBar fixed={true}>
            <NameHeader
              key={id + "name"}
              id={id}
              listID="menu"
              tagName="h3"
              editable={true}
            />
          </TopBar>
          <Layout />
        </Container>
      </ViewArea>
    </LayoutContextWrapper>
  )
}

export default Builder
