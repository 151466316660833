import _ from "lodash"

// const statusTemps = () => {
//   return {
//     default: [
//       {
//         id: "active",
//         name: "Active",
//         isMain: true,
//       },
//       {
//         id: "inProgress",
//         name: "In Progress",
//       },
//       {
//         id: "completed",
//         name: "Completed",
//       },
//     ],
//   }
// }

const loadDomains = (state, action) => {
  const { domains } = action.payload

  _.each(domains, domain => {

    // Change this when ready to set custom templates
    // domain.statusTemps = statusTemps() //|| domain.statusTemps || statusTemps()
    domain.views = domain.views || {}
    state[domain.id] = domain
  })

  return state
}

export default loadDomains
