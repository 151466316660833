import _ from "lodash"
// import reorderByStatus from "./reorder_by_status"
import updateItem from "./update_item"
import { arrange } from "../../../utils"

const reorder = (state, action) => {
  const { id, parentID, field, position } = action.payload
  let parent = state[parentID]

  if (field) {
    // apply status filter to know which siblings to sort relative to
    const hasField = itemID => {
      // return (
      //   state[itemID].statusID === statusID ||
      //   (!state[itemID].statusID && statusID === "active")
      // )
      return (
        _.get(state[itemID], ["fields", field.sourceID, "value", "id"]) === field.id
      )
    }
    const filtered = _.filter(parent.itemIDs, hasField)

    // Nothing to reorder; only one item
    if (_.size(filtered) === 0) return state

    // get id currently at position
    const positionID = filtered[position]
    // find the index of this item within the main parent list
    const siblingIndex = _.indexOf(parent.itemIDs, positionID)
    // add the current id at that index
    parent = arrange(parent, id, siblingIndex)
  } else {
    parent = arrange(parent, id, position)
  }

  return updateItem(state, { payload: { fields: parent, overwrite: true } })
}

export default reorder
