
const updateDomain = (db, usersCollection, userID, domain, callback) => {
  if (!userID) return console.log("missing userID")
  if (!domain) return console.log("missing domain")

  // userID = userID ? userID : auth().currentUser.uid
  db.collection(usersCollection)
    .doc(userID)
    .collection('domains')
    .doc(domain.id)
    .set(domain)
    .catch(error => {
      console.log("Firebase error while loading:", error)
    })
}

export default updateDomain
