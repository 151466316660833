const getDomain = (db, userCollection, userID, domainID, onError) => {
  return new Promise((resolve, reject) => {
    if (!userID) return console.log("missing userID")
    db.collection(userCollection)
      .doc(userID)
      .collection("domains")
      .doc(domainID)
      .get()
      .then(snapshot => {
        resolve(snapshot.data())
      })
      .catch(error => {
        if (onError) {
          if (onError()) {
            console.log('retrying');
            
            // retry once
            getDomain(db, userCollection, userID, domainID).then(resolve)
          }
        }
        console.log("Firebase error while loading:", error)
      })
  })
}

export default getDomain
