import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import Name from "../../elements/name"
import Editor from "../../features/editor"
import ItemFields from "../../features/item_fields"
import ItemActions from "../../features/item_actions"
// import ItemOptionsMenu from "../../elements/item_options_menu"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

import CompareArrowsIcon from "@material-ui/icons/CompareArrows"
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom"
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter"
import HeightIcon from "@material-ui/icons/Height"
import SetSetting from "../../elements/set_setting"
import Breadcrumbs from "../../features/breadcrumbs"
import BreadcrumbsTo from "../../features/breadcrumbs_to"
import FormatColorTextIcon from "@material-ui/icons/FormatColorText"
import { ViewArea } from "../../sections"
import { TopBar } from "../../features"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2px 20px;
  /* border: 2px solid yellow; */
`
const Body = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  /* border: 2px solid green; */
`

const NameHeader = styled(Name)`
  margin: 10px 10px 0px 0;
  /* width: 80%; */
  line-height: 25px;
`

const EditorStyled = styled(Editor)`
  line-height: 25px;
  margin-bottom: 3px;
`

// ========================================= //
// Main Component
// ========================================= //

const Main = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [id] = useApp("currentItemID")

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <ViewArea mobileFullWidth={true}>
      <Container className={className}>
        {id && (
          <>
            <TopBar fixed={true}>
              <NameHeader
                key={id + "name"}
                id={id}
                listID="menu"
                tagName="h3"
                editable={true}
              />
            </TopBar>
            <Body>
              <EditorStyled
                key={id + "editor"}
                id={id}
                listID="menu"
                editable={true}
              />
            </Body>
          </>
        )}
      </Container>
    </ViewArea>
  )
}

export default Main
