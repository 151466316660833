import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { motion } from "framer-motion"

// ========================================= //
// Styles
// ========================================= //

const Container = styled(motion.div)`
  display: flex;
  overflow: auto;
`

// ========================================= //
//  Main component
// ========================================= //

/**
 * A listener wrapper that will add the provided settings paths listeners
 * to the provided component and pass the current values as props.
 */

const DisplayStateAnimated = ({
  paths,
  Component,
  hardHide,
  reverse,
  children,
  className,
  ...props
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [state] = useApp(false, (state) => _.pick(state.app, paths))

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  const show = _.every(paths, (path) => _.get(state, path))

  const variants = {
    open: { height: "auto" },
    closed: { height: 0, overflow: "hidden" },
  }

  return (
    <Container
      initial={{ height: 0, overflow: "hidden" }}
      animate={show ? "open" : "closed"}
      variants={variants}
      transition={{ ease: "easeOut", duration: 0.2 }}
    >
      {children}
    </Container>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

DisplayStateAnimated.propTypes = {
  /**
   * The app state path to listen to
   */
  paths: PropTypes.array.isRequired,
  /**
   * The wrapper component. If none provided, a basic div with an active class added will be returned.
   */
  Component: PropTypes.elementType,
  /**
   * If setting is false or undefined, don't render children
   */
  hardHide: PropTypes.bool,
  /**
   * If paths are set, and hardHide set to true, hide children on true settings
   */
  reverse: PropTypes.bool,
}

DisplayStateAnimated.defaultProps = {
  Component: "div",
  hardHide: false,
  reverse: false,
}

export default DisplayStateAnimated
