import _ from "lodash"
import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { LayoutContext } from "../layout_context"
import { RGL, Inner } from "./style"
import Block from "./block"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

// ========================================= //
//  Main component
// ========================================= //

const Layout = ({}) => {
  // ========================================= //
  // State
  // ========================================= //

  const {
    isEditing,
    layouts,
    blocks,
    addBlock,
    updateBlock,
    droppingItem,
  } = useContext(LayoutContext)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleDrop = (layout, layoutItem, event) => {
    addBlock({ ...droppingItem, ...layoutItem })
  }

  const handleChange = (newLayout) => {
    console.log('handleChange');
    _.map(newLayout, (block) => updateBlock(block.i, block))
  }

  const getDroppingItem = () => {
    const i = "defaultIndex"
    const defaults = { i, h: 40, w: 30 }
    return droppingItem ? { ...defaults, ...droppingItem} : defaults
  }

  // ========================================= //
  // Render
  // ========================================= //
  console.log("rerender:", blocks)

  const cols = 10
  return (
    <Inner>
      <RGL
        isDraggable={isEditing}
        isResizable={isEditing}
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        // cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        cols={{ lg: cols, md: cols, sm: cols, xs: cols, xxs: cols }}
        rowHeight={10}
        // verticalCompact={false}
        compactType={null}
        isBounded={true}
        autoSize={false}
        isDroppable={true}
        droppingItem={getDroppingItem()}
        onDrop={handleDrop}
        onLayoutChange={handleChange}
      >
        {_.map(blocks, (block) => (
          <div key={block.i}>
            <Block block={block} />
          </div>
        ))}
      </RGL>
    </Inner>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Layout.propTypes = {}

Layout.defaultProps = {}

export default Layout
