import _ from "lodash"
import React from "react"
import { useItem, useItems } from "../../../store/items/selectors"
import { newPaper } from "./new_paper"

const usePaper = (papersID, paperName) => {
  const [paperIDs, { createItem }] = useItem([papersID, "itemIDs"])
  const [papers] = useItems(paperIDs, ["id", "name"])
  const paper = _.filter(papers, ({ name }) => name === paperName)[0]

  const createPaper = (philosophersID, philosopherID, fields) => {
    if (paper) return false
    createItem({
      name: fields.name,
      position: 0,
      parentIDs: [papersID],
      fields: newPaper(philosophersID, philosopherID, fields),
    })
  }

  return [paper, createPaper]
}

export default usePaper
