import _ from "lodash"
import createItem from "./create_item"

const determineID = id => {
  // Avoid race conditions by keeping new item id deterministic
  const [base, lineage] = _.split(id, "-")
  const next = _.toNumber(lineage || 0) + 1
  return base + "-" + next
}

const reCreateItem = (state, action) => {
  const { id } = action.payload
  const item = state[id]
  const nextID = determineID(id)

  // don't recreate more than once
  if (state[nextID]) return state

  const newItem = _.cloneDeep(item)
  newItem.id = nextID
  newItem.originalID = id

  return createItem(state, { payload: { fields: { ...newItem } } })
}

export default reCreateItem
