import _ from "lodash"
import React, { useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import PropTypes from "prop-types"
import { effect } from "./style"

// ========================================= //
// Styles
// ========================================= //

// ========================================= //
//  Main component
// ========================================= //

const SlideIn = ({ children, ...props }) => {
  // ========================================= //
  // State
  // ========================================= //

  const loads = useRef(0)
  // force css animation reload
  loads.current += 1
  const reload = loads.current % 2 === 0
  if (loads.current === 2) loads.current = 0

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <CustomTag reload={reload ? 1 : 0} {...props} css={effect}>
      {children}
    </CustomTag>
  )
}

const CustomTag = ({ tagName, children, className }) => {
  const Tag = `${tagName}`
  return <Tag className={className}>{children}</Tag>
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

SlideIn.propTypes = {
  /**
   * The type of html element to use
   */
  tagName: PropTypes.string,
}

SlideIn.defaultProps = {
  tagName: "div",
}

export default SlideIn
