import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import { Icons } from "./all_icons"
import { SvgIconStyled } from "./style"

// ========================================= //
//  Main component
// ========================================= //

const Icon = ({ name, size }) => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const getSize = () => {
    if (_.isNumber(size)) return size

    switch (size) {
      case "x-small":
        return 8
      case "small":
        return 10
      case "medium":
        return 12
      case "large":
        return 15
      case "x-large":
        return 20
    }
  }

  // ========================================= //
  // Render
  // ========================================= //

  if (!name) return null

  return <SvgIconStyled component={Icons[name]} size={getSize()} />
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Icon.propTypes = {
  /**
   * The name of the icon component to display
   */
  name: PropTypes.string.isRequired,
  /**
   * "small", "medium" etc. or number for custom size
   */
  size: PropTypes.any,
}

Icon.defaultProps = {
  size: "medium",
}

export default Icon
