import _ from "lodash"
import React from "react"
import styled, { keyframes } from "styled-components"
import { useApp } from "../../store/app/selectors"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/Add"

// ========================================= //
// Style
// ========================================= //

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(180deg);
	}
`

const IconButtonStyled = styled(IconButton)`
  border-radius: 0;
  transition: all 0.3s;
  margin: 0 18px 0 2px;
  display: block;
  opacity: 0;
  ${props => props.container && props.container}:hover & {
    opacity: 1;
  }
  & div {
    transition: all 0.3s; 
    /* max-width: 0;
    overflow: hidden; */
    opacity: 0;
    font-size: 0px;
  }
  &:hover {
    /* background: #e7eaef; */
    background: none;
    font-size: 20px;
    color: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -40})};
    opacity: 1;
    svg {
      animation: ${rotate} 0.3s linear;
    }
    & div {
      opacity: 1;
      font-size: 13px;
    }
  }
`

// ========================================= //
// Main Component
// ========================================= //

const NewItemButton = ({ listID, setShow, options, container }) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [, { setNewItemParentID }] = useApp(false)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleAdd = () => {
    setShow(true)
    // setNewItemParentID(listID)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <IconButtonStyled
      fontSize="small"
      aria-label="add"
      container={container}
      onClick={handleAdd}
    >
      <AddIcon />
      <div>Add Item</div>
    </IconButtonStyled>
  )
}

export default NewItemButton
