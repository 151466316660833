import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../store/items/selectors"
import { allowTouch } from "../_styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import matchSorter from "match-sorter"

// ========================================= //
// Style
// ========================================= //

const StyledAutocomplete = styled(Autocomplete)`
  margin: 0;
  input {
    color: ${(props) =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -60 })};
  }
  /* & .MuiOutlinedInput-notchedOutline {
     border-color: white;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: white;
  } */
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #588cb1;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #588cb1;
  }
  label,
  svg {
    color: ${(props) =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -40 })};
  }
  ${allowTouch}
`

// ========================================= //
// Main Component
// ========================================= //

const SearchItems = ({
  label = "Search",
  filter = false,
  order = false,
  limit = 5,
  onChange,
  onBlur,
  defaultValue,
  type,
  className,
  variant,
  canCreate = true,
  autoFocus = false,
  setFocus = false,
  isMultiple = false,
  disableFn = false,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [items] = useItem([])
  const inputRef = useRef(null)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleChange = (event, value) => {
    if (value && onChange) onChange(value)
  }

  // const handleCreate = e => {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   console.log("MMMMMMMMK")

  //   alert("FUCK YEAH")
  // }
  // ========================================= //
  // Render
  // ========================================= //

  let options = _.values(items)
  // options = type ? _.filter(options, { type }) : _.values(items)
  // options = _.filter(options, item => _.isObject(item) && !_.isArray(item))
  // options = _.reject(options, { id: "intentions" })
  options = _.reject(options, { isDeleted: true })
  // options = _.reject(options, { completed: true })

  if (filter) options = _.filter(options, (option) => filter(items, option))
  if (order) options = order(options)

  if (isMultiple) {
    defaultValue = _.map(defaultValue, (id) => items[id])
  } else {
    defaultValue = defaultValue ? items[defaultValue] : null //{ name: "" }
  }

  return (
    <StyledAutocomplete
      className={className}
      id="nav-to"
      multiple={isMultiple}
      options={options}
      value={defaultValue}
      // defaultValue={defaultValue}
      autoHighlight={true}
      autoComplete={true}
      autoSelect={false}
      blurOnSelect={true}
      // this doesn't work. Need latest version of materia?
      // clearOnBlur={true}
      getOptionLabel={(option) => option.name}
      //groupBy={groupByFn}
      renderGroup={(params) => [
        <div key={params.key}>
          <strong>{params.key}</strong>
        </div>,
        params.children,
      ]}
      getOptionDisabled={disableFn ? disableFn : (option) => false}
      className={className}
      onChange={handleChange}
      // onBlur={() => console.log('FUCK YEAH')}
      // disableOpenOnFocus={true}
      noOptionsText={"Nothing found"}
      // renderTags={(value, getTagProps) =>
      //   value.map((option, index) => (
      //     <Chip
      //       label={option.name}
      //       // avatar={option.favorite && <Avatar>M</Avatar>}
      //       {...getTagProps({ index })}
      //       disabled={value.length === 1}
      //     />
      //   ))
      // }
      filterOptions={(options, { inputValue }) => {
        const results = matchSorter(options, _.trim(inputValue), {
          keys: ["name"],
          threshold: matchSorter.rankings.CONTAINS,
        }).slice(0, limit)
        return results.length
          ? results
          : canCreate
          ? [{ id: "create", name: "Create this item?", value: inputValue }]
          : []
      }}
      renderInput={(params) => (
        <TextField
          ref={inputRef}
          inputRef={(input) => input && setFocus && input.focus()}
          onBlur={onBlur}
          {...params}
          label={label}
          variant={variant || "outlined"}
          // value={defaultValue}
          fullWidth={true}
          autoFocus={setFocus}
        />
      )}
    />
  )
}

export default SearchItems
