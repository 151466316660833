import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import Nested from '../views/nested'

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Inner = styled.div`
  display: flex;
  min-width: 50vw - 75;
  padding: 0 20px;
`

// ========================================= //
// Main Component
// ========================================= //

const Main = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [limit] = useApp(["activeIntentionLimit"])

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className}>
      <Inner>
        <Nested />
      </Inner>
    </Container>
  )
}

export default Main
