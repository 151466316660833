import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import { useApp } from "../../../store/app/selectors"
import { MuiDrawer } from "./style"
import { ThemeConsumer } from "styled-components"

// ========================================= //
//  Main component
// ========================================= //

const Drawer = ({ path, side, overlay, overlayWhenMobile, z, ...props }) => {
  const { disableDiscovery } = props

  // ========================================= //
  // State
  // ========================================= //

  const [isOpen, { setSetting }] = useApp(path, false, false)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleOpen = () => {
    setSetting(_.set({}, path, true))
  }

  const handleClose = () => {
    setSetting(_.set({}, path, false))
  }

  const showOverlay = (theme) => {
    const isMobile = _.get(theme, "device.isTabletOrMobileDevice")
    return overlay || (overlayWhenMobile && isMobile)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <ThemeConsumer>
      {(theme) => (
        <MuiDrawer
          // swipeAreaWidth={100}
          z={z}
          hysteresis={0.2}
          disableBackdropTransition={true}
          disableDiscovery={props.disableDiscovery}
          anchor={side}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          overlay={showOverlay(theme) ? 1 : 0}
          elevation={0}
          // BOTH are needed for some reason?!?!
          swipeAreaWidth={disableDiscovery ? 0 : 45}
          SwipeAreaProps={{
            style: {
              zIndex: z - 1,
              // BOTH are needed for some reason?!?!
              width: disableDiscovery ? 0 : 45,
            },
          }}
          ModalProps={{
            disableEnforceFocus: true,
            disableBackdropClick: !showOverlay(theme),
            // hideBackdrop: !overlay,
            // BackdropProps: {
            //   open: overlay && isOpen,
            // }
          }}
          className={props.className}
        >
          {props.children}
        </MuiDrawer>
      )}
    </ThemeConsumer>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Drawer.propTypes = {
  /**
   * The app settings path to hold the Drawer open/close state
   */
  path: PropTypes.string.isRequired,
  /**
   * Which side of the screen should the drawer appear on?
   */
  side: PropTypes.string,
  /**
   * CSS z-index for the drawer
   */
  z: PropTypes.number,
  /**
   * Show sidebar with an overlay?
   */
  overlay: PropTypes.bool,
  /**
   * Show overlay when mobile/narrow viewport?
   */
  overlayWhenMobile: PropTypes.bool,
  /**
   * Should the discoverable swipe-to-top zone be disabled?
   */
  disableDiscovery: PropTypes.bool,
}

Drawer.defaultProps = {
  path: "show.drawer",
  side: "right",
  overlay: false,
  overlayWhenMobile: true,
  z: 1500,
  disableDiscovery: false,
}

export default Drawer
