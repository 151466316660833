import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import ExpandArrow from "./expand_arrow"
import SetSetting from "../../elements/set_setting"
import SetMenuItemOpen from "../../elements/set_menu_item_open"
import SetActive from "../../elements/set_active"
import BaseItem, {
  CardStyled,
  showOnCardHover,
  verticalCenter,
} from "../../elements/base_item"
import ToggleArrow from "../../elements/set_active_button"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import Name from "../../elements/name"
import Description from "../../elements/description"
// import Item from "./item"
import Item from "../../features/item/index"

import AddToList from "../../features/add_to_list"
// import List from "../../features/list"
import List from "./list"
import Parents from "./parents"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { setStatusID } from "../../../store/items/actions"
// import FilterListIcon from "@material-ui/icons/FilterList"
import Filter from "./filter"
import AddItem from "./add_item"
import { Button } from "../../elements"
import AccountTreeIcon from "@material-ui/icons/AccountTree"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  /* border: 1px red solid; */
  /* width: ${(props) => props.width}px; */
  /* display: flex; */
  /* position: relative; */
  /* z-index: 100; */
  /* scroll-snap-align: end; */
  /* flex-direction: column; */
  /* overflow: hidden; */
  /* flex-shrink: 0; */
  border-left: 1px solid ${(props) => props.theme.section.border};
  display: flex;
  flex-direction: column;
  height: 100%;
`

// const Title = css`
//   font-size: 12px;
//   font-weight: bold;
// `

// const Header = styled.div`
//   ${Title};
//   padding: 0px 10px 0 20px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   transition: all 0.3s;
//   border-right: 0px solid white;
//   ${props =>
//     props.isOpen &&
//     css`
//       border-right: 5px solid ${props => props.theme.element.item.active};
//     `}
// `

// const Back = styled(ExpandArrow)`
//   cursor: pointer;
//   transform: rotate(-90deg);
//   /* position: absolute;
//   left: 0px;
//   margin-top: 7px; */
//   /* margin-top: 7px; */
// `

const Options = styled(ArrowDropDownIcon)``

const OptionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 2px; */
  svg {
    margin: 0 2px;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  border-bottom: 1px solid ${(props) => props.theme.section.border};
`

// const SetSettingStyled = styled(SetSetting)`
//   flex-grow: 1;
//   align-self: stretch;
//   padding: 10px 0;
//   display: flex;
// `

// const NameStyled = styled(Name)`
//   ${Title};
//   flex-grow: 1;
//   display: flex;
//   align-items: center; /* align vertical */
//   line-height: 40px;
// `

// const ListStyled = styled(List)`
//   position: relative;
//   flex-grow: 1;
//   overflow: hidden;
//   overflow-y: auto;
//   &::-webkit-scrollbar {
//     display: none; /* Safari and Chrome */
//   }
//   /* overscroll-behavior: none; */
//   & > :first-child {
//     /* offset border stacking */
//     margin-top: -1px;
//     border-top: 1px solid ${props => props.theme.section.border};
//   }
// `

const AddToListStyled = styled(AddToList)`
  /* justify-self: flex-end; */
  position: absolute;
`

// ========================================= //
// Main Component
// ========================================= //

const Column = ({ id: latestID, width, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [id, setID] = useState(latestID)
  const id = latestID
  const [inTransition, setInTransition] = useState(false)
  const [isDeleted] = useItem([id, "isDeleted"])
  const [sortBy] = useItem([id, "sortBy"], false, { field: false })
  const sort = ({ items }) => {
    const { itemIDs = []} = items[id]
    const { field, direction } = sortBy
    const direct = (ids) => (direction === "DESC" ? _.reverse(ids) : ids)
    return field ? direct(_.sortBy(itemIDs, (id) => items[id][field])) : itemIDs
  }
  const [itemIDs] = useItem(false, sort, [])
  const [, { displayIntention }] = useApp("currentItemID")

  // const [selectedID] = useApp("views.writer.open")
  // const [selectedID] = useApp("currentItemID")
  // const [parentIDs] = useItem([selectedID, "parentIDs"])

  // ========================================= //
  // Effects
  // ========================================= //

  // if current column item becomes deleted, navigate up a level
  useEffect(() => {
    if (isDeleted) {
      displayIntention({ path: false, id, remove: true })
    }
  }, [isDeleted, id])

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className} width={width}>
      <Parents id={id} inTransition={inTransition} />
      <Item id={id} inTransition={inTransition} isHeader={true} width={width} />
      <OptionsBar>
        {/* <Options /> */}
        <Button.SetState
          name="Show Parents"
          // onClick={() => alert("coming soon")}
          path="show.parents"
          Icon={AccountTreeIcon}
          size="small"
        />
        <Filter id={id} />
        {/* <AddItem parentID={id} /> */}
        <AddItem
          listID={id}
          intentionID={id}
          container={Container}
          width={width}
        />
      </OptionsBar>
      <List
        listID={id}
        parentID={id}
        itemIDs={itemIDs}
        ItemComp={Item}
        width={width}
        isDragDisabled={!!sortBy.field}
        itemParams={{
          inTransition,
          isHeader: false, //(itemID) => false, //itemID === latestID,
          // isOpen: itemID => itemID === selectedID,
        }}
      />
      {/* <AddToListStyled listID={id} intentionID={id} container={Container} /> */}
    </Container>
  )
}

export default Column
