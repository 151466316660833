import _ from "lodash"
import updateItem from "./update_item"
import sessions from "./sessions"
import updateParents from "./utils/update_parents"
import removeFromItem from "./remove_from_item"

const deleteItem = (state, action) => {
  const { id, parentID, isDeleted = true } = action.payload
  const item = _.cloneDeep(state[id])
  // uncomment once sessions are working again
  //const item = isDeleted ? sessions.stop(state, action, true) : state[id]

  // fix for some previous bug.
  if (parentID) {
    if (!_.size(item.parentIDs)) item.parentIDs = [parentID]
    if (!_.includes(item.parentIDs, parentID)) item.parentIDs.push(parentID)
  }

  _.merge(item, {
    isDeleted: isDeleted,
    deletedAt: isDeleted ? Date.now() : false,
  })

  state = updateItem(state, { payload: { fields: item, overwrite: false } })
  _.map(item.parentIDs, parentID => {
    const parent = state[parentID]
    parent.deletedIDs = parent.deletedIDs || []
    if (isDeleted) {
      _.remove(parent.itemIDs, itemID => itemID === id)
      parent.deletedIDs.push(id)
    } else {
      _.remove(parent.deletedIDs, itemID => itemID === id)
      parent.itemIDs.push(id)
    }
    state = updateItem(state, {
      payload: { fields: parent, overwrite: true },
    })
  })

  _.map(item.itemIDs, itemID => {
    const child = state[itemID]
    if (_.size(child.parentIDs) > 1) {
      removeFromItem(state, { payload: { id: itemID, parentID: id } })
    } else {
      state = deleteItem(state, {
        payload: { id: itemID, parentID: id, isDeleted },
      })
    }
  })

  state = updateParents(state, item)
  return state
}

export default deleteItem
