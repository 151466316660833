import styled, { css } from "styled-components"
import { showOnCardHover, verticalCenter } from "./base_item"
import { Button } from "../../elements"

const hoverColor = css`
  @media (hover: hover) {
    &:hover {
      color: rgb(93, 149, 171);
      background: none;
    }
  }
`

export const ExpandButton = styled(Button.NavigateTo)`
  grid-area: arrow;
  height: 100%;
  ${verticalCenter};
  ${showOnCardHover};
  ${hoverColor}
  transition: all 0.3s;
  transform: rotate(90deg);
  &.active {
    opacity: 1 !important;
    transform: rotate(-90deg);
  }
`
