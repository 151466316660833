import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../store/items/selectors"
import { CustomFieldSearch } from "./custom_field"
import RepeatIcon from "@material-ui/icons/Repeat"
import ContentEditable from "react-sane-contenteditable"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  span {
    transition: all 0.3s;
    opacity: 0.4;
  }
  svg {
    transition: all 0.3s;
    opacity: 0;
    font-size: 15px;
    margin-left: 10px;
  }
  &:hover {
    span {
      opacity: 1;
    }
    svg {
      opacity: 1;
    }
  }
`

const Options = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
  ${props =>
    props.show &&
    css`
      max-height: 200px;
      border: 1px black solid;
      padding: 10px;
    `}
`

const Field = styled(ContentEditable)`
  outline: none;
  cursor: text;
  font-size: 15px;
  display: flex;
  &[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: #d2d2d2;
    font-weight: normal;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const ItemAction = ({ id, index }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [action, { removeAction, updateAction }] = useItem([
    id,
    "actions",
    index,
  ])
  const [showOptions, showOptionsSet] = useState(false)
  const [options, setOptions] = useState({ name: "butts" })

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    setOptions(_.merge({}, options, action[0].options))
  }, [action])

  // ========================================= //
  // Functions
  // ========================================= //

  const update = () => {
    updateAction({ id, index })
  }

  const handleRemove = () => {
    removeAction({ id, index })
  }

  const handleNameChange = (event, name) => {
    setOptions({ name })
  }

  const handleBlur = () => {
    if (!options.name) return
    updateAction({ id, index, options })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <Content>
        <span onClick={handleRemove}>X</span>Repeats Daily
        <RepeatIcon onClick={() => showOptionsSet(!showOptions)} />
        <Options show={showOptions}>
          Name:{" "}
          <Field
            tagName={"div"}
            placeholder={"...repeating name"}
            caretPosition="end"
            content={options.name}
            editable={true}
            maxLength={500}
            multiLine={false}
            onChange={handleNameChange}
            onBlur={handleBlur}
          />
        </Options>
      </Content>
    </Container>
  )
}

export default ItemAction
