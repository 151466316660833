import _ from "lodash"
import reCreateItem from "../re_create_item"
import archiveItem from "../archive_item"

const ineligible = item => {
  return item.isDeleted || item.isArchived
}

const isIntention = item => item.type === "intention"

const repeatChildren = (state, item, filterFn) => {
  _.map(item.itemIDs, (id, index) => {
    
    if (ineligible(state[id])) return
    
    if (isIntention(state[id])) {
      state = repeatChildren(state, state[id], filterFn)
    } else if (filterFn(state[id])) {
      state = reCreateItem(state, { payload: { id } })
      // archive item to avoid recreating again in future
      state = archiveItem(state, { payload: { id } })
    }
  })

  return state
}

export default repeatChildren
