import _ from "lodash"
import React from "react"
import { useUser } from "../../store/user/selectors"

// ========================================= //
// Main Component
// ========================================= //

const SignOut = ({ tagName = "div", className, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, { signOutUser }] = useUser(false)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleSignOut = () => {
    signOutUser()
  }

  // ========================================= //
  // Render
  // ========================================= //

  const CustomTag = `${tagName}`

  return (
    <CustomTag className={className} onClick={handleSignOut}>
      {children}
    </CustomTag>
  )
}

export default SignOut
