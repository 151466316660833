import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import { useItem } from "../../../../../../../store/items/selectors"
import { useApp } from "../../../../../../../store/app/selectors"
import { useDomains } from "../../../../../../../store/domains/selectors"
import { LinkOption, Bottom, Submit } from "./style"
import FieldSearch from "../../../../../../elements/custom_field/field_search"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"

// ========================================= //
// Styles
// ========================================= //

// ========================================= //
// Main Component
// ========================================= //

const Modal = ({ text, link = {}, isOpen, onSave, onRemove, onClose }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, { updateItem }] = useItem(false)
  const [domainID] = useApp("currentDomain.id")
  const [autoLinks, { updateDomain }] = useDomains([domainID, "autoLinks"])

  const autoLink = _.get(autoLinks, [text], false)
  const [auto, setAuto] = useState(!!autoLink)
  const [expand, setExpand] = useState(!!link.expand || false)
  const [alwaysExpand, setAlwaysExpand] = useState(
    !!_.get(autoLinks[text], "expand", false)
  )
  const [selectedID, setSelectedID] = useState(link.itemID)
  const submitEl = useRef(null)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (!text) onClose()
  }, [text])

  useEffect(() => {
    if (selectedID && submitEl.current) {
      submitEl.current.focusVisible()
    }
  }, [selectedID])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleSelect = (itemID) => {
    setSelectedID(itemID)
  }

  const handleSubmit = () => {
    handleSave()
  }

  const handleSave = () => {
    console.log('saving', auto, link);
    if (auto || autoLink && !auto) {
      // update selected item to include this action
      // then update Editor to load all actions?
      const linkInfo = {
        itemID: selectedID,
        auto,
        expand: alwaysExpand ? "name" : false,
      }

      updateDomain({
        id: domainID,
        autoLinks: { [text]: auto ? linkInfo : null },
      })

      updateItem({
        id: selectedID,
        linkedTerms: {
          [text]: auto ? linkInfo : null,
        },
      })
    } 

    onSave({itemID: selectedID, expand})
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <FieldSearch
        type={"select"}
        title="Link to which item?"
        link={{ id: selectedID }}
        isOpen={isOpen}
        onSave={handleSelect}
        onClose={onClose}
        setFocus={!selectedID}
      >
        <LinkOption>
          <FormControlLabel
            control={
              <Checkbox
                checked={expand}
                onChange={() => setExpand(!expand)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Use item's complete name for link?"
          />
          {auto && expand && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={alwaysExpand}
                  onChange={() => setAlwaysExpand(!alwaysExpand)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Always?"
            />
          )}
        </LinkOption>

        <LinkOption>
          <FormControlLabel
            control={
              <Checkbox
                checked={auto}
                onChange={() => setAuto(!auto)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={
              <>
                Always link <strong>{text}</strong> with this item?
              </>
            }
          />
        </LinkOption>

        {(selectedID || link.itemID) && (
          <Bottom>
            <Submit
              action={submitEl}
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
            >
              {link.itemID ? "Change" : "Create"} Link
            </Submit>
            {link.itemID && (
              <Submit variant="outlined" color="secondary" onClick={onRemove}>
                Remove
              </Submit>
            )}
          </Bottom>
        )}
      </FieldSearch>
    </>
  )
}

export default Modal
