import styled, { css } from "styled-components"
import Popper from "@material-ui/core/Popper"
import { motion } from "framer-motion"

export const MuiPopover = styled(Popper)`
  z-index: 100000;
`

export const MotionInner = styled(motion.div)`
  width: 300px;
  height: 200px;
  padding: 20px;
  background: #444444;
  color: white;
  .editor {
    color: white;
    height: 92%;
    overflow: hidden;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
