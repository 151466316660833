import _ from "lodash"
import { call, put, take, delay } from "redux-saga/effects"

const frequency = 1000 * 60

let running = false
function* timer(action) {
  // if (running) return console.log("NOOOO TIMER RUNNING")

  running = true
  while (true) {
    // console.log("=====Timer 60 Seconds=====")
    yield delay(frequency)
    yield put({ type: "MINUTE", time: Date.now() })
  }
}

export default timer
