import _ from "lodash"
import styled, { css, keyframes } from "styled-components"

const AnimateIn = keyframes`
  from {
    /* playing with position to get smoother fadeIns */
    position: relative;
    opacity: 0;
    left: -20px;
  }

  to {
    position: relative;
    left: 0;
    opacity: 1;
    margin-left: 0px;
  }
`

const AnimateOut = keyframes`
  from {
    display: inherit;
    /* playing with position to get smoother fadeIns */
    position: relative;
    opacity: 1;
    left: 0px;
  }

  to {

    position: relative;
    left: -200px;
    opacity: 0;
    margin-left: 0px;
  }
`

const fadeInFirstLoad = css`
  animation: ${AnimateIn} 0.3s linear;
`

const fadeOut = css`
  animation: ${AnimateIn} 0.3s linear;
`

const isHidden = css`
  max-height: 0px;
  border: none;
  opacity: 0;
  margin: 0;
`

const Card = styled.div`
  /* max-height: 150px; */
  /* width: ${(props) => props.width || "250"}px; */

  border: 1px solid ${(props) =>
    _.get(props, "theme.element.item.border", "hsla(224, 41%, 90%, 0.98)")};
  /* border: 1px solid hsla(0, 0%, 91%, 0.45); */
  border-radius: 5px;
  font-size: 12px;
  padding: 15px;
  background: white;
  transition: all 0.3s;
  opacity: 1;
  overflow: hidden;

  box-shadow: 0px 0px 3px 0px ${(props) =>
    _.get(props, "theme.element.item.shadow", "black")};
  /* box-shadow: 0px 1px 7px -3px hsla(0, 0%, 62%, 0.25); */
  margin: 0 0 5px 0;
  /* ${(props) => props.firstLoad && fadeInFirstLoad}; */
  ${(props) => props.remove && fadeOut}
  ${(props) => props.isHidden && isHidden}
  cursor: grab;
  @media (hover: hover) { 
    &:hover {
      /* box-shadow: 0px 0px 3px 0px #c1daf3; */
      box-shadow: 0px 0px 3px 0px ${(props) =>
        _.get(props, "theme.element.item.shadow", "black")};
    }
  }
  &:active {
    /* padding: 15px 15px; */
    background: white;
    /* box-shadow: 2px 0px 9px 0px #d0d5da; */
    box-shadow: 2px 0px 9px 0px ${(props) =>
      _.get(props, "theme.element.item.shadow", "black")};
  }
`

export default Card
