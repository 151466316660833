import _ from "lodash"
import React, { useRef, useState, useEffect } from "react"
import { useItem } from "../../../store/items/selectors"
import styled, { css } from "styled-components"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import DropDownMenu from "../drop_down_menu"

// ========================================= //
// Style
// ========================================= //

const OptionsArrow = styled(ArrowDropDownIcon)`
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  opacity: 0.3;
  margin-left: 0px;
  &:hover {
    opacity: 1;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldSetType = ({
  id,
  fieldID,
  children,
  isNew,
  saveOnChange = true,
  onChange,
  onDismiss,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [type, actions] = useItem([id, "fields", fieldID, "type"])

  const options = isNew || !saveOnChange
    ? [
        { id: "text", name: "Text" },
        { id: "date", name: "Date" },
        { id: "link", name: "Link value to an item's field" },
        {
          id: "select",
          name: "Select box of items as options",
        },
        // { id: "count", name: "Word count" },
      ]
    : [{ id: "delete", name: "Delete Field" }]

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleChange = ({ id: type }) => {
    const field = type === "delete" ? false : { id: fieldID, type }
    if (saveOnChange) {
      actions.setFields({
        publish: !isNew,
        id,
        fields: { [fieldID]: field },
      })
    }
    if (onChange) onChange(field)
  }

  const handleDismiss = () => {
    if (onDismiss) onDismiss()
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <DropDownMenu
      key={id}
      // new fields will open menu on load
      isOpen={isNew}
      options={options}
      onChange={handleChange}
      onDismiss={handleDismiss}
    >
      {children || <OptionsArrow />}
    </DropDownMenu>
  )
}

export default CustomFieldSetType
