import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ignoreClick } from "../../utils"
import { useApp } from "../../store/app/selectors"
import Overlay from "../elements/overlay"
import Name from "../elements/name"
import Description from "../elements/description"
import ItemFields from "../features/item_fields"
import ItemActions from "../features/item_actions"
import ParentList from "../features/parent_list"
import AddParentSearch from "../features/add_parent"
import AddChildSearch from "../features/add_child_search"
import AddToList from "../features/add_to_list"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"
import ViewColumnIcon from "@material-ui/icons/ViewColumn"
import ListForIntention from "../features/list_for_intention"
import StatusTagSelector from "../elements/status_tag_selector"
import Tooltip from "@material-ui/core/Tooltip"
import SetActive from "../elements/set_active"
import SetSetting from "../elements/set_setting"
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser"
import AccountTreeIcon from "@material-ui/icons/AccountTree"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 550px;
  min-height: 90vh;
  max-height: 90vh;
  display: flex;
  background: white;
  /* border: 1px solid #eaeaea;
  box-shadow: 0px 0px 3px 0px #dceeff; */
  border: 1px solid
    ${(props) =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -10 })};
  box-shadow: 0px 0px 28px 2px
    ${(props) =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -3 })};
  transition: all 0.3s;
  margin-top: 30px;
  opacity: 0;
  ${(props) => props.show && visible};
  ${(props) => props.fullScreen && fullScreen};
`
const visible = css`
  opacity: 1;
  margin-top: 0px;
`

const fullScreen = css`
  min-width: 100vw;
  min-height: 100vh;
`

const hidePanel = css`
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: none;
`

const Inner = styled.div`
  width: 100%;
  /* margin: 20px; */
  display: flex;
  align-items: stretch;
  /* justify-content: space-between; */
`

const Details = styled.div`
  margin: 20px;
  width: 550px;
  position: relative;
  flex-grow: ${(props) => (props.fullScreen ? 2 : 0)};
`

const NameStyled = styled(Name)`
  margin: 10px 180px 20px 0;
  width: 80%;
  line-height: 25px;
`

const StatusTagSelectorStyled = styled(StatusTagSelector)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  /* color: #c1cfde; */
  padding: 10px 50px;
  border: 1px solid
    ${(props) =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -10 })};
  & .MuiListItemText-primary {
    font-size: 15px;
  }
  &:hover {
    background: ${(props) =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -20 })};
    & .MuiListItemText-primary {
      color: white;
    }
  }
`

const Parents = styled.div`
  width: 275px;
  margin-left: 20px;
  border-right: 1px solid #d8e0ea;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  ${(props) => !props.visible && hidePanel};
`

const Children = styled.div`
  width: 275px;
  /* margin-left: 20px; */
  padding-left: 20px;
  border-left: 1px solid #d8e0ea;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  ${(props) => !props.visible && hidePanel};
`

const AddChildSearchStyled = styled(AddChildSearch)`
  margin: 20px 20px 10px 0;
`

const AddParentSearchStyled = styled(AddParentSearch)`
  margin: 20px 20px 10px 0;
`

const FullScreenToggle = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    color: rgb(93, 149, 171);
  }
`

const OpenFully = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    color: rgb(93, 149, 171);
  }
`

const SidebarToggle = styled.div`
  position: absolute;
  top: 0px;
  right: 30px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    color: rgb(93, 149, 171);
  }
`

const Stats = styled.div`
  cursor: pointer;
  font-size: 15px;
  &:hover {
    color: rgb(93, 149, 171);
  }
`

// ========================================= //
// Main Component
// ========================================= //

const MainItemMenu = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [{ id, parentID, isOpen, fullScreen }, actions] = useApp(["menuItem"])
  const [showChildren, setShowChildren] = useState(false)
  const [showParents, setShowParents] = useState(false)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClose = () => {
    actions.setMenuItem({ isOpen: false })
    setShowParents(false)
    setShowChildren(false)
  }

  const handleFullScreen = () => {
    actions.setMenuItem({ fullScreen: !fullScreen })
    setShowParents(!fullScreen ? true : false)
    setShowChildren(!fullScreen ? true : false)
  }

  const handleParents = () => {
    setShowParents(!showParents)
  }

  const handleChildren = () => {
    setShowChildren(!showChildren)
  }

  const handleParentsAndChildren = () => {
    handleParents()
    handleChildren()
  }

  // ========================================= //
  // Render
  // ========================================= //

  const FullScreenIcon = fullScreen ? FullscreenExitIcon : FullscreenIcon

  return (
    <Overlay visible={isOpen} onClose={handleClose}>
      <Container show={isOpen} onClick={ignoreClick} fullScreen={fullScreen}>
        {id && (
          <Inner>
            <Parents visible={showParents}>
              {showParents && (
                <>
                  <AddParentSearchStyled id={id} />
                  <ParentList id={id} />
                </>
              )}
            </Parents>
            <Details fullScreen={fullScreen}>
              <Tooltip title={"Open item fully"} enterDelay={300} interactive>
                <OpenFully>
                  <SetActive navToID={id} id={id}>
                    <SetSetting path={"currentItemID"} value={id}>
                      <OpenInBrowserIcon onClick={handleClose} />
                    </SetSetting>
                  </SetActive>
                </OpenFully>
              </Tooltip>
              {/* <Tooltip
                title={fullScreen ? "Collapse" : "Fullscreen"}
                enterDelay={300}
                interactive
              >
                <FullScreenToggle>
                  <FullScreenIcon onClick={handleFullScreen} />
                </FullScreenToggle>
              </Tooltip> */}
              <Tooltip
                title={
                  showChildren || showParents
                    ? "Hide parents & children"
                    : "Show parents & children"
                }
                enterDelay={300}
                interactive
              >
                <SidebarToggle onClick={handleParentsAndChildren}>
                  <AccountTreeIcon />
                </SidebarToggle>
              </Tooltip>

              {/* <StatusTagSelectorStyled id={id} /> */}

              <NameStyled
                key={id + "name"}
                id={id}
                listID="menu"
                tagName="h3"
                editable={true}
              />
              <Description
                key={id + "description"}
                id={id}
                listID="menu"
                editable={true}
              />

              <ItemFields id={id} />
              {/* <ItemActions id={id} /> */}
              {/* <Stats onClick={handleParents}>33 Parent Intentions</Stats>
              <Stats onClick={handleChildren}>12 Child Intentions</Stats> */}
            </Details>
            <Children visible={showChildren}>
              {showChildren && (
                <>
                  <AddChildSearchStyled id={id} />
                  <ListForIntention id={id} />
                  <AddToList
                    listID={id}
                    intentionID={id}
                    container={Children}
                  />
                </>
              )}
            </Children>
          </Inner>
        )}
      </Container>
    </Overlay>
  )
}

export default MainItemMenu
