import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import Card from "./card"

// ========================================= //
// Style
// ========================================= //

export const CardStyled = styled(Card)`
  /* transition: all 0.3s; */
  outline: none !important;
  color: ${(props) =>
    _.get(props, "theme.element.item.text", "hsla(0, 0%, 25%, 0.88)")};
  line-height: 18px;
  padding: 0;
  display: grid;
  grid-template-columns: 20px auto 20px 50px;
  grid-template-rows: 15px auto 15px min-content;
  grid-template-areas:
    ". . . arrow"
    ". name . arrow"
    ". . . arrow"
    "bottom bottom bottom bottom";
  &:active {
    outline: none;
  }
  ${(props) => props.expanded && expandedStyle}
`

export const showOnCardHover = css`
  transition: opacity 0.3s;
  @media (hover: hover) {
    opacity: 0;
    ${CardStyled}:hover & {
      opacity: 1;
    }
  }
`

export const verticalCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const expandedStyle = css`
  border: 1px solid
    ${(props) => _.get(props, "props.theme.element.item.border", "hsla(224, 41%, 90%, 0.98)")};
  margin-left: 10px;
`

// ========================================= //
// Main Component
// ========================================= //

const BaseItem = ({ cardOptions = {}, children, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [firstLoad, setFirstLoad] = useState(true)

  // ========================================= //
  // Effects
  // ========================================= //

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setFirstLoad(false)
  //   }, 500)
  //   return () => clearTimeout(timer)
  // }, [])

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <CardStyled className={className} {...cardOptions} firstLoad={firstLoad}>
      {children}
    </CardStyled>
  )
}

export default BaseItem
