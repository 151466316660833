import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import Overlay from "../elements/overlay"
import { ChromePicker } from "react-color"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 300px;
  padding: 10px;
  background: white;
`

const Option = styled.div`
  padding: 10px;
  background: white;
  cursor: pointer;
  ${props =>
    props.active &&
    css`
      background: grey;
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const ThemePicker = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [show, { setSetting }] = useApp(["showThemePicker"])
  const [theme] = useApp(["theme"])
  const [colors, setColors] = useState(_.get(theme, "colors", "#ffffff"))
  const [section, setSection] = useState("background")

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleClose = () => {
    setSetting({ showThemePicker: false })
  }

  const handleChange = color => {
    // setColors(color.hsl)
    setColors(_.merge({}, colors, { [section]: color.hsl }))
  }

  const handleChangeComplete = color => {
    // _.merge({}, colors, { [section]: color.hsl })
    setSetting({
      theme: { colors: _.merge({}, colors, { [section]: color.hsl }) },
    })
  }

  const optionButton = name => {
    return (
      <Option active={section === name} onClick={() => setSection(name)}>
        {_.capitalize(name)}
      </Option>
    )
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Overlay visible={show} onClose={handleClose} background={"#ffffff00"}>
      <Container onClick={handleClick}>
        <div onClick={handleClose}>[x]</div>
        <ChromePicker
          color={colors[section]}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
        />
        {optionButton("primary")}
        {optionButton("accent")}
        {optionButton("border")}
        {optionButton("background")}
      </Container>
    </Overlay>
  )
}

export default ThemePicker
