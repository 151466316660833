import _ from "lodash"
import React, { useState, createContext } from "react"
import PropTypes from "prop-types"
import Content from "./content"

export const PopoverContext = createContext({})

// ========================================= //
//  Main component
// ========================================= //

const Popover = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <span
      aria-owns={open ? "mouse-over-popover" : undefined}
      onMouseOver={handleOpen}
      onMouseOut={handleClose}
      style={open ? { position: 'relative', zIndex: 1500 } : {}}
    >
      <PopoverContext.Provider value={{ open, anchorEl, handleClose }}>
        {children}
      </PopoverContext.Provider>
    </span>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Popover.propTypes = {}

Popover.defaultProps = {}

Popover.Content = Content

export default Popover
