import _ from "lodash"
// import reorderByStatus from "./reorder_by_status"
import updateItem from "./update_item"
import { arrangeArray } from "../../../utils"

const reorderFields = (state, action) => {
  const { id, fieldID, position } = action.payload
  const item = state[id]
  const field = item.fields[fieldID]

  let fields
  fields = _.values(item.fields)
  fields = _.sortBy(fields, "index")
  fields = arrangeArray(fields, field, position)

  _.each(fields, ({ id }, index) => (item.fields[id].index = index))

  return updateItem(state, { payload: { fields: item, overwrite: true } })
}

export default reorderFields
