import _ from "lodash"
import updateStatus from "../update_status"

const updateParents = (state, item) => {      
  return _.reduce(
    item.parentIDs,
    (state, id) => updateStatus(state, { payload: { id } }),
    state
  )
}

export default updateParents
