import _ from "lodash"
import updateItem from "./update_item"
import sessions from "./sessions"
import updateParents from "./utils/update_parents"

const completeItem = (state, action) => {
  const { id, completed = true } = action.payload
  const item = completed ? sessions.stop(state, action, true) : state[id]  
  
  item.statusID = completed ? 'completed' : (item.statusID || 'active')

  _.merge(item, { completed, completedAt: completed ? Date.now() : false })

  state = updateItem(state, { payload: { fields: item, overwrite: true } })
  state = updateParents(state, item)
  return state
}

export default completeItem
