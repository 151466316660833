import _ from "lodash"
import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useItem } from "../../../store/items/selectors"
import ContentEditable from "react-sane-contenteditable"

// ========================================= //
// Style Components
// ========================================= //

const ContentEditableStyled = styled(ContentEditable)`
  &[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #d2d2d2;
  }
  width: 100%;
  outline: none;
  cursor: text;
`

// ========================================= //
// Main Component
// ========================================= //

/**
 * Display and make editable any item text field
 * */

const Field = ({ id, field, isCustomField, onSave, onBlur, ...props }) => {

  // ========================================= //
  // State
  // ========================================= //

  const path = isCustomField ? [id, "fields", field, "value"] : [id, field]
  const [value, actions] = useItem(id ? path : false)
  const [content, setContent] = useState(value || "")
  const inputRef = useRef(null)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    // value has changed externally
    if (value && value !== content) {
      if (inputRef) {
        // I've tried som many content editable plugins
        // and they have all had issues.
        // with this one, calling setContent will auto focus
        // and if there are more than one name fields on the screen
        // the other will take focus just for a store update...
        // so, when the store updates, set inner html manually
        // console.log('manually setting');

        inputRef.current.ref.innerHTML = value
      }
    }
  }, [value])

  // ========================================= //
  // Functions
  // ========================================= //

  const save = () => {
    // nothing has changed
    if (value === content) return
    if (onSave) onSave(content)
    isCustomField
      ? actions.setFields(_.set({ id }, ["fields", field], content))
      : actions.updateItem(_.set({ id }, [field], content))
    console.log("SAVED", id, field, content)
  }

  const handleBlur = (event) => {
    if (onBlur) onBlur(content)
    save()
  }

  const handleChange = (event, text) => {
    if (!text) return
    setContent(_.size(text) ? text : value)
  }

  const handlePaste = (text) => {
    handleChange(false, text)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <ContentEditableStyled
      ref={inputRef}
      caretPosition="end"
      content={content}
      onChange={handleChange}
      onPaste={handlePaste}
      onBlur={handleBlur}
      tagName={props.htmlTag}
      className={props.className}
      placeholder={props.placeholder}
      focus={props.hasFocus}
      editable={props.editable}
      maxLength={props.maxLength}
      multiLine={props.allowNewLine}
    />
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Field.propTypes = {
  /**
   * The ID of the item to display
   */
  id: PropTypes.string.isRequired,
  /**
   * Which item's field should be displayed?
   */
  field: PropTypes.string.isRequired,
  /**
   * Is the field from the custom field object?
   * */
  isCustomField: PropTypes.bool,
  /**
   * Optional HTML tag to render e.g. 'div', 'span', 'h1'...
   */
  htmlTag: PropTypes.string,
  /**
   * The empty state placeholder text for the field
   */
  placeholder: PropTypes.string,
  /**
   * Should the field be editable?
   */
  editable: PropTypes.bool,
  /**
   * Should "enter" create new line or save?
   */
  allowNewLine: PropTypes.bool,
  /**
   * Should the field be focused upon initial render?
   */
  hasFocus: PropTypes.bool,
  /**
   * The maximum length of the item's field value
   * */
  maxLength: PropTypes.number,
  /**
   * Optional save handler; If provided, it will override default saving behavior
   */
  onSave: PropTypes.func,
  /**
   * Optional handler for after field loses focus
   */
  onBlur: PropTypes.func,
}

Field.defaultProps = {
  field: "name",
  isCustomField: false,
  htmlTag: "div",
  placeholder: "...",
  editable: true,
  allowNewLine: false,
  hasFocus: false,
  maxLength: 500,
}

export default Field
