import React from "react"
import styled from "styled-components"
import { useApp } from "../../../store/app/selectors"
import Overlay from "../../elements/overlay"

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px black solid;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 50px;
`

// ========================================= //
// Main Component
// ========================================= //

const MultipleTabs = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [multipleTabs] = useApp(["multipleTabs"])

  // ========================================= //
  // Render
  // ========================================= //

  return multipleTabs ? (
    <Overlay visible={true}>
      <Container>
        <div>Ntntnl is currently open in another tab.</div>
      </Container>
    </Overlay>
  ) : (
    children
  )
}

export default MultipleTabs
