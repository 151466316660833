import _ from "lodash"
import updateDomain from "./update_domain"

export function generateID() {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

const createDomain = (state, action) => {
  const { name } = action.payload
  const id = generateID()
  const domain = { id, name, createdAt: Date.now() }

  action.payload = { fields: domain }

  return updateDomain(state, action)
}

export default createDomain
