import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { css, ThemeConsumer } from "styled-components"
import { ButtonStyle, InnerStyle, Separator } from "./style"
import SetState from "../set_state"
import NavigateTo from "../navigate_to"
import Tooltip from "@material-ui/core/Tooltip"
import MuiButton from "@material-ui/core/Button"
import { motion } from "framer-motion"

// ========================================= //
//  Main component
// ========================================= //

const Button = ({ Icon, name, sepLeft, sepRight, ...props }) => {
  // if (props.material) return <MuiButton variant="outlined">{name}</MuiButton>
  const content = (
    <ThemeConsumer>
      {(theme) => (
        <>
          {sepLeft && <Separator />}
          <Tooltip title={name} enterDelay={1000} enterNextDelay={500} leaveDelay={200}>
            <motion.div
              css={InnerStyle}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.5 }}
            >
              {Icon ? (
                <Icon />
              ) : props.material ? (
                <MuiButton
                  variant="outlined"
                  style={{ height: "100%", width: "100%" }}
                >
                  {name}
                </MuiButton>
              ) : (
                <>{name}</>
              )}
            </motion.div>
          </Tooltip>
          {sepRight && <Separator />}
        </>
      )}
    </ThemeConsumer>
  )
  return props.wrapped || props.material ? (
    <>{content}</>
  ) : (
    <motion.div
      size={props.size}
      css={ButtonStyle}
      onClick={props.onClick}
      className={props.className}
      whileHover={{ scale: 1.1 }}
    >
      {content}
    </motion.div>
  )
}

Button.SetState = ({ Icon, sepLeft, sepRight, ...props }) => {
  return (
    <SetState css={ButtonStyle} {...props}>
      <Button
        Icon={Icon}
        wrapped={true}
        {...{ sepLeft, sepRight }}
        {...props}
      />
    </SetState>
  )
}

Button.NavigateTo = ({ Icon, sepLeft, sepRight, ...props }) => {
  return (
    <NavigateTo css={ButtonStyle} {...props}>
      <Button
        Icon={Icon}
        wrapped={true}
        {...{ sepLeft, sepRight }}
        {...props}
      />
    </NavigateTo>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Button.propTypes = {
  /**
   * The button's name for meta/hover info
   */
  name: PropTypes.string.isRequired,
  /**
   * A material UI icon
   */
  Icon: PropTypes.elementType,
  /**
   * Small, medium, or large
   */
  size: PropTypes.string,
  /**
   * The redux app store setting path
   */
  setting: PropTypes.string,
  /**
   * The value will be toggled true/false unless a string is provided, in which case value/false will be toggled.
   */
  value: PropTypes.any,
  /**
   * Should the setting value be toggled? Bool (true/false) String (string/false)
   */
  toggle: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Use a material ui button?
   */
  material: PropTypes.bool,
}

Button.defaultProps = {
  size: "medium",
  toggle: true,
  material: false,
}

// ========================================= //
// Example props for View Builder
// ========================================= //

export const exampleProps = {
  name: "Click me",
  material: true,
}

// ========================================= //
// Default export
// ========================================= //

export default Button
