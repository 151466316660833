import _ from "lodash"
import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import SetSetting from "../../elements/set_setting"

import { useDomains } from "../../../store/domains/selectors"
import { useItem } from "../../../store/items/selectors"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import AddIcon from "@material-ui/icons/Add"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  font-size: 12px;
  display: flex;
  align-items: stretch;
  margin-right: 10px;
  text-align: center;
  svg {
    padding-right: 5px;
  }
  span {
    cursor: pointer;
    border-radius: 5px;
    color: ${props =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -40 })};
    border: 1px solid
      ${props =>
        props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -10 })};
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0 10px 0 2px;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const AddColumn = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [columnType] = useApp("views.board.columnType")

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className}>
      <SetSetting path={"views.board.showAddColumn"}>
        <span>
          <AddIcon />
          Item column
        </span>
      </SetSetting>
    </Container>
  )
}

export default AddColumn
