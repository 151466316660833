import _ from "lodash"
import React, { useContext } from "react"
import PropTypes from "prop-types"
import { LayoutContext } from "../layout_context"
import { generateID } from "../../../../utils"

// ========================================= //
//  Main component
// ========================================= //

const Droppable = ({ type, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const { setDroppingItem } = useContext(LayoutContext)

  // ========================================= //
  // Functions
  // ========================================= //

  const convertToGrid = ({ h, w }) => {
    return { h: _.round(h / 20), w: _.round(w / 40) }
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <div
      className="droppable-element"
      draggable={true}
      unselectable="on"
      onDragStart={(e) => {
        // this is a hack for firefox
        // Firefox requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        e.dataTransfer.setData("text/plain", "")

        const id = generateID(5)
        setDroppingItem({
          i: id,
          id,
          type,
          props: {},
          style: {},
          ...convertToGrid({
            h: e.target.offsetHeight,
            w: e.target.offsetWidth,
          }),
        })
      }}
    >
      {children}
    </div>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Droppable.propTypes = {}

Droppable.defaultProps = {}

export default Droppable
