import _ from "lodash"
import React from "react"
import styled from "styled-components"
import NavigationIcon from "@material-ui/icons/Navigation"
import SearchIcon from "@material-ui/icons/Search"
import MenuIcon from "@material-ui/icons/Menu"
import { Button } from "../../elements"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  position: fixed;
  top: 0px;
  padding: 5px 0 5px 0;
  left: 0;
  /* To get on top of swipeable div for Navigator Drawer */
  z-index: ${({ z }) => z};
  display: flex;
  background: white;
`

const Menu = styled(Button.SetState)`
  position: relative;
  /* Display on top of menu when open */
  z-index: 1100;
  svg {
    font-size: 20px;
  }
`

const Nav = styled(Button.SetState)`
  & svg {
    transition: all 0.3s;
    transform: rotate(180deg);
  }
  &.active svg {
    transform: rotate(0deg);
  }
`
const Search = styled(Button.SetState)``

// ========================================= //
// Main Component
// ========================================= //

const Top = ({ z, className }) => {
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container z={z}>
      <Menu name="Show/hide Menu" path="show.sidebar" Icon={MenuIcon} />
      <Nav
        name="Show/hide Navigation"
        Icon={NavigationIcon}
        path="show.navigator"
        size="small"
        sepLeft={true}
      />
      <Search
        name="Show/hide Search"
        Icon={SearchIcon}
        path="show.search"
        toggle={true}
        size="small"
        sepLeft={true}
        sepRight={true}
      />
    </Container>
  )
}

export default Top
