import _ from "lodash"
import React from "react"
import { useItem } from "../../../store/items/selectors"
import Column from "./column"
import ColumnNew from "./column_new"

// ========================================= //
// Main Component
// ========================================= //

const ColumnByItems = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [itemIDs] = useItem([id, "itemIDs"])

  // ========================================= //
  // Rendering
  // ========================================= //

  return (
    <>
      {_.map(itemIDs, (itemID, key) => (
        <ColumnByItem key={key} {...{ id: itemID, parentID: id, className }} />
      ))}
    </>
  )
}

// ========================================= //
// Supporting Component
// ========================================= //

const ColumnByItem = ({ id, parentID, className }) => {
  const [itemIDs] = useItem([id, "itemIDs"])

  return (
    <Column
      {...{ listID: id, itemIDs, parentID, newItemParentID: id, className }}
    />
  )
}

export default ColumnByItems
