import _ from "lodash"
import React, { useState } from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import FieldLabel from "./field_label"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"

// ========================================= //
// Styles
// ========================================= //

const GlobalStyle = createGlobalStyle`
  .MuiPickersDay-daySelected {
    background-color: ${props => props.theme.element.item.active};
    color: white !important;
    &:hover {
      background-color: ${props => props.theme.element.item.active};
      opacity: 0.8;
    };
  } 
  /* class="MuiButtonBase-root MuiIconButton-root MuiPickersDay-day MuiPickersDay-current" */
  .MuiPickersDay-current {
    color: ${props => props.theme.element.item.active};
  }
`

const Container = styled.div``
const Content = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 20px;
  }
  & .MuiInputBase-root {
    flex-direction: row-reverse;
    button {
      padding: 12px 12px 12px 0;
      &:hover {
        background: none;
        color: ${props => props.theme.element.item.active};
      }
    }
  }
  & .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
  & .MuiInputAdornment-root {
    margin-left: 0;
  }
  & .MuiInputBase-input {
    flex-direction: row-reverse;
    padding: 0;
    font-size: 15px;
  }
  & .MuiInput-underline:before {
    border: none;
  }
  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldDate = ({
  id,
  fieldID,
  showLabel,
  defaults,
  isNew,
  onBlur,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  fieldID = _.get(defaults, "fieldID", fieldID)
  const [fieldDate, actions] = useItem([id, "fields", fieldID, "value"])
  const date = _.get(defaults, "value", fieldDate)
  const [selectedDate, setSelectedDate] = useState(date || null)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleDateChange = date => {
    setSelectedDate(date)
    const field = _.has(defaults, "name") ? _.clone(defaults) : { type: "date" }
    field.value = date

    actions.setFields({
      id,
      fields: {
        [fieldID]: field,
      },
    })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <GlobalStyle />
      {showLabel && (
        <FieldLabel
          id={id}
          fieldID={fieldID}
          // isNew={isNew}
          onBlur={onBlur}
        />
      )}
      <Content>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id={`${id}-${fieldID}-date-picker`}
            value={selectedDate}
            onChange={handleDateChange}
            autoOk={true}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Content>
    </Container>
  )
}

export default CustomFieldDate
