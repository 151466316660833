const hasToken = async (auth, domain, repeat = true) => {
  // get auth tokens
  let tokenResults = await auth().currentUser.getIdTokenResult()
  // user has access, true
  if (tokenResults.claims[domain.id]) return true

  console.log("token missing for", domain.id, tokenResults.claims)
  // renew token cache
  auth().currentUser.getIdToken(true)
  // check again after renew
  return repeat ? hasToken(auth, domain, false) : false
}

const getSharedDomain = (db, auth, userCollection, userID, domain) => {
  return new Promise(async (resolve, reject) => {
    if (!userID) return console.log("missing userID")

    if (await hasToken(auth, domain)) {
      db.collection(userCollection)
        .doc(userID)
        .collection("domains")
        .doc(domain.id)
        .get()
        .then(snapshot => {
          const doc = snapshot.data()          
          domain = doc ? { ...domain, ...snapshot.data() } : false
          resolve(domain)
        })
        .catch(error => {
          console.log("Firebase error while loading:", error)
          resolve(false)
        })
    } else {
      resolve(false)
    }
  })
}

export default getSharedDomain
