import _ from "lodash"
import React, { useState } from "react"
import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"

// ========================================= //
// Style
// ========================================= //

const Inner = styled.div`

  transition: all 0.3s;
  transition-delay: ${(props) => props.delay}s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition-timing-function: ease-in-out;
`

const PlaceHolder = styled.div`
  min-width: 1px;
  min-height: 100px;
`

// ========================================= //
// Main Component
// ========================================= //

const LazyLoad = ({ children, index, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [visible, setVisible] = useState(false)
  const [delay, setDelay] = useState(0)
  const loadedAt = Date.now()

  // ========================================= //
  // Functions
  // ========================================= //

  const setFirstVisibility = (isVisible) => {
    if (visible || !isVisible) return
    const sinceLoad = Date.now() - loadedAt
    const offset = sinceLoad > 500 ? 0 : index
    setDelay(offset * 0.01)
    setVisible(true)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <VisibilitySensor onChange={setFirstVisibility} partialVisibility={true}>
      <Inner className={className} delay={delay} visible={visible}>
        {visible ? children : <PlaceHolder />}
      </Inner>
    </VisibilitySensor>
  )
}

export default LazyLoad
