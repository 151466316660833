import _ from "lodash"
import React from "react"
import FieldType from "./field_type"

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldNew = ({ id, onBlur }) => {
  // ========================================= //
  // State
  // ========================================= //

  const fieldID = Math.random()
  .toString(36)
  .substr(2, 9)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleDismiss = () => {
    if (onBlur) onBlur(fieldID)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <FieldType
      id={id}
      fieldID={fieldID}
      isNew={true}
      onDismiss={handleDismiss}
      onChange={handleDismiss}
    />
  )
}

export default CustomFieldNew
