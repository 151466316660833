import _ from "lodash"
import React from "react"
import styled from "styled-components"
import { useItemsByStatusID } from "../../../store/items/selectors"
import List from "../../features/list"
import AddToList from "../../features/add_to_list"
import SetActive from "../../elements/set_active"
import Item from './item'
// import Item from "../../features/item"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  width: 265px;
  margin-right: 20px;
  /* To be flush with edge */
  &:last-of-type {
    margin-right: 0px;
    width: 254px;
  }
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
`
const HR = styled.div`
  height: 1px;
  width: 252px;
  margin: 5px 0 20px 0;
  border-bottom: 1px #f7f7f7 solid;
`
const Name = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: ${props =>
    props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -30 })};
  width: 250px;
`

// ========================================= //
// Main Component
// ========================================= //

const Column = ({
  headerID,
  parentID,
  listID,
  newItemParentID,
  itemIDs,
  onDrop,
  isMain,
  showHeaderNav = true,
  params,
  className,
}) => {
  // ========================================= //
  // Rendering
  // ========================================= //

  return (
    <Container className={className}>
      <Item {...{ id: headerID || listID, parentID, listID, showNav: showHeaderNav, ...params }} />

      <HR />

      <List
        listID={listID}
        parentID={listID}
        itemIDs={itemIDs}
        isEmpty={!_.size(itemIDs)}
        onDrop={onDrop}
        droppableParams={params}
        ItemComp={Item}
      />
      <AddToList
        listID={listID}
        intentionID={newItemParentID}
        container={Container}
        fields={params}
      />
    </Container>
  )
}

export default Column
