import _ from "lodash"
import updateDomain from "./update_domain"

const shareDomain = (state, action) => {
  const { domain, email } = action.payload

  domain.invites = state[domain.id].invites || {}
  // don't invite the same email twice
  if (domain.invites[email]) return state

  domain.invites[email] = "sent"
  domain.sharing = true
  
  action.payload.fields = domain
  return updateDomain(state, action)
}

export default shareDomain
