import React, { useState, useMemo } from "react"
import styled, { css } from "styled-components"
import imageExtensions from "image-extensions"
import isUrl from "is-url"
import { Transforms } from "slate"
import {
  Slate,
  Editable,
  useEditor,
  useSelected,
  useFocused,
  withReact,
} from "slate-react"

// ========================================= //
//  Supporting functions
// ========================================= //

const isImageUrl = (url) => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split(".").pop()
  return imageExtensions.includes(ext)
}

export const insertImage = (editor, url) => {
  const text = { text: "" }
  const image = { type: "image", url, children: [text] }
  Transforms.insertNodes(editor, image)
}

// ========================================= //
//  Main plugin
// ========================================= //

const withImages = (editor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData("text/plain")
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split("/")

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

// ========================================= //
//  Element
// ========================================= //

export const ImageElements = (props) => {
  const { attributes, children, element } = props

  switch (element.type) {
    case "image":
      return <ImageElement {...props} />
    default:
      return false
  }
}

const ImageStyled = styled("img")`
  display: block;
  max-width: 100%;
  /* max-height: 20em; */
  box-shadow: ${(props) =>
    props.selected && props.focused ? "0 0 0 3px #B4D5FF" : "none"};
`

const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <ImageStyled {...{ selected, focused }} src={element.url} />
      </div>
      {children}
    </div>
  )
}

export default withImages
