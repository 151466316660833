import _ from "lodash"
import createItem from "./create_item"
import updateItem from "./update_item"
import {
  getTime,
  startOfYesterday,
  startOfTomorrow,
  addMinutes,
  getDay,
} from "date-fns"

const add = (state, action) => {
  const { id, type, options } = action.payload
  const item = state[id]
  item.actions = item.actions || []

  item.actions.push({
    type,
    options,
    conditions: { nextRunAt: getTime(startOfTomorrow()) },
    // conditions: { nextRunAt: getTime(startOfYesterday()) },
  })

  state = updateItem(state, { payload: { fields: item, overwrite: false } })
  return state
}

const update = (state, action) => {
  console.log("action update called")
  const { id, index, options } = action.payload

  const item = state[id]
  item.actions[index] = _.merge(item.actions[index], { options })  

  return updateItem(state, { payload: { fields: item, overwrite: true } })
}

const determineID = id => {
  // Avoid race conditions by keeping new item id deterministic
  const [base, lineage] = _.split(id, "-")
  const next = _.toNumber(lineage || 0) + 1
  return base + "-" + next
}

const reCreateItem = (state, item, changes) => {
  const nextID = determineID(item.id)

  // don't recreate more than once
  if (state[nextID]) return false

  const newItem = _.merge(_.cloneDeep(item), changes)
  newItem.id = nextID
  newItem.originalID = item.originalID || item.id

  return createItem(state, { payload: { fields: newItem } })
}

const run = (state, action) => {
  const { id, index } = action.payload

  console.log("RUNNING ACTION: ", state[id].name)

  const act = state[id].actions[index]
  act.conditions.nextRunAt = getTime(startOfTomorrow())
  // act.conditions.nextRunAt = getTime(addMinutes(Date.now(), 1))

  state = reCreateItem(state, state[id], { name: act.options.name })

  // remove the action since the new item will handle it from now on
  // state[id].actions.splice(index, 1)
  delete state[id].actions

  return updateItem(state, { payload: { fields: state[id], overwrite: true } })
}

const remove = (state, action) => {
  const { id, index } = action.payload

  if (!id || _.isUndefined(index)) return state

  const item = state[id]
  item.actions.splice(index, 1)

  return updateItem(state, { payload: { fields: item, overwrite: true } })
}

export default { add, update, run, remove }
