import LogRocket from "logrocket"
import { env } from "../../utils"

// ========================================= //
// Initialize the bug tracking library
// ========================================= //

if (!env.IS_DEVELOPMENT) {
  LogRocket.init("cxecbh/ntntnl-production", {
    dom: {
      textSanitizer: true,
      inputSanitizer: true,
    },
  })
} else {
  console.log("Development mode; no bug tracking")
}

// ========================================= //
// Create the middleware
// ========================================= //

const logRocketMiddleware = store => next => action => {
  if (!env.IS_DEVELOPMENT) {
    if (action.type === "SET_USER" && action.payload.authenticated) {
      const { id, email } = action.payload
      // assign current user to bug tracking session
      LogRocket.identify(id, {
        email,
        // Add your own custom user variables here, ie:
        environment: env.environment,
      })
    }
  }

  return next(action)
}

export default logRocketMiddleware
