import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

// ========================================= //
// Style
// ========================================= //

const Selector = styled.span`
  outline: none;
`

const MenuStyled = styled(Menu)`
  outline: none;
  & .MuiPaper-root {
    outline: none;

    margin-top: -20px;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const DropDownMenu = ({
  id,
  options,
  activeIndex,
  onChange,
  onDismiss,
  children,
  isOpen = false,
  useContextMenu,
  className,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [selectedIndex, setSelectedIndex] = useState(activeIndex)
  const el = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const activeItem = options[activeIndex]

  useEffect(() => {
    if (isOpen) setAnchorEl(el.current)
  }, [isOpen])

  // ========================================= //
  // Functions
  // ========================================= //

  const prevent = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleOpenClick = event => {
    prevent(event)
    setAnchorEl(el.current)
  }

  const handleMenuItemClick = (event, index) => {
    prevent(event)
    // setSelectedIndex(index)
    setAnchorEl(null)    
    if (onChange) onChange(options[index])
  }

  const handleClose = (event, reason) => {
    if (reason && onDismiss) onDismiss()
    prevent(event)
    setAnchorEl(null)
  }

  // ========================================= //
  // Render
  // ========================================= //
  
  return (
    <>
      {children ? (
        <Selector
          ref={el}
          className={className}
          onClick={!useContextMenu ? handleOpenClick : () => false}
          onContextMenu={useContextMenu ? handleOpenClick : () => false}

          // SHOULD THIS BE HERE?
          //onClose={handleClose}
        >
          {children}
        </Selector>
      ) : (
        <Selector ref={el} className={className} onClick={handleOpenClick}>
          {activeItem && (
            <List component="nav" aria-label="Device settings">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="status"
                onClick={handleOpenClick}
              >
                <ListItemText primary={activeItem.name} />
              </ListItem>
            </List>
          )}
        </Selector>
      )}
      <MenuStyled
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_.map(options, (option, index) => (
          <MenuItem
            key={option.id}
            disabled={index === activeIndex}
            selected={index === activeIndex}
            style={{ color: option.id === "delete" ? "#d86767" : "inherit" }}
            onClick={event => handleMenuItemClick(event, index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </MenuStyled>
    </>
  )
}

export default DropDownMenu
