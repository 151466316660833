import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../store/items/selectors"
import ItemAction from "../elements/item_action"
import EditableField from "../elements/editable_field"
import CustomField from "../elements/custom_field"
import AddIcon from "@material-ui/icons/Add"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  /* margin-top: 20px; */
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  border-top: 1px solid ${props => props.theme.section.border};
  /* border-bottom: 1px solid ${props => props.theme.section.border}; */
  svg {
    transition: all 0.3s;
    opacity: 0.3;
    cursor: pointer;
  }
  svg:hover {
    opacity: 1;
  }
`

const Inner = styled.div`
  /* padding: 10px 20px; */
`

const Field = styled.div`
  /* padding: 10px 0; */
  padding: 20px 20px;

  border-bottom: 1px #e4e4e4 solid;
  font-size: 11px;
  &:last-of-type {
    border-bottom: none
  }
`

const NewField = styled(Field)`
  transition: all 0.3s;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  ${props =>
    props.show &&
    css`
      opacity: 1;
      max-height: 100px;
    `};
`

const AddField = styled(Field)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: grey;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  transition: all 0.3s;
  opacity: 0.2;
  ${Container}:hover & {
    opacity: 1;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const ItemActions = ({ id }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [actions, { addAction }] = useItem([id, "actions"])
  const [showNew, setShowNew] = useState(false)
  const [newItem, setNewItem] = useState({})

  // ========================================= //
  // Functions
  // ========================================= //

  const handleRepeat = field => {
    setShowNew(false)
    addAction({ id, type: "repeat", options: { interval: "daily" } })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <Header>
        <span>Actions</span>
        <AddIcon onClick={() => setShowNew(!showNew)} />
      </Header>

      <Inner>
        {_.map(actions, (action, index) => (
          <Field key={index}>
            <ItemAction id={id} index={index} />
          </Field>
        ))}

        {showNew && <span onClick={handleRepeat}>Repeat daily?</span>}
      </Inner>
    </Container>
  )
}

export default ItemActions
