import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import SetMenuItemOpen from "../../elements/set_menu_item_open"
import SetSetting from "../../elements/set_setting"
import SetActive from "../../elements/set_active"
import BaseItem, {
  CardStyled,
  showOnCardHover,
  verticalCenter,
} from "../../elements/base_item"
import ToggleArrow from "../../elements/set_active_button"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import NavTo from "./nav_to"
import Name from "../../elements/name"

// ========================================= //
// Style
// ========================================= //

const BaseItemStyled = styled(BaseItem)``

const NameStyled = styled(Name)`
  grid-area: name;
  font-size: 12px;
`

const hoverColor = css`
  &:hover {
    color: rgb(93, 149, 171);
    background: none;
  }
`

const NavToStyled = styled(NavTo)`
  grid-area: arrow;
  height: 100%;
  ${verticalCenter};
  ${showOnCardHover};
  ${hoverColor}
  opacity: ${(props) => (props.expanded ? 1 : 0)};
`

const ActiveMark = styled.div`
  grid-area: arrow;
  transition: all 0.3s;
  ${(props) =>
    props.isOpen &&
    css`
      border-right: 5px solid ${(props) => props.theme.element.item.active};
      opacity: 1;
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const Item = ({ id, parentID, isOpen, showNav = true, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    // <SetSetting path={"views.writer.open"} value={id}>
    <SetMenuItemOpen id={id}>
      {/* <SetActive id={id} parentID={parentID} expanded={false}> */}
      <BaseItemStyled cardOptions={{}}>
        <NameStyled id={id} tagName="span" listID={"writer-list"} />
        {/* <ToggleArrowStyled id={id} parentID={parentID} expanded={false}> */}
        {showNav && (
          <NavToStyled id={id} propagate={false}>
            <KeyboardArrowRightIcon />
          </NavToStyled>
        )}
        {/* </ToggleArrowStyled> */}
        <ActiveMark isOpen={isOpen} />
      </BaseItemStyled>
      {/* </SetActive> */}
    </SetMenuItemOpen>
  )
}

export default Item
