import _ from "lodash"

const initialState = {
  authenticated: null,
  id: false,
  email: false,
}

const userReducer = (state = initialState, action) => {
  state = _.cloneDeep(state)

  switch (action.type) {
    case "SET_USER":
      return { ...state, ...action.payload }
    case "SIGN_OUT_USER":
      return { ..._.cloneDeep(initialState), authenticated: false }
    default:
      return state
  }
}

export default userReducer
