import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import FlexSearch from "flexsearch"
import { Icons } from "./all_icons"
import synonyms from "./synonyms"
import Icon from "./icon"

// NOT FINISHED YET
// see MUI icon search here:
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/material-icons/SearchIcons.js

// ========================================= //
// Search
// ========================================= //

const searchIndex = FlexSearch.create({
  async: true,
  tokenize: "full",
})

// _.map(Icons, (icon, key) => {
//   searchIndex.add(key, searchable)
// })

// ========================================= //
//  Main component
// ========================================= //

const IconSelector = ({ defaultName, iconProps }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [name, setName] = useState(defaultName)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return <Icon name={name} {...iconProps} />
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

IconSelector.propTypes = {
  /**
   * A default icon name to show on initial display
   */
  defaultName: PropTypes.string,
  /**
   * See Icon component for available params
   */
  iconProps: PropTypes.object,
}

IconSelector.defaultProps = {}

export default IconSelector
