import _ from "lodash"
import updateItem from "./update_item"

const archiveItem = (state, action) => {
  const { id } = action.payload
  const item = _.merge(state[id], { archived: true, archivedAt: Date.now() })

  return updateItem(state, { payload: { fields: item, overwrite: true } })
}

export default archiveItem
