import styled, { css, keyframes } from "styled-components"
import { Responsive } from "react-grid-layout"
import autosizeHOC from "../autosize"
const ResponsiveGridLayout = autosizeHOC(Responsive)

export const RGL = styled(ResponsiveGridLayout)`
  display: flex;
  & .react-grid-item {
    display: flex;
    transition: none;
    ${({ isDraggable }) =>
      isDraggable &&
      css`
        transition: transform 0.3s ease;
      `}
  }
  .editing & .react-grid-item.react-grid-placeholder {
    color: blue;
  }
  ${(props) =>
    props.isDraggable &&
    css`
      z-index: 100;
      background: white;
      & .react-grid-item {
        border: 1px #bfbfbf dashed;
        &:hover ${Options} {
          opacity: 1;
        }
      }
    `}
`

export const Inner = styled.div`
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: none;
  overflow-y: auto;
  width: 100%;
  /* max-width: 960px; */
  transition: all 0.3s;
  margin: 0px 0 0 0;
  /* border: 1px blue solid; */
  & .layout {
    /* border: 2px red solid; */
    height: 100%;
  }
`

export const Box = styled.div`
  box-sizing: border-box;
  height: ${(props) => props.height || "inherit"};
  width: ${(props) => props.width || "inherit"};
  border: ${(props) => props.border || "none"};
  padding: ${(props) => props.padding || 0}px;
`

export const SetField = styled.div`
  border: 1px #d4d4d4 solid;
  border-radius: 5px;
  margin: 0 auto;
  padding: 3px 10px;
  font-size: 12px;
  display: inline-block;
  align-self: center;
  color: #8c8c8c;
  cursor: pointer;
`

export const Options = styled.div`
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0;
  transition: all 0.3s;
  opacity: 0;
  svg {
    margin: 3px;
    cursor: pointer;
    font-size: 1em;
    transition: all 0.3s;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`