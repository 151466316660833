import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"
import { useItem, useItemsPath } from "../../store/items/selectors"
import SearchItems from "../elements/search_items"
import FieldSearch from "../elements/custom_field/field_search"
import SearchIcon from "@material-ui/icons/Search"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  opacity: 0.5;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  svg {
    margin-right: 10px;
  }
  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

const SearchItemsStyled = styled(SearchItems)`
  margin: 0 auto;
  width: 300px;
  label {
    width: 100%;
    font-size: 14px;
    font-weight: 100;
    margin-top: 8px;
    transition: all 0.3s;
    transform: none;
    opacity: 1;
    &.Mui-focused,
    &.MuiFormLabel-filled {
      transform: none;
      margin-left: 5%;
      opacity: 0;
    }
  }
  .MuiInputBase-root {
    padding: 0;
  }
  fieldset {
    border: none;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const MainSearch = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [hasFocus, { setMenuItem, setSetting }] = useApp("show.searchFocus")
  const [activeID, { displayIntention }] = useApp(["activeIntentions"], _.last)
  const [selectedID, setSelectedID] = useState(false)
  const [path] = useItemsPath(selectedID || false)
  const [, { createItem }] = useItem(false)
  const lastID = useRef(null)
  const [createItemName, setCreateItemName] = useState(false)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (lastID.current !== selectedID && _.size(path)) {
      lastID.current = selectedID
      displayIntention({ path })
    }
  }, [path, displayIntention, selectedID, lastID])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleSelect = (item) => {
    const { id } = item
    if (id === "create") return handleCreate(item.value)
    setTimeout(() => {
      setSelectedID(id)
      setSetting({ currentItemID: id })
      handleBlur()
    }, 0)
    // setMenuItem({ id, isOpen: true })
  }

  const handleCreate = (name) => {
    if (!name.length) return

    // show item search to get a parent
    setCreateItemName(name)
  }

  const handleParentSelect = (parentID) => {
    if (parentID && createItemName) {
      const response = createItem({ name: createItemName, parentID })
      const changes = _.get(response, "changes.items")

      if (changes) {
        const newID = _.first(_.keys(changes))
        setSetting({ currentItemID: newID })
      }
    }

    setCreateItemName(false)
    handleBlur()
  }

  const handleBlur = () => {
    if (createItemName) return
    setSetting({ show: { search: false, searchFocus: false } })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <Container className={className}>
        <SearchItemsStyled
          setFocus={hasFocus}
          onBlur={handleBlur}
          onChange={handleSelect}
          label={
            <Label>
              <SearchIcon /> <span>search or create</span>
            </Label>
          }
        />
        {createItemName && (
          <FieldSearch
            type={"select"}
            title="Select a location"
            // link={{ id: activeID }}
            isOpen={!!createItemName}
            onSave={handleParentSelect}
            onClose={() => setCreateItemName(false)}
            setFocus={true}
            canCreate={false}
          ></FieldSearch>
        )}
      </Container>
    </>
  )
}

export default MainSearch
