import _ from "lodash"
import linkItem from "../link_item"

const jsonParse = (json, elseReturnThis) => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return elseReturnThis
  }
}

const parse = (content) => {
  if (_.isString(content)) return jsonParse(content, [])
  if (_.isObject(content)) return content
  return []
}

const getSlateLinks = (content) => {
  // format has changed from previous version
  content = parse(content)
  
  return _.map(content, ({ children }) =>
    _.map(children, (node) => _.get(node, "link.itemID"))
  )
}

const getAllLinks = (item) => {
  if (!item) return []

  // links set by the Slatejs editor
  const descriptionLinks = getSlateLinks(item.description)

  // check custom fields for links to same item
  const fieldLinks = _.map(item.fields, (field) => _.get(field, "value.id"))

  // if other forms of linking become available add them here

  // combine all links
  const links = _.uniq(_.compact(_.flattenDeep([descriptionLinks, fieldLinks])))

  return links
}

const modifyLinks = (state, id, links, remove = false) => {
  _.each(
    links,
    (linkID) => (state = linkItem(state, { payload: { id, linkID, remove } }))
  )
  return state
}

const isDeleted = (prev, item) => !_.get(prev, "isDeleted") && item.isDeleted
const isRestored = (prev, item) => _.get(prev, "isDeleted") && !item.isDeleted

const manageLinks = (state, itemPrev, item) => {
  const { id } = item

  // get all items linked previously
  const previous = getAllLinks(itemPrev)

  // get all items linked after update
  const current = getAllLinks(item)

  // when deleted, remove all links to other items
  if (isDeleted(itemPrev, item)) {
    return modifyLinks(state, id, current, true)
  }
  // when restored, add all links back to other items
  if (isRestored(itemPrev, item)) return modifyLinks(state, id, current)
  // no change
  if (_.isEqual(previous, current)) return state

  // get differences
  const add = _.difference(current, previous)
  const remove = _.difference(previous, current)

  // add new links
  state = modifyLinks(state, id, add)
  // remove old links
  state = modifyLinks(state, id, remove, true)

  return state
}

export default manageLinks
