import _ from "lodash"
import updateStatus from "./update_status"
import updateItem from './update_item'

const removeFromItem = (state, action) => {
  const { id, parentID } = action.payload
  const child = state[id]
  const parent = state[parentID]

  _.remove(parent.itemIDs, (ID) => ID === id)  
  _.remove(child.parentIDs, (ID) => ID === parentID)  

  if (child.parentID === parentID) child.parentID = false

  state = updateItem(state, { payload: { fields: parent, overwrite: true } })
  state = updateStatus(state, { payload: { id: parentID } })
  state = updateItem(state, { payload: { fields: child, overwrite: true } })

  return state
}

export default removeFromItem
