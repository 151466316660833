import _ from "lodash"
import React, { useEffect } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"
import MainSearch from "../features/main_search"

// ========================================= //
// Style
// ========================================= //

const MainSearchStyled = styled(MainSearch)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: ${(props) => (props.show ? 10 : -40)}px;
  z-index: 3000;
  width: 300px;
  margin-bottom: 15px;
  padding: 0 10px;
  align-self: center;
  border: 1px #e8e8e8 solid;
  border-radius: 5px;
  background: white;
  opacity: 0.95;
`

// ========================================= //
// Main Component
// ========================================= //

const View = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [showSearch, actions] = useApp("show.search")

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (showSearch) {
      const timer = setTimeout(() => {
        actions.setSetting({ show: { searchFocus: true } })
      }, 200)
      return () => clearTimeout(timer)
    }
  }, [showSearch])

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return <MainSearchStyled show={showSearch} />
}

export default View
