import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useDomains } from "../../../store/domains/selectors"
import { useItem } from "../../../store/items/selectors"
import DropDownMenu from "../../elements/drop_down_menu"
import { Button } from "../../elements"
import TuneIcon from "@material-ui/icons/Tune"
import Panel from "./panel"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
`

const DropDownMenuStyled = styled(DropDownMenu)`
  width: 100%;
`

// ========================================= //
// Main Component
// ========================================= //

const ViewSelect = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [viewID, actions] = useItem([id, "view", "id"])
  console.log("viewID", viewID)
  const [showSettings, set] = useApp("view.philpapers")
  const options = [
    { id: "builder", name: "Create New" },
    { id: "writer", name: "Writer" },
    { id: "kanban", name: "Kanban board" },
    { id: "table", name: "Table" },
    { id: "journal", name: "Journal" },
    { id: "vocab", name: "Vocab" },
    { id: "philpapers", name: "Philosophy" },
    { id: "dashboard", name: "Dashboard" },

    // { id: "nested", name: "Nested items" },
  ]
  const [currentDomain] = useApp("currentDomain")
  const [views, { updateDomain }] = useDomains([currentDomain.id, "views"])
  _.map(
    views,
    (view) =>
      !_.find(options, { id: view.id }) &&
      options.push(_.pick(view, ["id", "name"]))
  )

  const currentView = _.findIndex(options, { id: viewID })
  // default to Writer view if none set
  const activeIndex = currentView === -1 ? 1 : currentView

  // ========================================= //
  // Functions
  // ========================================= //

  const onChange = (view) => {
    if (view.id === "builder") return onCreate(view)
    actions.updateItem({ id, view })
  }

  const onCreate = (view, nameTaken = false) => {
    const name = prompt(
      `${nameTaken && "That name is already in use."} Your new view's name?`
    )
    if (name) {
      const viewID = _.camelCase(name)
      view = { id: viewID, name }
      if (_.find(options, { id: viewID })) {
        onCreate(view, true)
      }

      updateDomain({
        id: currentDomain.id,
        views: { [view.id]: view },
      })

      actions.updateItem({ id, view: _.pick(view, ["id"]) })
    }
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Panel title="View">
      <Container>
        <DropDownMenuStyled {...{ options, activeIndex, onChange }} />
        <Button.SetState
          Icon={TuneIcon}
          name="Show settings"
          path="show.viewSettings"
          size="medium"
          toggle
        />
      </Container>
    </Panel>
  )
}

export default ViewSelect
