import _ from "lodash"
import React, { memo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItemsPath } from "../../../store/items/selectors"

// ========================================= //
// Main Component
// ========================================= //

const NavigateTo = ({
  itemID,
  parentID,
  reset,
  tagName,
  onClick,
  ...props
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [path] = useItemsPath(itemID)
  const findInDisplayed = (intentions, state) => _.includes(intentions, itemID)
  const [isDisplayed, { displayIntention }] = useApp(
    ["activeIntentions"],
    findInDisplayed
  )
  const isActive = (intentions, state) => _.last(intentions) === itemID
  const [active] = useApp(["activeIntentions"], isActive)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    displayIntention({
      path: _.size(path) ? path : false,
      id: itemID,
      parentID,
      remove: isDisplayed,
      reset,
    })
    if (onClick) onClick()
  }

  // ========================================= //
  // Render
  // ========================================= //

  const Tag = `${tagName}`

  return (
    <Tag
      {...props}
      onClick={handleClick}
      className={[props.className, active ? "active" : ""].join(" ")}
    >
      {props.children}
    </Tag>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

NavigateTo.propTypes = {
  /**
   * The id of the item to set active in the navigator
   */
  itemID: PropTypes.string.isRequired,
  reset: PropTypes.bool,
  /**
   * The value to set onClick
   */
  value: PropTypes.any,
  /**
   * The html element type used for container
   */
  tagName: PropTypes.string,
  /**
   * A styled-component css template literal
   */
  css: PropTypes.any,
}

NavigateTo.defaultProps = {
  tagName: "div",
  value: true,
  reset: false,
}

export default memo(NavigateTo)
