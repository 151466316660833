import _ from "lodash"
import React, { useEffect } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"
import Name from "../elements/name"
import ListForIntention from "./list_for_intention"
import AddToList from "./add_to_list"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.show ? "265" : 0)}px;
  margin-right: ${props => (props.show ? "15" : 0)}px;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`
const NameStyled = styled(Name)`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -30})};
  width: 250px;
`

// ========================================= //
// Main Component
// ========================================= //

const ActiveIntentionBlock = ({ index, className, gridArea }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [intentionID, { displayIntention }] = useApp(
    ["activeIntentions"],
    ids => ids.slice(-50)[index - 1]
  )
  const listID = "activeIntentionCard-" + intentionID

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (index === 1 && !intentionID) {
      displayIntention({ id: "intentions", index: 0 })
    }
  }, [intentionID])

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container
      className={className}
      key={"block" + index}
      show={intentionID}
      gridArea={gridArea}
    >
      {intentionID ? (
        <>
          <NameStyled
            key={intentionID}
            id={intentionID}
            tagName="h3"
            editable={true}
          />
          <ListForIntention
            id={intentionID}
            listID={listID}
            container={Container}
          />
          <AddToList
            key={listID}
            listID={listID}
            intentionID={intentionID}
            container={Container}
          />
        </>
      ) : null}
    </Container>
  )
}

export default ActiveIntentionBlock
