import _ from "lodash"
import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useDomains } from "../../../store/domains/selectors"
import { useItem } from "../../../store/items/selectors"
import SetSetting from "../../elements/set_setting"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { Separator } from "../../elements/button/style"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  margin-right: 0px;
  font-size: 12px;
  span {
    cursor: pointer;
    /* border-radius: 5px; */
    color: ${(props) =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -40 })};
    /* border-bottom: 1px solid ${(props) => props.theme.section.border}; */
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 5px;
    & ${Separator} {
      margin-left: 22px;
    }
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .MuiMenuItem-root,
  .MuiListItem-gutters {
    padding: 0;
  }
`

const SetSettingStyled = styled(SetSetting)`
  flex-grow: 1;
  padding: 0px 0px;
`

// ========================================= //
// Main Component
// ========================================= //

const StatusSettings = ({ className, domainID, intentionID }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [columnType, actions] = useApp("views.board.columnType")
  const [sourceID] = useApp("views.board.sourceID")
  const [customFieldName] = useItem([sourceID, "name"])
  const [open, setOpen] = useState(false)
  const itemRef = useRef(null)

  // ========================================= //
  // Functions
  // ========================================= //

  const showMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(true)
  }

  // const handleTypeChange = type => {
  //   console.log(type)
  //   actions.StatusSettings()
  // }

  const handleClose = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(false)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className} onContextMenu={showMenu} ref={itemRef}>
      <span onClick={showMenu}>
        <ArrowDropDownIcon />
        {columnType === "field"
          ? _.capitalize(customFieldName)
          : _.capitalize(columnType)}
        <Separator />
      </span>

      <Menu
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={itemRef.current}
        onClick={handleClose}
      >
        <MenuItem>
          <SetSettingStyled path={"views.board.columnType"} value={"items"}>
            Item's Children
          </SetSettingStyled>
        </MenuItem>
        <MenuItem>
          <SetSettingStyled path={"views.board.columnType"} value={"status"}>
            <SetSetting path={"views.board.sourceID"} value={"statuses"}>
              Status
            </SetSetting>
          </SetSettingStyled>
        </MenuItem>
        <MenuItem>
          <SetSettingStyled path={"views.board.columnType"} value={"field"}>
            <SetSetting path={"views.board.sourceID"} value={false}>
              Custom Field
            </SetSetting>
          </SetSettingStyled>
        </MenuItem>

        {/* <MenuItem onClick={handleClose}>Default</MenuItem>
        <MenuItem onClick={handleClose}>New Status</MenuItem>
        <MenuItem onClick={handleClose}>New Set</MenuItem>
        <MenuItem onClick={handleClose}>Manage</MenuItem> */}
      </Menu>
    </Container>
  )
}

export default StatusSettings
