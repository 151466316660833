import _ from "lodash"
import { Editor, Transforms, Node, Text } from "slate"
import BoldIcon from "@material-ui/icons/FormatBold"
import ItalicIcon from "@material-ui/icons/FormatItalic"
import UnderlinedIcon from "@material-ui/icons/FormatUnderlined"

// ========================================= //
//  Plugin
// ========================================= //

const withFormats = (editor) => {
  //
  const type = "formats"
  const FORMATS = { b: "bold", i: "italic", u: "underlined" }

  const { extended } = editor

  // get current editor functions
  const {
    insertText,
    extended: { onCommand, onRightArrow },
  } = editor

  // ========================================= //
  // State
  // ========================================= //

  let nextFormat
  let lastSelection

  // ========================================= //
  // Register toolbar actions
  // ========================================= //

  editor.plugins.toolbar.bold = { format: "bold", Icon: BoldIcon }
  editor.plugins.toolbar.italic = { format: "italic", Icon: ItalicIcon }
  editor.plugins.toolbar.underlined = {
    format: "underlined",
    Icon: UnderlinedIcon,
  }

  // ========================================= //
  // Extend existing editor functions
  // ========================================= //

  editor.insertText = (character) => {
    const changeFormat =
      nextFormat && _.isEqual(lastSelection, editor.selection)
    lastSelection = false
    // call default editor function
    insertText(character)

    if (changeFormat) {
      const state = editor.extended.format.has(nextFormat) ? null : true
      const location = _.cloneDeep(editor.selection)
      // offset location to include character JUST added
      location.anchor.offset -= 1
      editor.extended.format.set({ [nextFormat]: state }, location)
      nextFormat = false
    }
  }

  editor.extended.onCommand = (event) => {
    const format = FORMATS[event.key]
    if (format) {
      if (editor.extended.noSelection()) {
        nextFormat = format
        lastSelection = _.cloneDeep(editor.selection)
      } else editor.extended.format.toggle(format)
    }

    // call default function
    onCommand(event)
  }

  editor.extended.onRightArrow = () => {
    // if (__atEndOfLeaf()) __breakOut()
    // call default function
    onRightArrow()
  }

  // ========================================= //
  // Add editor functions
  // ========================================= //

  editor.extended.format = {}

  editor.extended.format.getAll = () => {
    try {
      // this throws an error on documents will bullet lists...WHY?!?!
      return Editor.marks(editor) || {}
    } catch (e) {
      return {}
    }
  }

  editor.extended.format.get = (field) => {
    return editor.extended.format.getAll()[field]
  }

  editor.extended.format.has = (format = "") => {
    return !!editor.extended.format.getAll()[format]
  }

  editor.extended.format.hasAny = () => {
    return _.size(_.keys(editor.extended.format.getAll()))
  }

  editor.extended.format.toggle = (format) => {
    // if (!format || !editor.plugins.toolbar[format]) return
    if (!format) return
    editor.extended.format.set({
      [format]: editor.extended.format.has(format) ? null : true,
    })
  }

  editor.extended.format.set = (format, location) => {
    Transforms.setNodes(editor, format, {
      at: location || editor.selection,
      match: (n) => Text.isText(n),
      split: true,
    })
  }

  // ========================================= //
  //  Supporting functions
  // ========================================= //

  const __atEndOfLeaf = () => {
    const parent = Node.parent(editor, editor.selection.anchor.path)
    // const last = Node.last(editor, parent)
    console.log("parent", parent)
    // console.log('last', last);

    return editor.extended.format.hasAny()
  }

  const __breakOut = () => {
    console.log("should break out!")
    // LOGIC:
    // if
    // if selection has path of last leaf
    // and leaf has marks (bold/link)
    // create trailing text leaf
    // move cursor to leaf
  }

  // ========================================= //
  // Return extended editor
  // ========================================= //

  return editor
}

export default withFormats
