import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import { useItem } from "../../store/items/selectors"
import { Container } from "react-smooth-dnd"

// ========================================= //
// Styles
// ========================================= //

const Inner = styled.div`
  & .drop-preview {
    border: 2px #b9cade dotted;
    background: #f2f5f9;
    max-width: 220px;
    margin: 10px;
  }
  & .dropClass {
    border: 1px red solid;
    display: none;
  }
  /* the item being dragged */
  & .card-ghost {
  }
`

// ========================================= //
// Main Component
// ========================================= //

const DragDropContainer = ({
  items = [],
  listID,
  parentID,
  isDraggable,
  onDrop,
  droppableParams = {},
  children,
  className,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [activeIntentions, { setPlanningBoard }] = useApp(["activeIntentions"])
  const [, actions] = useItem(false)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleDrop = event => {
    const { removedIndex, addedIndex, payload, element } = event
    const position = event.addedIndex
    const field = _.get(droppableParams, "field", false)
    const item = payload
    if (_.isNull(position)) return
    onDrop
      ? onDrop({ item, position })
      : moveItem({ item, position, field, listID })
  }

  const moveItem = ({ item, position, field, listID }) => {
    const { id } = item
    console.log("moveITEM")

    if (listID === "fields") {
      // return console.log('COOL', {id: parentID, fieldID: id, position});
      return actions.reorderFields({ id: parentID, fieldID: id, position })
    }

    if (_.includes(items, id)) {
      console.log("reordering item", position)
      // actions.reorder({ id, parentID, statusID, position })
      actions.reorder({ id, parentID, position, field })
    } else if (field && item.parentID === parentID) {
      console.log("same parent, different fieldID")
      actions.setFields({
        id,
        fields: {
          [field.sourceID]: {
            type: "select",
            source: {
              id: field.sourceID,
              path: "itemIDs",
            },
            value: {
              id: field.id,
              path: "name",
            },
          },
        },
      })
      
      actions.reorder({ id, parentID, position, field })

      //actions.setStatusID({ id, statusID, parentID, position })
      // }
      //  else if (listID === "planningBoard" && parentID !== item.parentID) {
      //   console.log("planning board: add to item")
      //   if (parentID) {
      //     console.log("\t addingTo item in board")
      //     actions.addItemToParent({ id, parentID, position })
      //   } else {
      //     console.log("\t setting board", id)
      //     setPlanningBoard({ id })
      //   }
    } else {
      console.log("MOVE from:", item.parentID, "to:", parentID)

      // return
      actions.moveItem({
        id,
        oldParentID: item.parentID,
        newParentID: parentID,
        position,
        // statusID,
      })
    }
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container
      groupName="col"
      getChildPayload={index => ({ id: items[index], parentID })}
      dragClass="card-ghost"
      dropClass="card-ghost-drop"
      // onDragStart={e => console.log("drag started", e)}
      // onDragEnd={e => console.log("drag end", e)}
      // onDragEnter={() => {
      //   console.log("drag enter:")
      // }}
      // onDragLeave={() => {
      //   console.log("drag leave:")
      // }}
      // onDropReady={p => console.log("Drop ready: ", p)}
      onDrop={handleDrop}
      dropPlaceholder={{
        animationDuration: 300,
        showOnTop: false,
        className: "drop-preview",
      }}
      // without this, dragging in modals shifts item location oddly
      getGhostParent={() => document.body}
      dropPlaceholderAnimationDuration={200}
      render={ref => {
        return (
          <Inner ref={ref} className={className}>
            {children}
          </Inner>
        )
      }}
    />
  )
}

export default DragDropContainer
