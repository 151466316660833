import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import Name from "../elements/name"
import List from "../features/list"
import ListForIntention from "../features/list_for_intention"
import AddToList from "../features/add_to_list"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  transition: all 0.3s;
  box-shadow: 0px -2px 10px -4px
    ${props =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -30 })};
  background: white;
  display: flex;
`

const Inner = styled.div`
  min-width: 260px;
  margin: 20px;
  display: flex;
  flex-direction: column;
`

const listStyle = css`
  height: 100%;
  & .smooth-dnd-container {
    margin: 0 !important;
  }
`

const ListForIntentionStyled = styled(ListForIntention)`
  ${listStyle}
`

const ListStyled = styled(List)`
  ${listStyle}
`

// ========================================= //
// Main Component
// ========================================= //

const PlanningBoard = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, actions] = useApp(false)
  const [intentionID] = useApp(["planningBoard", "id"])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClear = () => {
    actions.setPlanningBoard({ id: false })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className}>
      <Inner>
        {intentionID ? (
          <>
            <div onClick={handleClear}>[X]</div>
            <Name id={intentionID} tagName="h3" editable={true} />
            <ListForIntentionStyled
              id={intentionID}
              listID={"planningBoard"}
              placeholder={"Drag intentions here to copy."}
            />
            <AddToList
              listID={"planningBoard"}
              intentionID={intentionID}
              container={Container}
            />
          </>
        ) : (
          <ListStyled
            listID={"planningBoard"}
            itemIDs={[]}
            isEmpty={true}
            placeholder={"Drag an intention here to begin planning"}
          />
        )}
      </Inner>
    </Container>
  )
}

export default PlanningBoard
