import _ from "lodash"
import loadDomains from "./load_domains"
import createDomain from "./create_domain"
import updateDomain from "./update_domain"
import shareDomain from "./share_domain"

// const statusTemps = () => {
//   return {
//     default: {
//       active: {
//         name: "Active",
//         isMain: true,
//       },
//       inProgress: {
//         name: "In Progress",
//       },
//       completed: {
//         name: "Completed",
//       },
//     },
//   }
// }

const domainsReducer = (state = {}, action) => {
  state = _.cloneDeep(state)

  switch (action.type) {
    case "LOAD_DOMAINS":
      return loadDomains(state, action)
    case "LOAD_DOMAIN":
      // add default status template to domain
      // action.payload.statusTemps = action.payload.statusTemps || statusTemps()
      action.payload.views = action.payload.views || {}
      const { id } = action.payload
      return { ...state, [id]: action.payload }
    case "CREATE_DOMAIN":
      // add default status template to domain
      // action.payload.statusTemps = action.payload.statusTemps || statusTemps()
      return createDomain(state, action)
    case "UPDATE_DOMAIN":
      return updateDomain(state, action)
    case "SHARE_DOMAIN":
      return shareDomain(state, action)
    default:
      return state
  }
}

export default domainsReducer
