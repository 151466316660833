import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import { useItems } from "../../store/items/selectors"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import LastPageIcon from "@material-ui/icons/LastPage"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  display: flex;
  align-items: center;
`

const LinkStyled = styled(Link)`
  display: flex;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s;
  color: ${props =>
    props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -20 })};
  &:hover {
    text-decoration: none;
    color: ${props =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -40 })};
  }
  ${props => props.current && current};
`

const Home = styled(LastPageIcon)`
  color: ${props =>
    props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -40 })};
  transform: rotate(180deg);
  margin-right: 10px;
`

const current = css`
  font-weight: 400;
  font-size: 0.9rem;
  color: ${props =>
    props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -40 })};
`

const NavigateNextIconStyled = styled(NavigateNextIcon)`
  color: ${props =>
    props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -20 })};
`

// ========================================= //
// Main Component
// ========================================= //

const BreadcrumbNav = ({ stopAtID }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [intentionIDs, actions] = useApp(["activeIntentions"])
  const [intentions] = useItems(intentionIDs, ["id", "name"])
  const stopIndex = stopAtID
    ? _.findIndex(intentions, { id: stopAtID })
    : intentions.length

  // ========================================= //
  // Functions
  // ========================================= //

  const handleHomeClick = () => {
    actions.displayIntention({ id: "intentions", remove: true })
  }

  const handleClick = (event, id, index) => {
    event.preventDefault()
    event.stopPropagation()
    const childID = _.get(intentions[index + 1], "id", false)
    if (isCurrent(index)) return actions.setMenuItem({ id, isOpen: true })
    actions.displayIntention({ id: childID, remove: true })
  }

  const isCurrent = index => {
    return intentions.length === index + 1 ? 1 : 0
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <LinkStyled onClick={handleHomeClick}>
        <Home />
      </LinkStyled>
      <Breadcrumbs
        separator={<NavigateNextIconStyled fontSize="small" />}
        aria-label="breadcrumb"
      >
        {_.map(_.slice(intentions, 0, stopIndex), ({ id, name }, index) => (
          <LinkStyled
            key={index}
            onClick={e => handleClick(e, id, index)}
            current={isCurrent(index)}
          >
            {_.truncate(name, 20, "...")}
          </LinkStyled>
        ))}
      </Breadcrumbs>
    </Container>
  )
}

export default BreadcrumbNav
