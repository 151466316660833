import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components/macro"
import DisplayState from "../../elements/display_state"

// ========================================= //
// Styles
// ========================================= //

const leftMargin = css`
  margin-left: 250px;
  width: calc(100vw - 250px);
`

const rightMargin = css`
  margin-right: 250px;
  width: calc(100vw - 250px);
`

const bothMargin = css`
  width: calc(100vw - 500px);
`

const Container = styled.div`
  ${({ show, center, mobileFullWidth }) => css`
  display: flex;
  flex: 1;
  transition: all 0.2s;
  width: 100vw;
  ${(_.get(show, "navigator") || center) && leftMargin}
  ${(_.get(show, "item.info") || center) && rightMargin}
  ${
    ((_.get(show, "item.info") && _.get(show, "navigator")) || center) &&
    bothMargin
  }
  /* Portrait and Landscape */ 
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
      ${
        mobileFullWidth &&
        center &&
        css`
          width: calc(100vw - 60px);
          /* margin: 0%; */
          margin: 0 30px;
        `
      }

  }
`}
`

// ========================================= //
//  Main component
// ========================================= //

const ViewArea = ({ center, mobileFullWidth, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <DisplayState
      paths={["show.navigator", "show.item.info"]}
      center={center}
      mobileFullWidth={mobileFullWidth}
      Component={Container}
    >
      {children}
    </DisplayState>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

ViewArea.propTypes = {
  /**
   * Should the view area stay centered even when sidebars are closed?
   */
  center: PropTypes.bool,
  /**
   * Should the view break from center when in mobile portrait?
   */
  mobileFullWidth: PropTypes.bool,
}

ViewArea.defaultProps = {
  center: true,
  mobileFullWidth: true,
}

export default ViewArea
