import _ from "lodash"
import styled, { css } from "styled-components"

const ActiveMark = styled.div`
  grid-area: arrow;
  transition: all 0.3s;
  ${(props) =>
    props.isOpen &&
    css`
      border-right: 5px solid
        ${(props) => _.get(props, "theme.element.item.active", "red")};
      opacity: 1;
    `}
`

export default ActiveMark
