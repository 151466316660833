import _ from "lodash"
import React, { useState } from "react"
import { ReactEditor, useEditor } from "slate-react"
import { useItem } from "../../../../../../store/items/selectors"
import { Container, Pill } from "./style"
import useLink from "./use_link"
import NavTo from "../../../../../elements/nav_to"
import { Popover } from "../../../../../elements"
import Editor from "../../../index"
import { BasicLeaf } from "../../../leaf"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

// let renderedLinks = 0
const Leaf = ({ leaf, attributes, children, ...rest }) => {
  const { itemID, expand } = leaf.link
  // const [identity] = useState(renderedLinks++)
  const isSelected = useLink(itemID)
  const [item] = useItem([itemID])
  // const editor = useEditor()

  return (
    <Pill {...attributes} expand={expand} isSelected={isSelected}>
      <Popover>
        <NavTo itemID={itemID} ctrl={true}>
          {/* {leaf.expand ? item.name : children} */}
          <BasicLeaf attributes={{}} leaf={leaf}>
            {expand ? (
              <>
                {item.name} <span style={{ display: "none" }}>{children}</span>
              </>
            ) : (
              children
            )}

            {/* {expand && !isSelected ? (
              <>
                {item.name} <span style={{ display: "none" }}>{children}</span>
              </>
            ) : (
              children
            )} */}
          </BasicLeaf>
        </NavTo>
        {!isSelected && (
          <Popover.Content>
            {item.name}
            <Editor
              key={itemID + "editor"}
              id={itemID}
              listID="menu"
              editable={false}
            />
          </Popover.Content>
        )}
      </Popover>
      {/* <NavToButton itemID={itemID}>
          <NavigateNextIcon />
        </NavToButton> */}
    </Pill>
  )
}

export default Leaf
