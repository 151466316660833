import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"

// ========================================= //
//  Main component
// ========================================= //

const SetState = ({ path, value, toggle, onClick, tagName, ...props }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [current, { setSetting }] = useApp(path)
  const active = current === value || (current && _.isBoolean(current))

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = (event) => {
    const next = toggle ? (active ? false : value) : value
    // const next = toggle ? !(current || false) : active ? false : value

    setSetting(_.set({}, path, next))
    if (onClick) onClick()
  }

  // ========================================= //
  // Render
  // ========================================= //

  const Tag = `${tagName}`

  return (
    <Tag
      {...props}
      onClick={handleClick}
      className={[props.className, active ? "active" : ""].join(" ")}
    >
      {props.children}
    </Tag>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

SetState.propTypes = {
  /**
   * Which app store path should be set when clicked?
   */
  path: PropTypes.string.isRequired,
  /**
   * The value to set onClick
   */
  value: PropTypes.any,
  /**
   * Should value be toggled? (String/true)/false; else always set same value/true on click
   */
  toggle: PropTypes.bool,
  /**
   * The html element type used for container
   */
  tagName: PropTypes.string,
  /**
   * A styled-component css template literal
   */
  css: PropTypes.any,
}

SetState.defaultProps = {
  tagName: "div",
  value: true,
  toggle: true,
}

export default SetState
