import _ from "lodash"
import reorder from "./reorder"
import completeItem from "./complete_item"
import updateItem from "./update_item"
// import sessions from "./sessions"
// import updateParents from "./utils/update_parents"
// import itemsReducer from "."

const setStatusID = (state, action) => {
  const { id, statusID, position = false, parentID = false } = action.payload
  const item = state[id]
  item.statusID = statusID  

  if (statusID === "completed" || (statusID !== "completed" && item.completed)) {
    state = completeItem(state, {
      payload: { id, completed: statusID === "completed" },
    })
  }

  if (_.isNumber(position) && parentID) {
    state = reorder(state, action)
  }

  // Is this ok to be here? If you're reading this in the future and don't know
  // of any issues, just remove this comment then. 
  return updateItem(state, { payload: { fields: item, overwrite: true } })
}

export default setStatusID
