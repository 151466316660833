import _ from "lodash"
import { call, all, put, takeEvery, takeLatest } from "redux-saga/effects"
import firebase from "../../firebase"

const getSharedDomain = domain => {
  return new Promise((resolve, reject) => {
    firebase
      .getDomain(domain.ownerID, domain.id)
      .then(d => resolve(_.merge(domain, d)))
      .catch(reject)
  })
}

function* fetchDomains(action) {
  try {
    const { userID } = action.payload
    
    // fetch list of domains user has access to
    const list = yield firebase.getDomains(userID)

    // fetch shared domains
    const domains = yield all(
      _.map(list, d => (d.shared ? call(getSharedDomain, d) : d))
    )

    yield put({ type: "LOAD_DOMAINS", payload: { domains } })
  } catch (error) {
    console.log("error:", error)
    yield put({ type: "FAILED_FETCH_DOMAINS", payload: { error } })
  }
}

export default fetchDomains
