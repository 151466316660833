import _ from "lodash"
import Element from "./element"
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined"

// ========================================= //
//  Plugin
// ========================================= //

const withToolbar = (editor) => {
  const type = "toolbar"

  // define action object to be extended by my plugins
  _.set(editor, ["plugins", type], {})

  // ========================================= //
  // Register render logic
  // ========================================= //

  editor.plugins.components[type] = Element

  // ========================================= //
  // Return extended editor
  // ========================================= //

  return editor
}

export default withToolbar
