import _ from "lodash"
import React, { useContext } from "react"
import PropTypes from "prop-types"
import { LayoutContext } from "../layout_context"
import { Box, Options } from "./style"
import Content from "./content"
import SettingsButton from "@material-ui/icons/Settings"
import LockButton from "@material-ui/icons/Lock"
import LockOpenButton from "@material-ui/icons/LockOpen"
import RemoveButton from "@material-ui/icons/RemoveCircleOutline"

// ========================================= //
//  Main component
// ========================================= //

const Block = ({ block }) => {
  // ========================================= //
  // State
  // ========================================= //

  const { isEditing, updateBlock, setActiveBlock, removeBlock } = useContext(
    LayoutContext
  )

  // ========================================= //
  // Functions
  // ========================================= //

  const handleLock = () => {
    console.log('handling lock', block.id);
    updateBlock(block.id, {
      static: !block.static,
      isDraggable: !!block.static,
    })
  }

  const handleConfig = () => {
    setActiveBlock(block)
  }

  const handleRemove = () => {
    console.log('removing', block.id);
    removeBlock(block.id)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Box {...block.style}>
      {isEditing && (
        <Options>
          {block.static ? (
            <LockButton onClick={handleLock} />
          ) : (
            <>
              <SettingsButton onClick={handleConfig} />
              <LockOpenButton onClick={handleLock} />
              <RemoveButton onClick={handleRemove} />
            </>
          )}
        </Options>
      )}
      <Content block={block} isEditing={isEditing} />
    </Box>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Block.propTypes = {
  /**
   * The block to display
   */
  block: PropTypes.object.isRequired,
}

Block.defaultProps = {}

export default Block
