export function fetchItems(payload) {
  return {
    type: "FETCH_ITEMS",
    payload,
  }
}

export function loadItems(items) {
  return {
    type: "LOAD_ITEMS",
    payload: { items },
  }
}

export function loadItem(item) {
  return {
    type: "LOAD_ITEM",
    payload: { item },
  }
}

export function clearItems() {
  return {
    type: "CLEAR_ITEMS",
  }
}

export function createItem(fields, clean) {
  return {
    type: "CREATE_ITEM",
    payload: { fields, clean },
  }
}

export function updateItem(fields, publish, overwrite = false) {
  return {
    type: "UPDATE_ITEM",
    payload: { fields, publish, overwrite },
  }
}

export function setItemPath(id, path, value, options = {}) {
  return {
    type: "UPDATE_ITEM",
    payload: { fields: { id }, path, value, options },
  }
}

export function setFields(payload) {
  return {
    type: "SET_FIELDS",
    payload,
  }
}

export function makeIntention(fields) {
  return {
    type: "MAKE_INTENTION",
    payload: { ...fields, type: "intention" },
  }
}

export function makeAction(fields) {
  return {
    type: "MAKE_ACTION",
    payload: { ...fields, type: "action" },
  }
}

export function reorder(payload) {
  return {
    type: "REORDER",
    payload,
  }
}

export function reorderFields(payload) {
  return {
    type: "REORDER_FIELDS",
    payload,
  }
}

export function moveItem(payload) {
  return {
    type: "MOVE_ITEM",
    payload,
  }
}

export function linkItem(payload) {
  return {
    type: "LINK_ITEM",
    payload,
  }
}

export function removeFromItem(payload) {
  return {
    type: "REMOVE_FROM_ITEM",
    payload,
  }
}

export function addItemToParent(payload) {
  return {
    type: "ADD_TO_ITEM",
    payload,
  }
}

export function updateItemParents(payload) {
  return {
    type: "UPDATE_ITEM_PARENTS",
    payload,
  }
}

export const sessions = {
  start: (payload) => {
    return {
      type: "SESSION_START",
      payload,
    }
  },
  pause: (payload) => {
    return {
      type: "SESSION_PAUSE",
      payload,
    }
  },
  stop: (payload) => {
    return {
      type: "SESSION_STOP",
      payload,
    }
  },
  remove: (payload) => {
    return {
      type: "SESSION_REMOVE",
      payload,
    }
  },
}

export function addAction(payload) {
  return {
    type: "ADD_ACTION",
    payload,
  }
}

export function updateAction(payload) {
  return {
    type: "UPDATE_ACTION",
    payload,
  }
}

export function removeAction(payload) {
  return {
    type: "REMOVE_ACTION",
    payload,
  }
}

export function addCondition(payload) {
  return {
    type: "ADD_CONDITION",
    payload,
  }
}

export function runCondition(payload) {
  return {
    type: "RUN_CONDITION",
    payload,
  }
}

export function removeCondition(payload) {
  return {
    type: "REMOVE_CONDITION",
    payload,
  }
}

export function completeItem(payload) {
  return {
    type: "COMPLETE_ITEM",
    payload,
  }
}

export function setStatusID(payload) {
  return {
    type: "SET_STATUS_ID",
    payload,
  }
}

export function satisfyItem(payload) {
  return {
    type: "SATISFY_ITEM",
    payload,
  }
}

export function archiveItem(payload) {
  return {
    type: "ARCHIVE_ITEM",
    payload,
  }
}

export function deleteItem(payload) {
  return {
    type: "DELETE_ITEM",
    payload,
  }
}
