import styled, { css } from "styled-components"
import Menu from "./menu"

export const MenuContainer = styled.div`
  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
`

export const MenuStyled = styled(Menu)`
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 12000;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.75s;
`

export const IconSpan = styled.span`
  svg {
    font-size: 18px;
    vertical-align: text-bottom;
  }
`

export const ButtonSpan = styled.span`
  cursor: pointer;
  color: ${(props) =>
    props.reversed
      ? props.active
        ? "white"
        : "#aaa"
      : props.active
      ? "black"
      : "#ccc"};
`
