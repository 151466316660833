import _ from "lodash"
import React, { useRef } from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

// ========================================= //
// Styles
// ========================================= //

const FormControlLabelStyled = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 12px;
    transition: all 0.3s;
    color: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -20})};
    &:hover {
      color: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -40})};
    }
  }
  & span.MuiSwitch-track {
    background: #6b788e;
    background: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -20})};

  }
  & .MuiSwitch-colorPrimary.Mui-checked {
    color: #7a8ba7;
    color: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -40})};
  }
  & .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background: #7a8ba7;
    background: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -40})};
  }
`

// ========================================= //
// Main Component
// ========================================= //

const SwitchSetting = ({ label, field }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [value, { setSetting }] = useApp([field])
  const switchRef = useRef(null)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleChange = event => {
    const state = event.target.checked
    setSetting({ [field]: state })
    switchRef.current.blur()
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <div>
      <FormControlLabelStyled
        control={
          <Switch
            inputRef={switchRef}
            checked={value ? true : false}
            onChange={handleChange}
            name={field}
            color="primary"
          />
        }
        label={label}
      />
    </div>
  )
}

export default SwitchSetting
