import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { parsePaper } from "./parse_paper"
import Paper from "./paper"
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious"
import SkipNextIcon from "@material-ui/icons/SkipNext"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  opacity: 0;
  transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      max-height: inherit;
    `}
`

const Header = styled("h2")`
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s;
    &:hover {
      opacity: 1;
    }
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Results = ({ id, philosophersID, papersID, results }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [index, setIndex] = useState(0)
  const fields = parsePaper(results, index)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    setIndex(0)
  }, [results, setIndex])
  
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container show={fields}>
      <Header>
        {!index && "Top"} Result {!!index && index + 1}
        <div>
          <SkipPreviousIcon onClick={() => index && setIndex(index - 1)} />
          <SkipNextIcon onClick={() => setIndex(index + 1)} />
        </div>
      </Header>
      <Paper {...{ id, philosophersID, papersID, fields }} />
    </Container>
  )
}

export default Results
