import _ from "lodash"
import updateItem from "./update_item"
import { newItem } from "./create_item"

const initState = (state, action) => {
  if (_.size(state)) return state

  const item = newItem({
    id: "intentions",
    type: "intention",
    name: action.domainName || "All Intentions",
    shortName: "All",
    description: "Your top level intentions",
  })

  delete item.parentID
  delete item.parentIDs

  return updateItem(state, { payload: { fields: item, overwrite: true } })
}

export default initState
