export const newPaper = (
  philosophersID,
  philosopherID,
  { published, reference, abstract }
) => ({
  [philosophersID]: {
    id: philosophersID,
    type: "select",
    name: "Philosopher",
    index: 0,
    source: {
      id: philosophersID,
      path: "itemIDs",
    },
    value: {
      id: philosopherID,
      path: "name",
    },
  },
  Published: {
    id: "Published",
    type: "text",
    name: "Published",
    index: 1,
    value: published,
  },
  Reference: {
    index: 2,
    id: "Reference",
    value: reference,
    name: "Reference",
    type: "text",
  },
  Meeting: {
    name: "Meeting",
    type: "date",
    id: "Meeting",
    value: false,
    index: 3,
  },
  Read: {
    name: "Read",
    type: "date",
    id: "Read",
    value: false,
    index: 4,
  },
  Abstract: {
    id: "Abstract",
    type: "text",
    name: "Abstract",
    index: 1,
    value: abstract,
  },
})
