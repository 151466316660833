import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import { Container, Inner, Name, Value } from "./styles"
import FileCopyIcon from "@material-ui/icons/FileCopy"

// ========================================= //
//  Main component
// ========================================= //

const Box = ({ name, value, Icon }) => {

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <Inner>
        {Icon && <Icon />}
        <Value>{value}</Value>
        <Name>{name}</Name>
      </Inner>
    </Container>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Box.propTypes = {
  /**
   * The name of the stat to display
   */
  name: PropTypes.string.isRequired,
  /**
   * the value/state to display
   */
  value: PropTypes.any.isRequired,
}

Box.defaultProps = {}

// ========================================= //
// Example props for View Builder
// ========================================= //

export const exampleProps = {
  name: "Title Here",
  value: 35,
  Icon: FileCopyIcon
}

// ========================================= //
// Default export
// ========================================= //

export default Box
