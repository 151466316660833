import _ from "lodash"
import React, { useState } from "react"
import styled from "styled-components"
import { useItem, useItems } from "../../../store/items/selectors"
import CustomFieldSearch from "./field_search"
import FieldLabel from "./field_label"
import ShareIcon from "@material-ui/icons/Share"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  span {
    transition: all 0.3s;
    opacity: 0.4;
  }
  svg {
    transition: all 0.3s;
    opacity: 0;
    font-size: 15px;
    margin-left: 10px;
  }
  &:hover {
    span {
      opacity: 1;
    }
    svg {
      opacity: 1;
    }
  }
`

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldSelect = ({
  id,
  fieldID,
  showLabel,
  defaults,
  isNew,
  onBlur,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  fieldID = _.get(defaults, "fieldID", fieldID)

  const [fieldSource, actions] = useItem([id, "fields", fieldID, "source"])
  // use default source if provided, else use item's field
  const source = _.get(defaults, "source", fieldSource)
  const [fieldValue] = useItem([id, "fields", fieldID, "value"])
  // use default value if provided, else use item's field
  const value = _.get(defaults, "value", fieldValue || {})  

  const sourcePath = source ? _.flatten([source.id, source.path]) : false

  const [sourceItemIDs] = useItem(sourcePath)
  const [items] = useItems(sourceItemIDs)

  const [showModal, showModalSet] = useState(isNew)

  // ========================================= //
  // Functions
  // ========================================= //

  const save = (sourceID, valueID = false) => {
    showModalSet(false)

    const field = _.has(defaults, 'name')
      ? _.clone(defaults)
      : {
          id: sourceID,
          type: "select",
          source: {
            id: sourceID,
            path: "itemIDs",
          },
        }

    field.value = {
      id: valueID,
      path: "name",
    }

    actions.setFields({
      id,
      fields: {
        [fieldID]: field,
      },
    })
  }

  const handleChange = event => {
    const id = event.target.value
    save(source.id, id)
    // if (onBlur) onBlur()
  }

  const closeModal = () => {
    if (!source && onBlur) {
      // delete field on modal close if nothing selected
      actions.setFields({ id, fields: { [fieldID]: false } })
      onBlur()
    }
    showModalSet(false)
  }

  // ========================================= //
  // Render
  // ========================================= //

  // if (showModal) return (<>YAY</>)
  // return null
  return (
    <Container>
      {showLabel && <FieldLabel id={id} fieldID={fieldID} onBlur={onBlur} />}
      {_.size(items) ? (
        <FormControl>
          <Select value={value.id || false} onChange={handleChange}>
            <MenuItem value={false}>...</MenuItem>
            {_.map(items, ({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Content onClick={() => showModalSet(true)}>
          <span>Click to search items</span>
          <ShareIcon />
        </Content>
      )}

      {showModal && (
        <CustomFieldSearch
          id={id}
          fieldID={fieldID}
          // link={value}
          isOpen={showModal}
          type={"select"}
          onSave={save}
          onClose={closeModal}
        />
      )}
    </Container>
  )
}

export default CustomFieldSelect
