import _ from "lodash"
import React, { useEffect } from "react"
import { useItem } from "../../../store/items/selectors"
import CustomField from "./custom_field"

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldNewDefault = params => {
  // ========================================= //
  // State
  // ========================================= //
  
  const { id: defaultID, fieldID } = _.get(params, "defaultSource", {})
  const [defaultField] = useItem([defaultID, "fields", fieldID])

  let newParams
  if (defaultField && defaultField.type) {
    newParams = _.cloneDeep(params)
    newParams.fieldID = fieldID
    newParams.defaults = _.cloneDeep(defaultField)
    delete newParams.defaults.value 
    delete newParams.defaultSource 
  } else {
    console.log("FAILED TO LOAD DEFAULT FIELD")
  }

  // ========================================= //
  // Effects
  // ========================================= //

  // useEffect(() => {
  //   if (!field && defaultField) {
  //     const defaults = _.cloneDeep(defaultField)
  //     defaults.value = false
  //     // if (_.isObject(defaults.value)) {
  //     //   defaults.value = ...
  //     // }
  //     actions.setFields({
  //       publish: false,
  //       id,
  //       fields: { [fieldID]: defaults },
  //     })
  //   }
  // }, [])

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  // WARNING: recursion
  return newParams ? <CustomField {...newParams} /> : null
}

export default CustomFieldNewDefault
