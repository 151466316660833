import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import ActiveIntentionBlock from "../features/active_intention_block"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Inner = styled.div`
  display: flex;
  min-width: 50vw - 75;
  padding: 10px 20px 10px 20px;
`

// ========================================= //
// Main Component
// ========================================= //

const Nested = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [limit] = useApp(["activeIntentionLimit"])
  const limit = 50

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  // display the tips of the intention tree [-3, -2, -1]
  const indexes = _.range(1, limit + 1, 1)

  return (
    <Container className={className}>
      <Inner>
        SHIT
        {_.map(indexes, index => (
          <ActiveIntentionBlock
            gridArea={"i" + index}
            key={"active" + index}
            index={index}
          />
        ))}
      </Inner>
    </Container>
  )
}

export default Nested
