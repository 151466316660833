
export function setLoading(payload) {
  return {
    type: "SET_LOADING",
    payload,
  }
}

export function loadData() {
  return {
    type: "LOAD_DATA",
    payload: {},
  }
}

export function loaded() {
  return {
    type: "LOADED",
    payload: {},
  }
}

export function setSetting(payload) {
  return {
    type: "SET_SETTING",
    payload,
  }
}

export function setCurrentDomain(payload) {
  return {
    type: "SET_CURRENT_DOMAIN",
    payload,
  }
}

export function setPlanningBoard(payload) {
  return {
    type: "SET_PLANNING_BOARD",
    payload,
  }
}

export function setShowPlanner(payload) {
  return {
    type: "SET_SHOW_PLANNER",
    payload,
  }
}

export function setShowSettings(payload) {
  return {
    type: "SET_SHOW_SETTINGS",
    payload,
  }
}

export function setMenuItem(payload) {
  return {
    type: "SET_MENU_ITEM",
    payload,
  }
}

export function setStatusBoardID(payload) {
  return {
    type: "SET_STATUS_BOARD_ID",
    payload,
  }
}

export function setShowMobileMenu(payload) {
  return {
    type: "SET_SHOW_MOBILE_MENU",
    payload,
  }
}

export function setBigTrackerItem(payload) {
  return {
    type: "SET_BIG_TRACKER_ITEM",
    payload,
  }
}

export function setBoard(payload) {
  return {
    type: "SET_BOARD",
    payload,
  }
}

export function setBoardPrev(payload) {
  return {
    type: "SET_BOARD_PREV",
    payload,
  }
}

export function displayIntention(payload) {
  return {
    type: "SET_ACTIVE_INTENTION",
    payload,
  }
}

export function setShowActive(show) {
  return {
    type: "SET_SHOW_ACTIVE",
    payload: {show},
  }
}

export function setShowQuickNav(show) {
  return {
    type: "SET_SHOW_QUICK_NAV",
    payload: {show},
  }
}

export function setShowQuickAdd(show) {
  return {
    type: "SET_SHOW_QUICK_ADD",
    payload: {show},
  }
}

export function setNewItemParentID(payload) {
  return {
    type: "SET_NEW_ITEM_PARENT_ID",
    payload,
  }
}