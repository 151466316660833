import _ from "lodash"
import updateItem from "./update_item"
import reorderFields from "./reorder_fields"

const setFields = (state, action) => {
  const { id, fields } = action.payload
  const originalItem = _.cloneDeep(state[id])
  const item = state[id]

  _.each(fields, (field, fieldID) => {
    // if field was set to false, delete field
    if (!field) return delete item.fields[fieldID]

    field.id = field.id || fieldID
    field.type = field.type || item.fields[fieldID].type

    // new field or an index was provided
    if (!item.fields[field.id] || field.index) {
      item.fields[field.id] = field
      state = reorderFields(state, {
        payload: { id, fieldID, position: field.index || 0 },
      })
    }

    // for all non-linked fields
    if (_.includes(["link", "select"], field.type)) {
      if (!field.name && !_.get(item.fields, [field.id, "name"])) {
        field.name = { id: field.id }
      }

      if (fieldID !== field.id) {
        replaceID(item, fieldID, field.id)
      }
    } else {
      // change id to match name
      if (field.name && fieldID !== field.name) {
        field.id = field.name
        replaceID(item, fieldID, field.id)
      }
    }
    // merge changes
    item.fields[field.id] = _.merge(item.fields[field.id], field)
  })

  // this is to allow updateItem to track changes for managing item links
  state[id] = originalItem
  return updateItem(state, {
    payload: { fields: item, overwrite: true },
  })
}

const replaceID = (item, oldID, newID) => {
  item.fields[newID] = item.fields[oldID]
  // remove old id reference
  delete item.fields[oldID]
}

export default setFields
