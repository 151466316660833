import _ from "lodash"
import React, { useRef } from "react"
import styled from "styled-components"
import { useItem } from "../../store/items/selectors"
import { useApp } from "../../store/app/selectors"
import BaseItem from "../elements/base_item"
import EditableField from "../elements/editable_field"
import Overlay from "../elements/overlay"

// ========================================= //
// Styles
// ========================================= //

const BaseItemStyled = styled(BaseItem)`
  z-index: 20000;
`

const Name = styled(EditableField)`
  grid-area: name;
`

// ========================================= //
// Main Component
// ========================================= //

const NewItem = ({ listID, parentID, fields, show, onBlur, width, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, { createItem }] = useItem(false)
  // const [newItemParentID, { setNewItemParentID }] = useApp(["newItemParentID"])
  // const itemRef = useRef(null)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleSave = name => {
    if (!name.length) return
    // console.log("createItem", { name, parentID, ...fields })

    if (name.length) createItem({ name, parentID, ...fields })

    // setNewItemParentID(false)
    if (onBlur) onBlur()
  }

  const handleBlur = () => {
    // setNewItemParentID(false)
    if (onBlur) onBlur()
  }

  // ========================================= //
  // Render
  // ========================================= //

  if (!show) return null

  return (
    <>
      <Overlay visible={true} background={"#d7e2ea80"} />
      <BaseItemStyled className={className} width={width}>
        <Name onSave={handleSave} hasFocus={true} onBlur={handleBlur} />
      </BaseItemStyled>
    </>
  )
}

export default NewItem
