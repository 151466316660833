import _ from "lodash"
import React from "react"
import { useApp } from "../../store/app/selectors"
import { useItem } from "../../store/items/selectors"
import IconButton from "@material-ui/core/IconButton"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import DropDownMenu from "./drop_down_menu"

// ========================================= //
// Main Component
// ========================================= //

const ItemOptionsMenu = ({
  id,
  parentID,
  childID,
  include = [],
  useContextMenu,
  children,
  className,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, { setSetting, setMenuItem }] = useApp(false)
  const [parentIDs, actions] = useItem([id, "parentIDs"])
  const [childParentIDs] = useItem([childID, "parentIDs"])
  const [childCount] = useItem([id, "itemIDs"], _.size)
  const options = _.filter([
    { id: "edit", name: "Edit" },
    { id: "remove", name: "Remove" },
    { id: "removeParent", name: "Remove as parent" },
    { id: "delete", name: "Delete" },
  ], (o) => _.size(include) ? _.includes(include, o.id) : true)

  // only allow removal if more than one parent
  if (childID || _.size(parentIDs) < 2) _.pullAt(options, 1)

  if (!childID || _.size(childParentIDs) < 2) _.remove(options, { id: "removeParent" })



  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    console.log("CLICKED")
  }

  const handleChange = option => {
    switch (option.id) {
      case "edit":
        return setMenuItem({ id, isOpen: true })
      case "remove":
        return actions.removeFromItem({ id, parentID })
      case "removeParent":
        return actions.removeFromItem({ id: childID, parentID: id})
      case "delete":
        if (childCount) {
          const confirmed = window.confirm(
            "This will delete all nested items not also belonging to other items. Are you sure?"
          )
          if (!confirmed) return false
        }

        return actions.deleteItem({ id, parentID })
      default:
        return
    }
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    // <div className={className} aria-label="item-menu">
      <DropDownMenu
        useContextMenu={useContextMenu}
        className={className}
        options={options}
        activeIndex={false}
        onChange={handleChange}
      >
        {/* <ArrowDropDownIcon /> */}
        {children ? children : <ArrowDropDownIcon />}
      </DropDownMenu>
    // </div>
  )
}

export default ItemOptionsMenu
