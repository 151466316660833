import _ from "lodash"
import React, { useContext } from "react"
import PropTypes from "prop-types"
import { PopoverContext } from "./index"
import { MuiPopover, MotionInner } from "./style"
import { motion, AnimatePresence } from "framer-motion"

// ========================================= //
// Styles
// ========================================= //

// ========================================= //
//  Main component
// ========================================= //

const Content = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const { open, anchorEl, handleClose } = useContext(PopoverContext)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <MuiPopover
      id="mouse-over-popover"
      open={open}
      anchorEl={anchorEl}
      elevation={1}
      // anchorOrigin={{
      //   vertical: "bottom",
      //   horizontal: "left",
      // }}
      // transformOrigin={{
      //   vertical: -10,
      //   horizontal: "left",
      // }}
      onClose={handleClose}
      // disableRestoreFocus
    >
      <AnimatePresence>
        <MotionInner
          initial={{ opacity: 0, marginTop: 5 }}
          animate={{ opacity: 1, marginTop: 0  }}
          transition={{ delay: 0.3 }}
          exit={{ opacity: 0, marginTop: 5  }}
        >
          {children}
        </MotionInner>
      </AnimatePresence>
    </MuiPopover>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Content.propTypes = {}

Content.defaultProps = {}

export default Content
