import _ from "lodash"
import React from "react"
import { useItem, useItems } from "../../store/items/selectors"
import List from "./list"

// ========================================= //
// Main Component
// ========================================= //

const ParentList = ({ id, container }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [parentIDs] = useItem([id, "parentIDs"])
  const items = _.map(parentIDs, parentID => ({
    id: parentID,
    key: parentID + "parentList",
    listID: "itemMenu",
    removeIDs: { childID: id, parentID: parentID },
  }))

  // ========================================= //
  // Functions
  // ========================================= //

  console.log("parentIDs:", parentIDs)

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <List
      listID={"parentList"}
      parentID={false}
      itemIDs={parentIDs}
      canAdd={false}
    />
  )
}

export default ParentList
