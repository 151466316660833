import _ from 'lodash'

const remove_null_fields = (object) => {
  return recursivelyRemoveNullFields(object)
}

const recursivelyRemoveNullFields = (object) => {
  _.each(object, (value, key) => {
    if (_.isNull(value)) {
      _.unset(object, key)
    } else if (_.isObject(value)) {
      recursivelyRemoveNullFields(value)
    }
  })

  return object
}

export default remove_null_fields
