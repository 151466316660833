import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import SetSetting from "../elements/set_setting"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import Overlay from "../elements/overlay"

// ========================================= //
// Styles
// ========================================= //

const SetSettingStyled = styled(SetSetting)`
  align-self: center;
  padding-top: 4px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

const Panel = styled.div`
  position: absolute;
  top: 50px;
  right: 10px;
  width: 200px;
  max-height: 0;
  min-height: 0px;
  overflow: hidden;
  border: 1px solid
    ${props =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -10 })};
  box-shadow: 0px 0px 28px 2px
    ${props =>
      props.theme.toHSL(props.theme.colors.primary, {
        s: -20,
        l: -3,
      })};
  background: white;
  padding: 20px;
  transition: all 0.3s;
  ${props =>
    props.visible &&
    css`
      max-height: 50vh;
      min-height: 300px;
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const Notifications = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [show, setShow] = useState(false)

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <SetSettingStyled setting={"showNotifications"}>
        <NotificationsActiveIcon onClick={() => setShow(true)} />
      </SetSettingStyled>
      <Overlay visible={show} onClose={() => setShow(false)}>
        <Panel visible={show}>Notifications here in time and stuff.</Panel>
      </Overlay>
    </>
  )
}

export default Notifications
