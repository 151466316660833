import _ from "lodash"
import satisfyItem from "./satisfy_item"
import completeItem from "./complete_item"

const allChildrenSatisfied = (items, item) => {
  // only check after first item has been added
  if (!item.itemIDs.length) return false
  const allSatisfied = _.every(item.itemIDs, id => {
    const child = items[id]
    return child.satisfied || child.completed || child.isDeleted
  })

  return allSatisfied
}

const mappedConditions = {
  allCompleted: allChildrenSatisfied,
}

const checkConditions = (items, item) => {
  const conditions = _.get(item, "conditions.satisfaction", {})
  if (!_.size(conditions)) return false

  return _.every(conditions, condition => {    
    const conditionFn = mappedConditions[condition.id]
    return conditionFn ? conditionFn(items, item) : false
  })
}

const updateStatus = (state, action) => {
  const { id } = action.payload
  const item = state[id]
  const completed = checkConditions(state, item)      

  return item.completed !== completed
    ? completeItem(state, { payload: { id, completed } })
    : state
  // : satisfyItem(state, { payload: { id, satisfied } })
}

export default updateStatus
