import _ from "lodash"

let changedItems = {}

const trackChange = item => {
  if (item) changedItems[item.id] = item
}

const getChanges = () => {
  // if the only field updated is the updated field, just don't...
  // _.each(changedItems, (item) => {
  //   if (_.size(item) === 1) delete changedItems[item.id]
  // })

  const changes = _.cloneDeep(changedItems)
  changedItems = {}
  return changes
}

export { trackChange, getChanges }
