import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import SetSetting from "../set_setting"

// ========================================= //
// Styles
// ========================================= //

export const linkStyle = css`
  cursor: ${({ cursor }) => cursor || "pointer"};
  display: inline;
  position: relative;
  /* border-bottom: 1px dashed #b7b2b2; */
  transition: all 0.3s;
  & svg {
    transition: all 0.3s;
    opacity: 0;
  }
  &:hover {
    /* border-color: #272727; */
    svg {
      opacity: 0.9;
      background: white;
    }
  }
`

const Link = styled(SetSetting)`
  ${linkStyle}
`

// ========================================= //
//  Main component
// ========================================= //

const NavTo = ({ itemID, ctrl, children, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [cursor, setCursor] = useState('pointer')

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = (e) => {
    // stop clicks that require the meta key
    if (ctrl && !e.metaKey) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const handleEnter = (e) => {
    if (!ctrl) return
    setCursor(e.metaKey ? "pointer" : "auto")
  }

  const handleLeave = (e) => {
    //if (!ctrl) return
    // cursor = metaKey ? "pointer" : "auto"
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Link
      path="currentItemID"
      value={itemID}
      span={true}
      className={className}
      cursor={cursor}
    >
      <span
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onClick={handleClick}
      >
        {children}
      </span>
    </Link>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

NavTo.propTypes = {
  /**
   * The ID of the item to navigate to
   */
  itemID: PropTypes.string.isRequired,
  /**
   * The click event will work only if ctrl/command key pressed
   */
  ctrl: PropTypes.bool,
}

NavTo.defaultProps = {
  ctrl: false,
}

export default NavTo
