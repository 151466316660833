import _ from "lodash"
import React, { useState } from "react"
import styled from "styled-components"
import { Draggable } from "react-smooth-dnd"
import VisibilitySensor from "react-visibility-sensor"
import Item from "./item"

// ========================================= //
// Style
// ========================================= //

const DragDiv = styled.div`
  overflow: visible !important;
  transition: opacity 0.3s;
  transition-delay: ${(props) => props.delay}s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  /* transform: ${(props) =>
    props.visible ? "translateX(0%)" : "translateX(3%)"}; */
  /* transition-duration: 0.5s; */
  transition-timing-function: ease-in-out;
  /* transition-property: transform; */
  /* transition-delay: ${(props) => props.delay}s; */
`

// ========================================= //
// Main Component
// ========================================= //

const ItemDraggable = ({
  id,
  listIndex,
  parentID,
  listID,
  ItemComp,
  itemParams,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [visible, setVisible] = useState(false)
  const [delay, setDelay] = useState(0)
  const loadedAt = Date.now()

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const setFirstVisibility = (isVisible) => {
    if (visible || !isVisible) return
    const sinceLoad = Date.now() - loadedAt
    const offset = sinceLoad > 500 ? 0 : listIndex
    setDelay(offset * 0.03)
    setVisible(true)
  }

  // ========================================= //
  // Render
  // ========================================= //

  ItemComp = ItemComp ? ItemComp : Item

  return (
    <Draggable
      render={() => (
        // needs to be inside DragDiv to work
        <DragDiv delay={delay} visible={visible}>
          <VisibilitySensor onChange={setFirstVisibility}>
            <ItemComp {...{ id, parentID, listID, ...itemParams }} />
          </VisibilitySensor>
        </DragDiv>
      )}
    />
  )
}

export default ItemDraggable
