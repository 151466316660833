import _ from "lodash"
import React from "react"
import styled from "styled-components"
import SetSetting from "../elements/set_setting"
import MenuIcon from "@material-ui/icons/Menu"
import AddToQueueIcon from "@material-ui/icons/AddToQueue"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-items: stretch;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`

const SetSettingStyled = styled(SetSetting)`
  padding: 3px 10px;
  font-size: 13px;
  margin-right: 15px;
  margin-top: 6px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${props => props.theme.element.menu.text};
  &:hover {
    background: ${props =>
      props.theme.toHSL(props.theme.colors.primary, { s: -5, l: -4 })};
  }
  &.active {
    color: white;
    /* background: #798390;
    background: #88919c; */
    background: ${props => props.theme.element.menu.text};
    /* background: #627792; */
  }
`

const MenuIconStyled = styled(MenuIcon)`
  color: ${props => props.theme.element.menu.text};
  margin-right: 50px;
  margin-top: 4px;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
`

const SetSettingAddStyled = styled(SetSettingStyled)`
  margin-top: 5px;
  span {
    padding-left: 3px;
    transition: all 0.3s;
    opacity: 0.3;
  }
  &:hover {
    span {
      opacity: 1;
    }
  }
`

const AddToQueueIconStyled = styled(AddToQueueIcon)`
  padding-top: 3px;
  font-size: 13px;
`

// ========================================= //
// Main Component
// ========================================= //

const BoardMenu = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className}>
      <SetSetting path={"sections.sidebar"} toggle={true}>
        <MenuIconStyled />
      </SetSetting>
      <SetSettingStyled path={"sections.panel2"} value="nested">Cards</SetSettingStyled>
      {/* <SetSettingStyled path={"sections.panel0"}>Planner</SetSettingStyled> */}
      <SetSettingStyled path={"sections.panel2"} value="writer">Write</SetSettingStyled>
      <SetSettingStyled path={"sections.panel2"} value="kanban">Kanban</SetSettingStyled>
      {/* <SetSettingAddStyled path={"nothing"}>
        <AddToQueueIconStyled /> <span>View</span>
      </SetSettingAddStyled> */}
    </Container>
  )
}

export default BoardMenu
