import _ from "lodash"
import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useItem } from "../../../store/items/selectors"
import ContentEditable from "react-sane-contenteditable"
import { CardActions } from "@material-ui/core"
import FieldType from "./field_type"

// ========================================= //
// Styles
// ========================================= //

const Label = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: top;
  svg {
    opacity: 0;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`

const NameField = styled(ContentEditable)`
  font-weight: normal;
  outline: none;
  cursor: text;
  flex-shrink: 1;
  &[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: #d2d2d2;
    font-weight: normal;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldLabel = ({
  id,
  fieldID,
  isNew,
  onBlur,
  placeholder,
  className,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [fieldName, actions] = useItem([id, "fields", fieldID, "name"])
  const sourcePath = _.isObject(fieldName) ? [fieldName.id, "name"] : false
  const [sourceName] = useItem(sourcePath)
  const name = sourceName || _.isObject(fieldName) ? false : fieldName

  const [content, setContent] = useState(name)
  const elRef = useRef(null)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (isNew) elRef.current.ref.focus()
  }, [])

  useEffect(() => {
    if (sourceName) setContent(sourceName)
  }, [sourceName])

  // ========================================= //
  // Functions
  // ========================================= //

  const save = () => {
    const newName = _.size(content) ? content : name
    const field = newName || !isNew ? { name: newName || "" } : false
    actions.setFields({
      id,
      fields: { [fieldID]: field },
    })
    if (onBlur) onBlur(content)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Label className={className}>
      <NameField
        ref={elRef}
        tagName={"div"}
        placeholder={placeholder || `Add field label`}
        caretPosition="end"
        content={content || ""}
        editable={true}
        maxLength={50}
        multiLine={false}
        onChange={(e, value) => setContent(value)}
        onPaste={value => setContent(value)}
        onBlur={save}
      />
      <FieldType id={id} fieldID={fieldID} />
    </Label>
  )
}

export default CustomFieldLabel
