import _ from 'lodash'

const arrange = (parent, id, position) => {  
  parent = _.cloneDeep(parent)

  // add to bottom of list if no position provided
  position = _.isNumber(position) ? position : parent.itemIDs.length
 
  // remove item from current position
  _.remove(parent.itemIDs, (ID) => ID === id)  

  // add item at new position
  parent.itemIDs.splice(position, 0, id)

  return parent
}

export const arrangeArray = (array, item, position) => {  
  array = _.cloneDeep(array)

  // add to bottom of list if no position provided
  position = _.isNumber(position) ? position : array.length
 
  // remove item from current position
  _.remove(array, element => _.isEqual(item, element))  

  // add item at new position
  array.splice(position, 0, item)

  return array
}

export default arrange