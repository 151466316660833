import _ from "lodash"
import React, { useState, useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem, useItemsPath } from "../../../../store/items/selectors"
import Name from "../../../elements/name"
import Editor from "../../../features/editor"
import CustomField from "../../../elements/custom_field"
import { COMPONENTS } from "../all_components"

// ========================================= //
// Main Component
// ========================================= //

const Content = ({ block, isEditing }) => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render˝
  // ========================================= //

  const src = _.get(COMPONENTS, [block.type], false)
  if (!src) return null

  const Component = src.Comp
  const props = { ...src.exampleProps, ...block.props }

  return <Component {...props} />
}

export default Content
