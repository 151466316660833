import _ from "lodash"
import React from "react"
import { useItem } from "../../../store/items/selectors"
import styled, { css } from "styled-components"
import FieldText from "./field_text"
import FieldDate from "./field_date"
import FieldLink from "./field_link"
import FieldSelect from "./field_select"
import FieldNewDefault from "./field_new_default"

const FieldComps = {
  text: FieldText,
  date: FieldDate,
  link: FieldLink,
  select: FieldSelect,
}

// ========================================= //
// Style
// ========================================= //

const Field = styled.div`
  margin: 10px 20px;
`

// ========================================= //
// Main Component
// ========================================= //

const CustomField = ({
  id,
  fieldID,
  showLabel = true,
  isNew,
  defaultType,
  defaultSource = {},
  defaults = {},
  onBlur,
  className
}) => {
  const [type] = useItem([id, "fields", fieldID, "type"])
  const FieldType = _.get(
    FieldComps,
    type || defaultType || defaults.type,
    false
  )
 
  return (
    <Field className={className}>
      {FieldType ? (
        <FieldType
          id={id}
          fieldID={fieldID}
          showLabel={showLabel}
          defaults={defaults}
          isNew={isNew}
          onBlur={onBlur}
        />
      ) : (
        // <>oops</>
        <FieldNewDefault
          {...{
            id,
            fieldID,
            showLabel,
            isNew,
            defaultSource,
            onBlur,
          }}
        />
      )}
    </Field>
  )
}

export default CustomField
