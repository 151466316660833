import _ from "lodash"
import updateStatus from "./update_status"
import updateItem from "./update_item"
import { isSelfOrChild, arrange } from "../../../utils"

const addToItem = (state, action) => {
  const { id, parentID, position } = action.payload
  const item = state[id]
  let parent = state[parentID]

  // avoid infinite reference
  if (isSelfOrChild(state, parent, item)) return state
  
  // avoid re-adding
  if (!_.includes(item.parentIDs, parentID)) {
    item.parentIDs.push(parent.id)
    state = updateItem(state, { payload: { fields: item, overwrite: true } })
  }

  parent = arrange(parent, id, position)    

  state = updateItem(state, {
    payload: { fields: parent, overwrite: true },
  })

  state = updateStatus(state, { payload: { id: parentID } })

  return state
}

export default addToItem
