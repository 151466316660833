import styled, { css } from "styled-components"

export const allowTouch = css`
  & * {
    /* Exception for input areas */
    /* https://gist.github.com/netpoetica/a1234ae1d4d2988f03ef */
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
  }
`

export const fullHeight = css`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
