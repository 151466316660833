import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useApp } from "../../../store/app/selectors"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  display: flex;
  flex: 1;
`

// ========================================= //
//  Main component
// ========================================= //

/**
 * A listener wrapper that will add the provided settings paths listeners
 * to the provided component and pass the current values as props.
 */

const GetState = ({ paths, children, className, ...props }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [state] = useApp(false, (state) => _.pick(state.app, paths))

  // ========================================= //
  // Functions
  // ========================================= //

  const getState = () => {
    // set default values when none found
    return _.reduce(
      paths,
      (result, path) => _.set(result, path, _.get(state, path, false)),
      {}
    )
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container {...state} {...props} className={className}>
      {children(getState())}
    </Container>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

GetState.propTypes = {
  /**
   * The app state path to listen to
   */
  paths: PropTypes.array.isRequired,
  // /**
  //  * The wrapper component. If none provided, a basic div with an active class added will be returned.
  //  */
  // Component: PropTypes.elementType,
  // /**
  //  * If setting is false or undefined, don't render children
  //  */
  // hardHide: PropTypes.bool,
  // /**
  //  * If paths are set, and hardHide set to true, hide children on true settings
  //  */
  // reverse: PropTypes.bool,
}

GetState.defaultProps = {}

export default GetState
