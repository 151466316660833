import _ from "lodash"
import React from "react"
import { useItem, useItems } from "../../store/items/selectors"
import { useApp } from "../../store/app/selectors"
import List from "./list"

// ========================================= //
// Main Component
// ========================================= //

const ListForIntention = ({ id, listID, placeholder, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [showCompleted] = useApp(["showCompleted"])
  const [itemIDs] = useItem([id, "itemIDs"])
  const [filteredIDs] = useItem([id, "itemIDs"], (ids, state) =>
    _.filter(
      ids,
      id => !state.items[id].isDeleted && !state.items[id].completed
    )
  )

  const isEmpty = !_.size(itemIDs) || (!showCompleted && !_.size(filteredIDs))

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <List
      listID={listID}
      parentID={id}
      itemIDs={itemIDs}
      isEmpty={isEmpty}
      placeholder={placeholder}
      className={className}
    />
  )
}

export default ListForIntention
