import _ from "lodash"
import { displayIntention } from "../actions"

// hide active intention blocks when intentions change
const manageActiveIntentions = (store, action) => {
  // const interests = ["LOAD_ITEM", "MOVE_ITEM", "REMOVE_FROM_ITEM", "DELETE_ITEM"]
  const interests = ["LOAD_ITEM"]
  if (!_.includes(interests, action.type)) return

  const { id, parentID, oldParentID, parentIDs, deletedAt } = action.payload
  
  // check for external delete events
  // not working though
  if (action.type === "LOAD_ITEM" && !deletedAt) return

  const activeIntentions = _.get(store.getState(), ["app", "activeIntentions"])

  // no active intentions need closing
  if (!_.includes(activeIntentions, id)) return

  // close "hanging" active intentions
  store.dispatch(
    displayIntention({
      id,
      parentID: oldParentID || parentID || parentIDs[0],
      remove: true,
    })
  )
}

export default manageActiveIntentions
