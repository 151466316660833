import _ from "lodash"
import { useState, useRef, useEffect } from "react"
import { useItem } from "../../../store/items/selectors"

// ========================================= //
// Content function
// ========================================= //

const setContent = (description) => {
  let initValue
  try {
    initValue = _.isString(description) ? JSON.parse(description) : description
  } catch (e) {
    initValue = description
  }

  return _.isArray(initValue)
    ? initValue
    : [
        {
          type: "paragraph",
          children: [{ text: initValue || "" }],
        },
      ]
}

const useContent = (id) => {
  // ========================================= //
  // State
  // ========================================= //

  const [description, actions] = useItem([id, "description"])
  const [value, setValue] = useState(setContent(description))
  const [isDirty, setDirty] = useState(false)
  const changes = useRef(0)
  const updateTimer = useRef(null)
  const unmounting = useRef(null)
  const noSave = useRef(null)

  // ========================================= //
  // Effects
  // ========================================= //

  // loading new content
  useEffect(() => {
    unmounting.current = false
    setValue(setContent(description))
    // !! let's value hook know to save on unmount vs update
    return () => (unmounting.current = true)
  }, [id])

  // publish changes
  useEffect(() => {
    if (changes.current) setUpdateTimer()
    return () => {
      if (unmounting.current && changes.current) save(true)
    }
  }, [value])

  // ========================================= //
  // Functions
  // ========================================= //

  const clearUpdateTimer = () => {
    clearTimeout(updateTimer.current)
  }

  const setUpdateTimer = () => {
    clearUpdateTimer()
    // updateTimer.current = setTimeout(() => save(changes.current > 10), 1000)
    updateTimer.current = setTimeout(() => save(), 1000)
  }

  const handleChange = (changedVal) => {
    if (_.isEqual(changedVal, value)) return false

    changes.current++
    setValue(changedVal)
    setDirty(true)
  }

  const save = (publish = true) => {
    clearUpdateTimer()

    if (noSave.current) return console.log('dont save');
    if (publish) {
      changes.current = 0
      setDirty(false)
    }

    actions.setItemPath(id, "description", value, { publish })
  }
  
  const enableSave = (enable) => {
    noSave.current = !enable
  }

  // ========================================= //
  // Return Hook
  // ========================================= //

  return [value, handleChange, save, isDirty, enableSave]
}

export default useContent
