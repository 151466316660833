import _ from "lodash"
import useRedux from "../../utils/use_redux"
import * as actions from "../actions"

const useItems = (ids, getFields = [], fn) => {
  const filter = state => {
    const items = _.compact(_.map(ids, id => state.items[id]))
    const results = fn ? fn(items) : items
    return _.size(getFields)
      ? _.map(results, item => _.pick(item, getFields))
      : results
  }

  return useRedux("items", actions, false, filter)
}

export default useItems
