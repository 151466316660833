import _ from "lodash"
import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useItem } from "../../../store/items/selectors"
import ContentEditable from "react-sane-contenteditable"
import FieldLabel from "./field_label"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  width: 100%;
`

const Field = styled(ContentEditable)`
  outline: none;
  cursor: text;
  font-size: 15px;
  display: flex;
  &[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: #d2d2d2;
    font-weight: normal;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const CustomFieldText = ({
  id,
  fieldID,
  showLabel,
  defaults,
  length = 30,
  isNew,
  onBlur,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  fieldID = _.get(defaults, "fieldID", fieldID)
  const defaultValue = _.get(defaults, "value", false)
  const [value, actions] = useItem([id, "fields", fieldID, "value"])
  const [content, setContent] = useState(
    _.toString(value || defaultValue || "")
  )
  const valueRef = useRef(null)

  // ========================================= //
  // Functions
  // ========================================= //

  const handlePaste = value => {
    handleChange(false, value)
  }

  const handleChange = (event, text) => {
    setContent(text)
  }

  const save = () => {    
    const field = defaults ? defaults : { type: "text" }
    field.value = content
    actions.setFields({ id, fields: { [fieldID]: field } })
    if (onBlur) onBlur()
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      {showLabel && (
        <FieldLabel id={id} fieldID={fieldID} isNew={isNew} onBlur={onBlur} />
      )}
      <Field
        ref={valueRef}
        tagName={"div"}
        placeholder={"...add text"}
        caretPosition="end"
        content={content}
        editable={true}
        maxLength={length}
        multiLine={false}
        onChange={handleChange}
        onPaste={handlePaste}
        onBlur={save}
      />
    </Container>
  )
}

export default CustomFieldText
