import styled from "styled-components"

export const Section = styled.div`
  padding: 15px 15px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid ${(props) => props.theme.section.border};
  p {
    line-height: 20px;
  }
`
export const Modules = styled(Section)`
  align-items: flex-start;
`

export const Spacer = styled.div`
  flex: 1;
`

export const Bottom = styled(Section)`
  border-top: 1px solid ${(props) => props.theme.section.border};
  align-items: center;
  justify-content: space-between;
  & button {
    flex: 1;
    &:first-of-type {
      margin-right: 10px;
    }
  }
`
