import _ from "lodash"
import options from "../../app/reducers/conditions"

const updateItemType = item => {
  if (item.type) {
    
    delete item.type

    if (
      !item.conditions ||
      _.isArray(_.get(item, "conditions.formation")) ||
      _.isArray(_.get(item, "conditions.satisfaction"))
    ) {
      item.conditions = {
        formation: {},
        satisfaction: {
          allCompleted: options.satisfaction.allCompleted,
        },
      }
    }
  }

  return item
}

export default updateItemType
