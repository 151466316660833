const signOut = (auth, fn) => {
  auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
      fn()
    })
    .catch(error => {
      // An error happened.
      console.log(error)
    })
}

export default signOut
