import _ from "lodash"
import useRedux from "../../utils/use_redux"
import * as actions from "../actions"

const findTopParent = (items, id, path = []) => {
  if (!items[id]) return path
  path.push(id)
  const { parentIDs } = items[id]
  if (!parentIDs || !_.size(parentIDs)) return path.reverse()
  
  const parentID = parentIDs[0]
  return findTopParent(items, parentID, path)
}

const useItemsPath = (id) => {
  return useRedux("items", actions, undefined, state => {
    return id ? findTopParent(state.items, id) : []
  })
}

export default useItemsPath
