import _ from "lodash"
import React, { useRef, useEffect } from "react"
import ReactDOM from "react-dom"
import { ReactEditor, useSlate } from "slate-react"
import { Editor, Range } from "slate"
import { MenuStyled } from "./style"
import FormatButton from "./format_button"

const ToolbarElement = () => {
  // ========================================= //
  // State
  // ========================================= //

  const ref = useRef()
  const editor = useSlate()

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (!ref.current) return null

    if (editor.extended.link.is()) {
      console.log("it is a link!")
      // console.log(editor.selection);
      // const range = editor.selection.getRangeAt(0)
      // const rect = range.getBoundingClientRect()
      // console.log(rect);
    }
    if (nothingSelected() && !editor.extended.link.is()) {
      removeToolbar()
    } else {
      setToolbarPosition()
    }
  })

  // ========================================= //
  // Functions
  // ========================================= //

  const nothingSelected = () => {
    const { selection } = editor
    return (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    )
  }

  const setToolbarPosition = () => {
    const { selection } = editor
    const el = ref.current
    try {
      const domSelection = window.getSelection()
      const domRange = domSelection.getRangeAt(0)
      const rect = domRange.getBoundingClientRect()
      el.style.opacity = 1
      el.style.top = `${rect.top + window.pageYOffset + el.offsetHeight}px`
      el.style.left = `${
        rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
      }px`
    } catch (e) {
      console.log('error toolbar selection');
    }
  }

  const removeToolbar = () => {
    if (ref.current) ref.current.removeAttribute("style")
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Portal>
      <MenuStyled ref={ref}>
        {/* <FormatButton format="image" />
        <FormatButton format="item" /> */}
        {_.map(editor.plugins.toolbar, (action, key) => (
          <FormatButton key={key} {...action} />
        ))}
      </MenuStyled>
    </Portal>
  )
}

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

export default ToolbarElement
