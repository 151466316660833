import _ from "lodash"
import root from "window-or-global"
import setActiveIntention from "./set_active_intention"
import { app } from "../interface"

const appReducer = (state = app, action) => {
  state = _.cloneDeep(state)

  switch (action.type) {
    case "MULTIPLE_TABS_OPEN":
      return { ...state, multipleTabs: action.payload, loading: false }
    case "SET_LOADING":
      return { ...state, loading: action.payload.loading }
    case "LOAD_DATA":
      return { ...state, loading: true }
    case "LOADED":
      return { ...state, loading: false, loaded: true }
    case "SET_APP_STATUS":
      return { ...state, status: { ...state.status, ...action.payload.status } }
    case "SET_SETTING":
      if (action.payload.theme) {
        root.localStorage.setItem("theme", JSON.stringify(action.payload.theme))
      } else if (action.payload.notice) {
        root.localStorage.setItem("notice", action.payload.notice)
      }

      // TODO: FIX
      if (_.has(action, "payload.activeIntentions")) {
        return setActiveIntention(state, {
          payload: { reset: true },
          path: action.payload.activeIntentions,
        })
      }

      if (action.payload.path) {
        // to support value overwriting instead of merging
        const { path, value } = action.payload
        return _.set(_.cloneDeep(state), path, value)
      } else {
        return _.merge({}, state, action.payload)
      }
    case "SET_CURRENT_DOMAIN":
      root.localStorage.setItem("domain", JSON.stringify(action.payload))
      return {
        ..._.cloneDeep(app),
        currentDomain: action.payload,
      }
    case "SET_PLANNING_BOARD":
      return {
        ...state,
        planningBoard: { ...state.planningBoard, ...action.payload },
      }
    case "SET_SHOW_PLANNER":
      return { ...state, showPlanner: action.payload }
    case "SET_SHOW_SETTINGS":
      return { ...state, showSettings: action.payload }
    case "SET_MENU_ITEM":
      return { ...state, menuItem: { ...state.menuItem, ...action.payload } }
    case "SET_STATUS_BOARD_ID":
      return { ...state, statusBoardID: action.payload }
    case "SET_SHOW_MOBILE_MENU":
      return { ...state, showMobileMenu: action.payload }
    case "SET_BOARD":
      state.history.push(action.payload)
      return { ...state, board: { ...action.payload } }
    case "SET_BOARD_PREV":
      return {
        ...state,
        board: { ...(state.history.pop() || app.board) },
      }
    case "SET_ACTIVE_INTENTION":
      return setActiveIntention(state, action)
    case "SET_BIG_TRACKER_ITEM":
      return { ...state, bigTrackerItem: action.payload.id }
    case "SET_SHOW_ACTIVE":
      return { ...state, showActive: action.payload.show }
    case "SET_SHOW_QUICK_NAV":
      return { ...state, showQuickNav: action.payload.show }
    case "SET_SHOW_QUICK_ADD":
      return { ...state, showQuickAdd: action.payload.show }
    case "SET_NEW_ITEM_PARENT_ID":
      return { ...state, newItemParentID: action.payload }
    default:
      return state
  }
}

export default appReducer
