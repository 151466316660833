import _ from "lodash"
import updateItem from "./update_item"

const linkItem = (state, action) => {
  const { id, linkID, remove } = action.payload
  const item = state[id]
  const linked = state[linkID]
  if (!linked) return state

  if (remove) {
    // no other links to item found, continue with removal
    _.remove(linked.linkedIDs, (ID) => ID === id)
  } else {
    if (!_.includes(linked.linkedIDs, id)) {
      linked.linkedIDs.push(id)
    }
  }

  return updateItem(state, { payload: { fields: linked, overwrite: true } })
}

export default linkItem
