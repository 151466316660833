import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItem, useItems } from "../../../store/items/selectors"
import SetMenuItemOpen from "../../elements/set_menu_item_open"
import CustomField from "../../elements/custom_field"
import FieldType from "../../elements/custom_field/field_type"
import CustomFieldDefault from "../../elements/custom_field/field_new_default"
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  background: #e4e4e42b;
  display: flex;
  overflow: hidden;
  height: 100%;
`

const Inner = styled.div`
  margin-top: 40px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  overflow: auto;
  width: 100%;
`

const TableContainerStyled = styled(TableContainer)`
  display: flex;
  margin-bottom: 50px;
`

// ========================================= //
// Main Component
// ========================================= //

const TableView = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [view, actions] = useItem([id, "view"])
  const [itemIDs] = useItem([id, "itemIDs"])
  const [items] = useItems(itemIDs)

  const itemCols = { name: { Header: "Name", accessor: "name" } }
  const itemRows = {}
  const links = {}
  const defaultItems = {}

  _.map(items, item => {
    const row = { id: item.id, name: item.name, fields: {} }
    _.map(item.fields, field => {
      // dates return error until the date object is parsed
      // if (field.type === "date") return
      // add reference to field
      row.fields[field.name] = field.id
      // Add table column headers
      if (!itemCols[field.name] && field.name) {
        const name = _.isObject(field.name) ? field.name.id : field.name
        itemCols[name] = { Header: name, accessor: name }
        defaultItems[field.name] = {id: item.id, fieldID: field.id}
      }

      // non-text custom fields
      if (_.isObject(field.value)) {
        links[field.value.id] = links[field.value.id] || []
        links[field.value.id].push({
          id: item.id,
          name: field.name,
          path: field.value.path,
        })
      } else {
        row[field.name] = field.value
      }
    })
    itemRows[item.id] = row
  })

  const [linkedItems] = useItems(_.keys(links))

  _.map(linkedItems, item => {
    if (itemCols[item.id]) {
      itemCols[item.name] = itemCols[item.id]
      delete itemCols[item.id]
    }
    _.map(links[item.id], link => {
      itemRows[link.id][link.name] = _.get(item, link.path, false)
    })
  })

  const data = React.useMemo(() => _.values(itemRows), [itemIDs])
  const columns = React.useMemo(() => _.map(itemCols), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  // ========================================= //
  // Functions
  // ========================================= //

  const setDefaultType = (column, field) => {
    actions.updateItem({ id, view: { table: { [column]: field.type } } })
  }

  // ========================================= //
  // Render˝
  // ========================================= //

  return (
    <Container className={className}>
      <Inner>
        <TableContainerStyled component={Paper}>
          <Table stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.render("Header")}
                      <FieldType
                        id={id}
                        fieldID={false}
                        saveOnChange={false}
                        onChange={field => setDefaultType(column.id, field)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      const fieldID = cell.column.Header                      
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {fieldID === "Name" ? (
                            <SetMenuItemOpen id={row.original.id}>
                              {cell.render("Cell")}
                            </SetMenuItemOpen>
                          ) : (
                            <CustomField
                              id={row.original.id}
                              defaultSource={defaultItems[fieldID] }
                              fieldID={row.original.fields[fieldID]}
                              showLabel={false}
                            />
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainerStyled>
      </Inner>
    </Container>
  )
}

export default TableView
