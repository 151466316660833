const b64DecodeUnicode = str => {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, function(m, p) {
      var code = p
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()
      if (code.length < 2) {
        code = "0" + code
      }
      return "%" + code
    })
  )
}

const getCustomClaimTokens = auth => {
  return new Promise((resolve, reject) => {
    auth()
      .currentUser.getIdToken()
      .then(idToken => {
        // Parse the ID token.
        const customTokens = JSON.parse(b64DecodeUnicode(idToken.split(".")[1]))
        resolve(customTokens)
      })
      .catch(reject)
  })
}

export default getCustomClaimTokens
