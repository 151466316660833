import _ from "lodash"
import React, { useRef } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../store/app/selectors"
import SwitchSetting from "../elements/switch_setting"
import SetSetting from "../elements/set_setting"
import MainSearch from "../features/main_search"
import BoardMenu from "../features/board_menu"
import Notifications from "../features/notifications"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  /* border-bottom: 1px solid
    ${props =>
      props.theme.toHSL(props.theme.colors.primary, { s: -15, l: -10 })}; */
  border-bottom: 1px solid ${props => props.theme.section.border};      
  box-shadow: 0px 0px 10px -4px ${props => props.theme.section.shadow};
  top: -40px;
  transition: all 0.3s;
  /* height: 0px; */
  width: 100vw;
  margin-top: -40px;
  overflow: hidden;
  /* position: absolute; */
  ${props =>
    props.visible &&
    css`
      margin-top: 0;
      /* height: 40px; */
    `}
`

const Inner = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    flex: 1 0 0;
  }
`

const Switches = styled.div`
  display: flex;
  justify-content: flex-end;
`

// ========================================= //
// Main Component
// ========================================= //

const OptionBar = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [visible] = useApp("sections.menu")

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container className={className} visible={visible}>
      <Inner>
        <BoardMenu />
        <MainSearch />
        <Switches>
          {/* <SwitchSetting label="Completed" field="showCompleted" /> */}
          {/* <SwitchSetting label="Compact" field="showCompact" /> */}
          <Notifications />
        </Switches>
      </Inner>
    </Container>
  )
}

export default OptionBar
