import _ from "lodash"
import React from "react"
import { useApp } from "../../store/app/selectors"
import { useItem } from "../../store/items/selectors"
import IconButton from "@material-ui/core/IconButton"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import DropDownMenu from "./drop_down_menu"

// ========================================= //
// Main Component
// ========================================= //

const DownArrowButton = ({ id, parentID, listID, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, { setSetting, setMenuItem }] = useApp(false)
  const [parentIDs, actions] = useItem([id, "parentIDs"])
  const options = [
    { id: "edit", name: "Edit" },
    { id: "remove", name: "Remove" },
    { id: "delete", name: "Delete" },
  ]

  // only allow removal if more than one parent
  if (_.size(parentIDs) < 2) _.pullAt(options, 1)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleChange = option => {
    switch (option.id) {
      case "edit":
        return setMenuItem({ id, isOpen: true })
      case "remove":
        return actions.removeFromItem({ id, parentID })
      case "delete":
        return actions.deleteItem({ id, parentID })
      default:
        return
    }
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <IconButton
      className={className}
      onClick={handleClick}
      aria-label="item-menu"
    >
      <DropDownMenu
        className={className}
        options={options}
        activeIndex={false}
        onChange={handleChange}
      >
        <ArrowDropDownIcon />
      </DropDownMenu>
    </IconButton>
  )
}

export default DownArrowButton
