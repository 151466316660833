import _ from "lodash"
import React, { useState, useRef, useEffect, useContext } from "react"
import styled, { css } from "styled-components"
import { useItem, useItemsPath } from "../../../../store/items/selectors"
import { LayoutContext } from "../layout_context"
import Modal from "../../../features/modal"
import Style from "./style"
// import Checkbox from "@material-ui/core/Checkbox"

// ========================================= //
// Style
// ========================================= //

// ========================================= //
// Main Component
// ========================================= //

const Configure = ({}) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [showConfigure, setShowConfigure] = useState(false)
  const { activeBlock, setActiveBlock } = useContext(LayoutContext)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // const setField = (key, label = true) => {
  //   console.log("SETTING", key, label)
  //   setSections(
  //     _.merge({}, sections, {
  //       [field.current]: {
  //         type: "field",
  //         id: key,
  //         style: {},
  //         field: options[key],
  //         label,
  //       },
  //     })
  //   )
  //   setShowConfigure(false)
  //   field.current = false
  // }

  // ========================================= //
  // Render˝
  // ========================================= //

  return (
    <Modal open={Boolean(activeBlock)} onClose={() => setActiveBlock(false)}>
      {activeBlock && activeBlock.id}
      {/* {section.label} */}
      {/* <h3>Select field to display in this section:</h3>
      {_.map(options, (field, key) => (
        <div key={key} onClick={() => setField(key)}>
          {key} {_.get(field, "current.key") === key && "current"}
        </div>
      ))}
      Show label?
      {field.current && (
        <Checkbox
          checked={sections[field.current].label}
          onChange={() =>
            setField(field.current, !sections[field.current].label)
          }
          inputProps={{ "aria-label": "show label" }}
        />
      )} */}
    </Modal>
  )
}

export default Configure
