import _ from "lodash"
import { newItem } from "./create_item"
import updateItem from "./update_item"

const migrate = (item, items) => {
  let save = false

  // make sure all default fields are set
  item = newItem(item, false)

  _.map(item.fields, (field, key) => {
    if (!_.has(field, "type")) {
      console.log("MISSING type", key, field)
      delete item.fields[key]
      save = true
      // convert Firestore 'Timestamp' to DateTime
    } else if (field.type === "date" && _.isObject(field.value)) {
      field.value = field.value.toDate()
    }

    if (!field || !_.isObject(field)) {
      delete item.fields[key]
    } else if (!field.id) {
      field.id = key
      save = true
    }
  })

  // if (item.id === "_lkc3jum5n") {
  //   item.fields = {}
  //   save = true
  // }

  return [item, save]
}

const loadItem = (state, action) => {
  const { item } = action.payload
  if (_.isEqual(state[item.id], item)) return state

  const [migrated, shouldSave] = migrate(item, state)
  state[item.id] = migrated

  if (shouldSave) console.log("Saving migrated record...")

  return shouldSave
    ? updateItem(state, { payload: { fields: migrated, overwrite: true } })
    : state
}

export default loadItem
