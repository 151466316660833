const validateInvite = (db, tokenCollection, code, callback, errorFn) => {
  db.collection(tokenCollection)
    .doc(code)
    .get()
    .then(snapshot => {
      if (callback) callback(snapshot.data())
    })
    .catch(error => {
      errorFn
        ? errorFn(error)
        : console.log("Firebase error while loading:", error)
    })
}

export default validateInvite
