import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../store/items/selectors"
import SearchItems from "../elements/search_items"

// ========================================= //
// Style
// ========================================= //

// ========================================= //
// Main Component
// ========================================= //

const AddChildSearch = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [itemIDs, actions] = useItem([id, 'itemIDs'])
  const [parentIDs] = useItem([id, 'parentIDs'])
  const ignore = _.flatten([id, itemIDs, parentIDs])

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const filterFn = (items, { id }) => {    
    return !_.includes(ignore, id)
  }

  const addChild = (item) => {    
    const parentID = id      
    actions.addItemToParent({ id: item.id, parentID }) 
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <div>
      <SearchItems
        className={className}
        label="Add as parent to..."
        filter={filterFn}
        isMultiple={false}
        onChange={addChild}
      />
    </div>
  )
}

export default AddChildSearch
