import _ from "lodash"
import React, { useEffect, useCallback, useMemo, useState, useRef } from "react"
import styled, { css } from "styled-components"
import { Slate, Editable, withReact } from "slate-react"
import { Editor, Transforms, Range, Point, createEditor } from "slate"
import { withHistory } from "slate-history"
import { useItem } from "../../store/items/selectors"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const EditableStyled = styled(Editable)`
  color: #707783;
  font-size: 14px;
  line-height: 20px;
  flex: 1;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }
`

const Help = styled.div`
  display: flex;
  justify-items: center;
  /* border-top: 1px solid
    ${props =>
      props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -10 })}; */
  padding: 10px 0;
  opacity: 0;
  transition: all 0.3s;
  ${Container}:hover & {
    opacity: 0.4;
  }
  cursor: pointer;
  svg {
    font-size: 15px;
  }
  & div {
    transition: all 0.3s;
    font-size: 12px;
    opacity: 0;
    margin-left: 5px;
    & span {
      color: red;
      font-style: italic;
    }
  }
  &:hover {
    & div {
      opacity: 1;
    }
  }
`

const Support = styled.div`
  max-height: 0;
  overflow: hidden;
  font-size: 12px;
  transition: all 0.3s;
  background: #f7f7f7;
  padding: 0px;
  & div {
    margin: 5px 0;
  }
  ${props =>
    props.visible &&
    css`
      padding: 20px;
      max-height: 300px;
      overflow: auto;
    `}
`

// ========================================= //
// Content function
// ========================================= //

const setContent = description => {
  let initValue
  try {
    initValue = JSON.parse(description)
  } catch (e) {
    initValue = description
  }

  return _.isArray(initValue)
    ? initValue
    : [
        {
          type: "paragraph",
          children: [{ text: initValue || "" }],
        },
      ]
}

// ========================================= //
// Main Component
// ========================================= //

const Description = ({ id, listID, editable, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [description, actions] = useItem([id, "description"])
  const renderElement = useCallback(props => <Element {...props} />, [])
  const editor = useMemo(
    () => withShortcuts(withReact(withHistory(createEditor()))),
    []
  )
  const [value, setValue] = useState(setContent(description))
  const [showSupport, setShowSupport] = useState(false)
  const changes = useRef(0)
  const updateTimer = useRef(null)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    setValue(setContent(description))
  }, [id])

  useEffect(() => {
    return () => clearUpdateTimer()
  }, [])

  // ========================================= //
  // Functions
  // ========================================= //

  const clearUpdateTimer = () => clearTimeout(updateTimer.current)

  const handleChange = value => {
    clearUpdateTimer()

    changes.current++
    setValue(value)

    updateTimer.current = setTimeout(() => save(changes.current > 10), 3000)
  }

  const save = (publish = true) => {
    clearUpdateTimer()
    if (publish) changes.current = 0

    const content = JSON.stringify(value)
    const unchanged = _.isEqual(description, content)
    if (unchanged) return
    console.log("saving", "changes:", changes.current, "publish", publish)

    if (publish) {
      console.log(description);
      console.log(content);
      
    }

    actions.updateItem({ id, description: content }, publish)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <Slate editor={editor} value={value} onChange={handleChange}>
        <EditableStyled
          className={className}
          placeholder="Description..."
          onBlur={save}
          renderElement={renderElement}
          spellCheck
        />
        <Help onClick={() => setShowSupport(!showSupport)}>
          {showSupport ? (
            <>
              <HighlightOffIcon />
              <div>Hide shortcuts</div>
            </>
          ) : (
            <>
              <HelpOutlineIcon />
              <div>
                Markdown supported. <span>View shortcuts</span>
              </div>
            </>
          )}
        </Help>
        <Support visible={showSupport}>
          <strong>
            The following shortcuts followed by a "space" are supported:
          </strong>
          <div>Headings: #,##,###...</div>
          <div>List items: -, +</div>
          <div>Block quotes: ></div>
        </Support>
      </Slate>
    </Container>
  )
}

// ========================================= //
// Slate.js markdown functions
// ========================================= //

const SHORTCUTS = {
  "*": "bold",
  "-": "list-item",
  "+": "list-item",
  ">": "block-quote",
  "#": "heading-one",
  "##": "heading-two",
  "###": "heading-three",
  "####": "heading-four",
  "#####": "heading-five",
  "######": "heading-six",
}

const withShortcuts = editor => {
  const { deleteBackward, insertText } = editor

  editor.insertText = text => {
    const { selection } = editor

    if (text === " " && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)
      const type = SHORTCUTS[beforeText]

      if (type) {
        Transforms.select(editor, range)
        Transforms.delete(editor)
        Transforms.setNodes(
          editor,
          { type },
          { match: n => Editor.isBlock(editor, n) }
        )

        if (type === "list-item") {
          const list = { type: "bulleted-list", children: [] }
          Transforms.wrapNodes(editor, list, {
            match: n => n.type === "list-item",
          })
        }

        return
      }
    }

    insertText(text)
  }

  editor.deleteBackward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          block.type !== "paragraph" &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, { type: "paragraph" })

          if (block.type === "list-item") {
            Transforms.unwrapNodes(editor, {
              match: n => n.type === "bulleted-list",
              split: true,
            })
          }

          return
        }
      }

      deleteBackward(...args)
    }
  }

  return editor
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>
    case "heading-four":
      return <h4 {...attributes}>{children}</h4>
    case "heading-five":
      return <h5 {...attributes}>{children}</h5>
    case "heading-six":
      return <h6 {...attributes}>{children}</h6>
    case "list-item":
      return <li {...attributes}>{children}</li>
    case "italic":
      return <i {...attributes}>{children}</i>
    default:
      return <p {...attributes}>{children}</p>
  }
}

export default Description
