import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useUser } from "../../../store/user/selectors"
import Drawer from "../../features/drawer"

import Overlay from "../../elements/overlay"
import SignOut from "../../elements/sign_out"
import SetSetting from "../../elements/set_setting"
import DomainSelector from "../../features/domain_selector"
import ThemePicker from "../../features/theme_picker"
import MenuIcon from "@material-ui/icons/Menu"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import { signOutUser } from "../../../store/user/actions"
import { Button } from "../../elements"

// ========================================= //
// Styles
// ========================================= //

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  margin: 30px 20px 0 20px;
`

const ListItem = styled.div`
  cursor: pointer;
  padding: 15px 15px 15px 0;
`

const Bottom = styled.div`
  display: flex;
  height: 100%;
`

const BottomInner = styled.div`
  display: flex;
  align-items: flex-end;
  div {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    svg {
      padding-right: 10px;
    }
    transition: all 0.3s;
    color: ${(props) => props.theme.colors.two};
    &:hover {
      color: inherit;
    }
  }
`

// ========================================= //
// Main Component
// ========================================= //

const SideBar = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, actions] = useApp("show.sidebar")
  const [email] = useUser(["email"])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClose = () => {
    actions.setSetting({ show: { sidebar: false } })
  }

  const handleShowSetting = (name) => {
    actions.setSetting({ [name]: true })
    actions.setSetting({ show: { [name]: true } })
    handleClose()
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Drawer
      path="show.sidebar"
      side="left"
      overlay={true}
      z={300}
      disableDiscovery={true}
    >
      <Button.SetState name={"Show/hide menu"} path="show.sidebar" Icon={MenuOpenIcon} />
      <Inner>
        <ListItem>
          <DomainSelector onChange={handleClose} />
        </ListItem>
        <ListItem onClick={() => handleShowSetting("showThemePicker")}>
          Theme
        </ListItem>
        <ListItem onClick={() => alert("Coming soon...")}>
          Export items
        </ListItem>
        <ListItem onClick={() => alert("Coming soon...")}>
          Keyboard shortcuts
        </ListItem>
        <ListItem onClick={() => handleShowSetting("updateLog")}>
          Release notes
        </ListItem>
        <ListItem
          onClick={() =>
            alert(
              "Why so needy?! I had to build the damn thing. All you have to do is figure out how to use it! \n\nJust kidding. Email, text, or Slack me."
            )
          }
        >
          Support
        </ListItem>
        <Bottom>
          <BottomInner>
            <SignOut>
              <ExitToAppIcon /> {email}
            </SignOut>
          </BottomInner>
        </Bottom>
      </Inner>
    </Drawer>
  )
}

export default SideBar
