import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import SearchItems from "../../elements/search_items"

// ========================================= //
// Style
// ========================================= //

// ========================================= //
// Main Component
// ========================================= //

const AddParentSearch = ({
  id,
  parentID,
  title,
  remove = false,
  className,
  onChange,
  onClose
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [itemIDs, actions] = useItem([id, "itemIDs"])
  const [parentIDs] = useItem([id, "parentIDs"])
  const ignore = _.flatten([id, itemIDs, parentIDs])

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const filterFn = (items, { id }) => {
    return !_.includes(ignore, id)
  }

  const addToParent = (parent) => {
    actions.addItemToParent({ id, parentID: parent.id })

    if (remove && parentID) actions.removeFromItem({ id, parentID })

    if (onChange) onChange(parent)

    if (onClose) onClose()
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <SearchItems
      className={className}
      label={title || "Add to parent..."}
      filter={filterFn}
      isMultiple={false}
      onChange={addToParent}
    />
  )
}

export default AddParentSearch
