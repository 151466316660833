import _ from "lodash"
import React, { useState } from "react"
import styled from "styled-components"
import NewItem from "./new_item"
import NewItemButton from "../elements/new_item_button"

// ========================================= //
// Styles
// ========================================= //

const Add = styled.div`
  display: flex;
  flex-direction: column;
`

// ========================================= //
// Main Component
// ========================================= //

const AddToList = ({ listID, intentionID, fields, container, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [showNewItem, setShowNewItem] = useState(false)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Add className={className}>
      <NewItem
        key={listID}
        show={showNewItem}
        onBlur={() => setShowNewItem(false)}
        parentID={intentionID}
        fields={fields}
      />
      <NewItemButton
        listID={listID}
        container={container}
        setShow={setShowNewItem}
      />
    </Add>
  )
}

export default AddToList
