import styled, { css } from "styled-components"
import Name from "../../elements/name"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const NameHeader = styled(Name)`
  display: flex;
  flex: 1;
  /* width: 100%; */
  margin: 10px 20px 10px 20px;
  line-height: 25px;
`