import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"

const NavTo = ({ id, propagate = true, className, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [ids, actions] = useApp("views.board.path")

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = (e) => {
    if (!propagate) {
      e.preventDefault()
      e.stopPropagation()
    }
    const index = _.indexOf(ids, id)
    const path = index > -1 ? _.take(ids, index + 1) : [...ids, id]
    actions.setSetting({ path: "views.board.path", value: path })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  )
}

export default NavTo
