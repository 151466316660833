import _ from "lodash"
import { removeNullFields } from "../../../utils"

const updateDomain = (state, action) => {
  const { fields, overwrite } = action.payload
  const { id } = fields
  console.log("updating", fields)
  let domain = overwrite ? fields : _.merge({}, state[id], fields)
  
  // setting any field to null will inform the update to remove it
  domain = removeNullFields(_.cloneDeep(domain))
  
  domain.updatedAt = Date.now()
  state[id] = domain

  // make changes available to firebase middleware
  _.set(action, ["changes", "domains", id], domain)

  return state
}

export default updateDomain
