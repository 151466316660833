import _ from "lodash"
import { Editor, Transforms, Text, Node, Range } from "slate"
import Leaf from "./leaf"
import Element from "./element"
import LinkModal from "./link_modal"
import LinkIcon from "@material-ui/icons/Link"

// ========================================= //
//  Main component
// ========================================= //

const withLinks = (editor) => {
  const type = "link-to-item"

  // get editor functions to extend
  const {
    isInline,
    isVoid,
    extended: {
      onCommand,
      format: { toggle },
    },
  } = editor

  // ========================================= //
  // Register render logic
  // ========================================= //

  // set the render element
  const render = (props) => props.leaf[type] && props.leaf.link && Leaf
  editor.plugins.leaves[type] = render
  editor.plugins.elements[type] = Element

  // set plugin render/support component
  editor.plugins.components[type] = LinkModal

  // add toolbar action
  editor.plugins.toolbar[type] = { format: type, Icon: LinkIcon }

  // ========================================= //
  // Extend existing editor functions
  // ========================================= //

  const isExpandableLink = (element) => {
    return element.type === type && _.get(element, "link.expand")
  }

  editor.isInline = (element) => {
    return isExpandableLink(element) ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return isExpandableLink(element) ? true : isVoid(element)
  }

  editor.extended.onCommand = (event) => {
    if (event.key === "l") editor.extended.format.toggle(type)

    // call default function
    onCommand(event)
  }

  editor.extended.format.toggle = (format) => {
    // call default function
    if (format !== type) return toggle(format)

    if (editor.extended.format.has(type)) {
      editor.extended.link.showModal(true)
    } else {
      // created in hooks.js
      editor.extended.link.showModal(true)
    }
  }

  const __runCB = (link, state) => {
    if (!changeCBs[link.itemID]) return
    _.map(changeCBs[link.itemID], (fn) => fn(state))

    editor.extended.link.current = state ? link : false
  }

  editor.extended.selection.onChange(type, (selection) => {
    const lastLink = editor.extended.link.current
    // if current leaf is a link
    if (editor.extended.link.is()) {
      // get link data
      const link = editor.extended.link.get()
      // if focused link has changed
      if (!_.isEqual(link, lastLink)) {
        // tell last link it's not selected any more
        if (lastLink) __runCB(lastLink, false)
        // tell new link it's selected
        __runCB(link, true)
      }
      // select link te{xt to reveal format menu
      if (link.expand) {
        console.log("select everything")
        editor.extended.selectNodeText()
      }
    } else if (lastLink) {
      // tell last link it's not selected
      __runCB(lastLink, false)
    }
  })

  // ========================================= //
  // Add editor functions
  // ========================================= //

  editor.extended.link = {}

  const changeCBs = {}
  editor.extended.link.onLinkChange = (itemID, refID, fn) => {
    if (fn) {
      _.set(changeCBs, [itemID, refID], fn)
    } else {
      delete changeCBs[itemID][refID]
    }
  }

  editor.extended.link.is = () => {
    return editor.extended.format.get(type)
  }

  editor.extended.link.get = (field) => {
    const formats = editor.extended.format.getAll()
    const text = editor.extended.getSelectedText()
    const all = { ...formats.link, text }

    return field ? all[field] : all
  }

  /**
   * Convert a node into a link
   * @param {object} link { itemID: 'string', expand: true }
   * @param {array} location slate.js location path
   */
  editor.extended.link.make = (link, location) => {
    Transforms.setNodes(
      editor,
      { type, link },
      { match: (n) => Editor.isInline(editor, n) }
    )
    // move the cursor past the node
    Transforms.move(editor)
    // add space in node after link
    Transforms.insertText(editor, " ")
    // move back closer to the link
    // Transforms.move(editor, { reverse: true })

    console.log(editor.selection)

    // Transforms.setNodes(editor, { type, link }, { at: location })
  }
  /** */
  editor.extended.link.create = (link, location) => {
    console.log("creating link:", link)
    __modify(link, location)
  }

  editor.extended.link.remove = (location) => {
    __modify(null, location)
  }

  // ========================================= //
  //  Supporting functions
  // ========================================= //

  const __modify = (link = null, location) => {
    if (link && link.expand) return __modifyExpand(link, location)

    const options = { match: (n) => Text.isText(n), split: true }
    if (location) options.at = location
    editor.extended.setType(type)
    Transforms.setNodes(editor, { [type]: !!link || null, link }, options)
  }

  const __modifyExpand = (link, location) => {
    // insert a new node to show the item search
    const mention = { type, link, children: [{ text: " " }] }
    Transforms.insertNodes(editor, mention)
  }

  // ========================================= //
  // Return extended editor
  // ========================================= //

  return editor
}

export default withLinks
