import _ from "lodash"
import React from "react"
import { useItem, useItems } from "../../store/items/selectors"

// ========================================= //
// Main Component
// ========================================= //

const ChildCount = ({ id, tagName = "span", className, children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [ids] = useItem([id, "itemIDs"], (ids, state) =>
    _.filter(ids, id => !state.items[id].isDeleted)
  )
  const [completedIDs] = useItems(ids, ["id"], (items) => {    
    return _.filter(items, item => item.completed)
  })  

  const total = _.size(ids)
  const completed = _.size(completedIDs)

  // ========================================= //
  // Render
  // ========================================= //

  const CustomTag = `${tagName}`

  return (
    <CustomTag className={className}>
      {total ? (
        <>
          {completed} / {total}
        </>
      ) : (
        <>0</>
      )}
      {children && <span>{children}</span>}
    </CustomTag>
  )
}

export default ChildCount
