const getSettings = (url) => {
  return {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url,
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: "com.example.ios",
    // },
    // android: {
    //   packageName: "com.example.android",
    //   installApp: true,
    //   minimumVersion: "12",
    // },
    // dynamicLinkDomain: "localhost",
  }
}

const signIn = (auth, url, email, onSuccess, onError) => {
  auth()
    .sendSignInLinkToEmail(email, getSettings(url))
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      onSuccess()
    })
    .catch(error => {
      console.log(error)

      // Some error occurred, you can inspect the code: error.code
      onError(error)
    })
}

export default signIn
