import _ from "lodash"
import React from "react"
import { useItem, useItems } from "../../../store/items/selectors"

const usePhilosopher = (philosophersID, philosopherName) => {
  console.log(philosophersID, philosopherName)

  const [philosopherIDs, { createItem }] = useItem([philosophersID, "itemIDs"])
  const [philosophers] = useItems(philosopherIDs, ["id", "name"])
  const philosopher = _.filter(
    philosophers,
    ({ name }) => name === philosopherName
  )[0]

  const createPhilosopher = () => {
    createItem({
      position: 0,
      parentIDs: [philosophersID],
      name: philosopherName,
    })
  }

  return [philosopher, createPhilosopher]
}

export default usePhilosopher
