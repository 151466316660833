import _ from "lodash"
import root from "window-or-global"
import React, { useState } from "react"
import { useUser } from "../../../store/user/selectors"
import isEmail from "validator/lib/isEmail"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

const Content = styled.div`
  margin: 20px;
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 30px;
  font-weight: bold;
  div {
    font-style: italic;
    font-weight: normal;
  }
`

const StyledButton = styled(Button)`
  height: ${(props) => (props.visible ? "60px" : 0)};
  overflow: hidden;
  transition: all 0.3s;
  color: #607d8b;
  border-color: ${(props) => (props.visible ? "#607d8b" : "white")};
  margin-top: 20px;
`

const Input = styled(TextField)`
  margin: 10px 0 0 0;
  width: 100%;
  box-sizing: content-box;
`

const SignIn = ({ children }) => {
  // const user = useSelector(state => state.user)
  const [user, { signInUser }] = useUser(false)

  const [email, setEmail] = useState("")
  const [valid, setValid] = useState(0)
  const [message, setMessage] = useState("beep boop...")
  const [submitted, setSubmitted] = useState(false)

  const handleInput = ({ target: { value } }) => {
    setEmail(_.trim(value))
    setValid(isEmail(email) ? 1 : 0)
  }

  const onFailed = (msg) => {
    setSubmitted(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!valid) return false
    setSubmitted(true)
    signInUser({ email })
    setMessage(
      <>
        A sign in link has been emailed to you.
        <div>(You can close this tab)</div>
      </>
    )

    // STOP AUTO/LISTENING
    
    // firebase.signIn(
    //   email,
    //   () => {
    //     root.localStorage.setItem("emailForSignIn", email)
    //     setMessage("Check your email for your sign in link.")
    //   },
    //   error => {
    //     setMessage("Something went wrong!" + error.code)
    //   }
    // )
  }

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit(event)
    }
  }

  return (
    <Content>
      {submitted ? (
        <Message>{message}</Message>
      ) : (
        <form noValidate autoComplete="off" onKeyDown={handleEnter}>
          <Input
            autoFocus
            placeholder="Email"
            value={email}
            onChange={handleInput}
          />
          <StyledButton
            visible={valid}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Submit
          </StyledButton>
        </form>
      )}
    </Content>
  )
}

export default SignIn
