import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { showOnCardHover, verticalCenter } from "../../elements/base_item"
import SetActive from "../../elements/set_active"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"

// ========================================= //
// Style
// ========================================= //

const hoverColor = css`
  @media (hover: hover) {
    &:hover {
      color: rgb(93, 149, 171);
      background: none;
    }
  }
`

const SetActiveStyled = styled(SetActive)`
  grid-area: arrow;
  height: 100%;
  ${verticalCenter};
  ${showOnCardHover};
  ${hoverColor}
  opacity: ${(props) => (props.expanded || props.isOpen ? 1 : 0)};
  transition: all 0.3s;
  transform: rotate(90deg);
  ${(props) =>
    props.expanded &&
    css`
      transform: rotate(-90deg);
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const ExpandArrow = ({ id, isOpen, parentID, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [expanded] = useApp(["activeIntentions"], (ids) => _.last(ids) === id)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <SetActiveStyled
      navToID={expanded ? false : id}
      id={id}
      expanded={expanded}
      isOpen={isOpen}
    >
      <KeyboardArrowRightIcon />
    </SetActiveStyled>
  )
}

export default ExpandArrow
