import React, { useState, useEffect, useLayoutEffect } from "react"
import Anonymous from "../../sections/anonymous"
import Desktop from "../desktop"
import Mobile from "../mobile"
import { useUser } from "../../../store/user/selectors"
import InitializeData from "./initialize_data"

// ========================================= //
// Main Component
// ========================================= //

const DeviceContent = () => {
  // ========================================= //
  // State
  // ========================================= //

  const [authenticated] = useUser(["authenticated"])
  const [isMobile, setIsMobile] = useState(false)

  // ========================================= //
  // Render
  // ========================================= //

  return authenticated ? (
    <InitializeData>
      {isMobile ? <Mobile /> : <Desktop />}
    </InitializeData>
  ) : (
    <Anonymous />
  )
}

export default DeviceContent
