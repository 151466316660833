import _ from "lodash"
import React from "react"
import styled from "styled-components"
import { useApp } from "../../store/app/selectors"

// ========================================= //
// Style
// ========================================= //

const Setting = styled.div`
  cursor: pointer;
  /* remove ios tap highlight */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`
const SettingSpan = styled.span`
  cursor: pointer;
  /* remove ios tap highlight */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

// ========================================= //
// Main Component
// ========================================= //

const SetSetting = ({
  path,
  value,
  toggle,
  children,
  className,
  span = false,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [current, { setSetting }] = useApp(path)
  const active = current === value || (current && _.isBoolean(current))

  // add active prop to children
  // const elements = _.map(React.Children.toArray(children), (element) =>
  //   React.cloneElement(element, { active: active ? 1 : 0 })
  // )

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = (event) => {
    const next = toggle ? !(current || false) : active ? false : value
    setSetting(_.set({}, path, next))
  }

  // ========================================= //
  // Render
  // ========================================= //

  const Tag = span ? SettingSpan : Setting

  return (
    <Tag
      className={[className, (active && "active") || ""].join(" ")}
      onClick={handleClick}
    >
      {children}
    </Tag>
  )
}

export default SetSetting
