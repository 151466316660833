import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import BaseItem from "./base_item"

const Base = styled(BaseItem)`
  width: ${(props) => props.width}px;
  grid-template-columns: 20px auto 20px 50px;
  margin: 0;
  border-radius: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-right: none;
  border-left: none;
  border-top: none;
  box-shadow: none;
  cursor: pointer;
  &:active {
    z-index: 10;
  }
  max-height: 400px;
  transition: all 0.3s;
  ${(props) =>
    props.isHeader &&
    css`
      transition: all 1s;
    `}

  ${(props) =>
    props.inTransition &&
    !props.isHeader &&
    css`
      max-height: 0;
      opacity: 0;
    `}
  ${(props) =>
    props.isHeader &&
    css`
      transition: padding 0.2s;
      transition: position 1s;
      z-index: 100;
      padding: 10px 0;
      font-size: 12px;
      font-weight: bold;
    `}
  -webkit-tap-highlight-color: rgba(0,0,0,0);
`

export default Base