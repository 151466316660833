import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ScrollShadow from "../../elements/effects/scroll_shadow"
import { motion, useDragControls, useAnimation } from "framer-motion"

// ========================================= //
// Style
// ========================================= //

const Container = styled(motion.div)`
  display: flex;
  flex-grow: 1;
  /* height: 200px; */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  /* border: 1px blue solid; */
`

// const ListStyle = styled(ScrollShadow)`
const ListStyle = styled.div`
  border: 1px blue dashed;
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  /* height: auto; */
  /* height: 100%; */
  padding-bottom: 300px;
  overflow: auto;
  padding: 0;
  transition: all 0.3s;
  background: ${(props) => (props.isDraggingOver ? "#e7ebec" : "white")};
  /* transform: translate3d(0, 0, 0); */
  /* overflow-y: scroll; */
  /* -webkit-transform: translate3d(0, 0, 0); */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    /* display: none; Safari and Chrome */
  }
  /* padding-top: -1px;
  margin:1px; */
  /* overflow: scroll; */
  /* -webkit-overflow-scrolling: touch; */
  /* Show this only when scrolled below top...BUT HOW? */
`

// &::-webkit-scrollbar {
//   display: none; /* Safari and Chrome */
// }
// ========================================= //
// Main Component
// ========================================= //

const List = ({
  listID,
  width,
  parentID,
  childID,
  itemIDs,
  itemParams,
  ItemComp,
  className,
  isDragDisabled = false,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, actions] = useItem(false)
  const dragControls = useDragControls()
  const controls = useAnimation()

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const onDragEnd = (result) => {
    const { source, destination } = result
    const position = destination.index
    // const { id } = JSON.parse(result.draggableId)
    const id = result.draggableId

    if (source.droppableId === destination.droppableId) {
      //console.log("same intention and status", { id, parentID, position })
      actions.reorder({ id, parentID, position })
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    outline: "none",
    padding: 0,
    margin: `0 0 0 0`,
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const handleWarning = (e) => {
    if (isDragDisabled) {
    }
    e.preventDefault()
    return false
  }

  async function warn() {
    await controls.start({ scale: 0.95 })
    return await controls.start({ scale: 1 })
  }

  // ========================================= //
  // Render
  // ========================================= //

  // return (
  // <div
  //   style={{
  //     height: "100%",
  //     background: "red",
  //     overflow: "scroll",
  //     overflowScrolling: "touch",
  //     WebkitOverflowScrolling: "touch",
  //     // transform: "translate3d(0,0,0)",
  //     // WebkitTransform: "translate3d(0,0,0)"
  //     // position: "fixed",
  //   }}
  // >
  // <ListStyle data-body-scroll-lock-ignore>
  //   {itemIDs.map((id, index) => (
  //     <div key={index} style={getItemStyle(false, {})}>
  //       <Item id={id} parentID={parentID} index={index} width={width} />
  //     </div>
  //   ))}
  // </ListStyle>
  //)

  return (
    <Container
      // draggable="true"
      // onDragStart={handleWarning}
      // whileHover={{ scale: 1.1 }}
      // whileTap={{ scale: 1.5 }}
      animate={controls}
      drag
      dragControls={dragControls}
      onDragStart={(e, info) => {
        console.log("drag started")
        // Check if event doesn't originate in the "handle" element
        if (!e.target.classList.contains("drag-handle")) {
          // Stop the drag
          dragControls.componentControls.forEach((entry) => {
            // be sure to pass along the event & info or it gets angry
            entry.stop(e, info)

            if (isDragDisabled) {
              warn()
            }
          })
        }
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              data-body-scroll-lock-ignore={true}
              // isDraggingOver={snapshot.isDraggingOver}
            >
              {_.map(itemIDs, (id, index) => (
                <Draggable
                  key={id}
                  draggableId={id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ItemComp
                        id={id}
                        childID={childID}
                        parentID={parentID}
                        index={index}
                        width={width}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

List.propTypes = {
  /**
   * An array of itemIDs to display
   */
  itemIDs: PropTypes.array.isRequired,
}

List.defaultProps = {
  itemIDs: [],
}

export default List
