import _ from "lodash"
import React from "react"
import { useApp } from "../../../store/app/selectors"
import { useDomains } from "../../../store/domains/selectors"
import { useItem, useItems } from "../../../store/items/selectors"
import { useItemsByStatusID } from "../../../store/items/selectors"
import Column from "./column"
import ColumnNew from "./column_new"
import { CustomFieldSearch } from "../../elements/custom_field"

// ========================================= //
// Main Component
// ========================================= //

const ColumnsByField = ({ id, parentID, className }) => {
 
  // ========================================= //
  // State
  // ========================================= //

  const sourcePath = "views.board.sourceID"
  const [sourceID, actions] = useApp(sourcePath)
  const [selectedIDs] = useItem([sourceID, "itemIDs"])
  const [itemIDs] = useItem([id, "itemIDs"])
  const [items] = useItems(itemIDs)

  // ========================================= //
  // Functions
  // ========================================= //

  const setSource = sourceID => {
    console.log("setting source", id)
    actions.setSetting(_.set({}, sourcePath, sourceID))
  }

  // ========================================= //
  // Rendering
  // ========================================= //

  return (
    <>
      {!sourceID && (
        <CustomFieldSearch
          // id={id}
          // fieldID={fieldID}
          // link={value}
          isOpen={!sourceID}
          type={"select"}
          onSave={setSource}
          // onClose={closeModal}
        />
      )}

      <ColumnByField
        {...{
          id,
          field: { id: false, sourceID },
          itemIDs,
          items,
          className,
        }}
      />

      {_.map(selectedIDs, (fieldID, key) => (
        <ColumnByField
          key={key}
          {...{
            id,
            field: { id: fieldID, sourceID },
            itemIDs,
            items,
            className,
          }}
        />
      ))}
      {/* <ColumnNew parentID={parentID} listID={} params={} /> */}
    </>
  )
}

// ========================================= //
// Supporting Component
// ========================================= //

const ColumnByField = ({ id, headerID, field, itemIDs, items, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [, actions] = useItem(false)
  const fieldPath = ["fields", field.sourceID, "value", "id"]
  const hasField = item => field.id === _.get(item, fieldPath, false)
  itemIDs = _.filter(items, hasField)
  itemIDs = _.map(itemIDs, "id")

  // for setting, reordering, and creating new items with this field
  const fields = {
    [field.sourceID]: {
      id: field.sourceID,
      type: "select",
      source: {
        id: field.sourceID,
        path: "itemIDs",
      },
      value: {
        id: field.id,
        path: "name",
      },
    },
  }

  // ========================================= //
  // Functions
  // ========================================= //

  const handleDrop = ({ item, position }) => {
    // set the field
    actions.setFields({ id: item.id, fields })

    // and then reorder the items relative to the custom field
    actions.reorder({ id: item.id, parentID: id, position, field })
  }

  // ========================================= //
  // Rendering
  // ========================================= //

  return (
    <Column
      {...{
        headerID: headerID || field.id,
        listID: field.id,
        parentID: id,
        newItemParentID: id,
        itemIDs,
        onDrop: handleDrop,
        params: { fields },
        className,
        showHeaderNav: false
      }}
    />
  )
}

export default ColumnsByField
