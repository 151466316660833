import _ from "lodash"
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
// import { parsePaper } from "./parse_paper"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  opacity: 0;
  transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      max-height: inherit;
    `}
`

const Header = styled("h2")`
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s;
    &:hover {
      opacity: 1;
    }
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Results = ({ id, philosophersID, papersID, results }) => {
  // ========================================= //
  // State
  // ========================================= //

  const fields = results//parsePaper(results, index)

  // ========================================= //
  // Effects
  // ========================================= //
  
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container show={fields}>
      <Header>
        Definition
      </Header>
      <div> 
        {fields}
      </div>
    </Container>
  )
}

export default Results
