import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useDomains } from "../../../store/domains/selectors"
import { useItem } from "../../../store/items/selectors"

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  width: 265px;
  height: 300px;
  margin-top: 60px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border: 1px dashed black;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const BlankStatusColumn = ({ className, domainID, intentionID }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [tempID] = useItem([intentionID, "statusTempID"])
  const [statusTemp] = useDomains([
    domainID,
    "statusTemps",
    tempID || "default",
  ])

  // ========================================= //
  // Render
  // ========================================= //

  return <Container className={className}>add new status</Container>
}

export default BlankStatusColumn
