import _ from 'lodash'
import styled, { css } from "styled-components"

export const SuggestionContainer = styled.div`
  position: absolute;
  z-index: 1000;
  top: -9999px;
  left: -9999px;
  /* padding: 3px; */
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  width: 300px;
`

export const Option = styled.div`
  padding: 15px 10px;
  color: ${(props) =>
    _.get(props, "theme.element.item.text", "hsla(0, 0%, 25%, 0.88)")};
  background: ${(props) => (props.selected ? "#54575a" : "transparent")};
  border-bottom: 1px solid ${(props) => props.theme.section.border};
  font-size: 12px;
  color: ${(props) => (props.selected ? "white" : "inherit")};
  cursor: pointer;
  transition: all 0.1s;
  line-height: 18px;
  &:hover {
    background: #54575a;
    color: white;
  }
`
export const Container = styled.span`
  display: inline-block;
  /* min-width: 100px; */
  /* border-bottom: 1px dotted ${(props) =>
    props.selected ? "black" : "red"}; */

${(props) =>
  !props.selected &&
  css`
      border: 1px red dotted;
      }
    `}
  ${(props) =>
    props.empty &&
    css`
      &::before {
        content: 'Search items...'; // ${(props) => props.placeholder || "search"};
        color: #d7d7d7;
        font-style: italic;
      }
    `}
`
