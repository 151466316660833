import { take, takeEvery } from "redux-saga/effects"
import fetchItems from "./fetch_items"
import manageActions, { runActions } from "./item_actions"
import timer from "./timer"

function* itemsSagas() {
  // initially loaded items
  yield takeEvery("FETCH_ITEMS", fetchItems)
  // initially loaded items
  // yield takeEvery("LOAD_ITEMS", manageActions)
  // updated item
  // yield takeEvery("LOAD_ITEM", manageActions)
  // start actions timer
  // yield takeEvery("FETCH_ITEMS", timer)
  // listen for timer events
  // yield takeEvery("MINUTE", runActions)
}

export default itemsSagas
