const conditions = {
  formation: {
    repeats: {
      id: "repeats",
      type: 'formation',
      name: "Repeats daily",
      createdAt: false,
      lastRanAt: false,
      settings: { frequency: "daily" },
    },
    // { id: "include", name: "Automatically add intentions that..." },
  },
  satisfaction: {
    allCompleted: {
      id: "allCompleted",
      type: 'satisfaction',
      name: "All nested intentions satisfied",
      createdAt: false,
      lastRanAt: false,
      settings: {},
    },
  },
}

export default conditions
