import styled, { css, keyframes } from "styled-components"

const loadEffect1 = keyframes`
  0% {
    opacity: 0;
    margin-top: 4px;
  }

  50% {
    margin-top: 0px;
  }

  to {
    opacity: 1
  }
`

const loadEffect2 = keyframes`
  0% {
    opacity: 0;
    margin-top: 5px;
  }

  50% {
    margin-top: 0px;
  }

  to {
    opacity: 1
    margin-top: 0px;
  }
`
// alt keyframes to force browser to replay animation
export const effect = css`
  animation: ${(props) => (props.reload ? loadEffect1 : loadEffect2)} 0.3s
    linear;
`
