import _ from "lodash"
import loadItem from "./load_item"
import createItem from "./create_item"
import addToItem from "./add_to_item"
import { searchDB } from "../search"

const loadItems = (state, action) => {
  const { items } = action.payload

  const orphans = []
  _.each(items, (item) => {
    state = loadItem(state, { payload: { item } })
    if (!_.size(state[item.id].parentIDs)) orphans.push(item.id)
  })

  // state = addStatusItems(state)

  //manageOrphans(state, orphans)

  // add items to search index
  _.each(state, (item) => item && searchDB.add(item.id, item.name))

  return state
}

const manageOrphans = (state, orphans) => {
  if (!_.size(orphans)) return

  console.log("orphans found")

  if (!state.orphans) {
    state = createItem(state, {
      payload: {
        clean: false,
        fields: { id: "orphans", name: "orphans", parentIDs: ["intentions"] },
      },
    })
  }

  _.each(orphans, (id) => {
    state = addToItem(state, { payload: { id, parentID: "orphans" } })
  })
}

const addStatusItems = (state) => {
  if (!state.statuses) {
    state = createItem(state, {
      payload: {
        clean: false,
        fields: { id: "statuses", name: "Status" },
      },
    })

    _.each(["Todo", "Doing", "Done"], (name) => {
      state = createItem(state, {
        payload: {
          fields: { name, parentIDs: ["statuses"] },
        },
      })
    })
  }

  return state
}

export default loadItems
