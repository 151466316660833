import _ from "lodash"
import setStatus from './set_status'
import manageActiveIntentions from './manage_active_intentions'

const appMiddleware = store => next => action => {
  const results = next(action)

  // set app status based on store reducers
  setStatus(store, action)
  
  // manage active intention blocks
  manageActiveIntentions(store, action)

  return results
}

export default appMiddleware
