import _ from "lodash"
import React, { forwardRef, useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import useResizeAware from "react-resize-aware"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
  width: 100%;
  outline: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &:before {
    /* This wouldn't show up unless I added a character */
    content: " .";
    /* ... so hide the character */
    color: transparent;
    position: sticky;
    top: 0;
    height: ${({ height }) => height}px;
    width: 100%;
    z-index: 100;
    /* width: ${(props) => props.width}px; */
    transition: all 0.3s;
    opacity: 1;
    z-index: 0;
    /* background: linear-gradient(to bottom, #909090, rgba(21, 21, 21, 0)); */
    ${({ border, atTop, theme }) =>
      border &&
      !atTop &&
      css`
        border-top: 1px solid ${theme.section.border};
      `}
    /* border-top: 2px black solid; */
    /* background: radial-gradient(ellipse at center top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 70%);  */
    /* background: radial-gradient(ellipse at center top,rgb(185 185 185 / 65%) 0%,rgba(0,0,0,0) 70%); */
    /* background: radial-gradient(ellipse at center top,rgb(228 228 228 / 65%) 0%,rgba(0,0,0,0) 70%); */
    /* background: radial-gradient(ellipse at center top,rgba(228, 228, 228, 0.65) 0%,rgba(0,0,0,0) 70%); */
    background: radial-gradient(ellipse at center top, rgba(228, 228, 228, 0.65), rgba(225, 225, 225, 0) 69.33%)
    ${(props) =>
      props.atTop &&
      css`
        opacity: 0;
      `}
  }
  /* ${({ offset, height }) =>
    offset &&
    css`
      & > :first-child {
        margin-top: -${height}px;
      }
    `} */
`

const Inner = styled.div`
  ${({ offset, height }) =>
    offset &&
    css`
      margin-top: -${height}px;
    `}
`

// ========================================= //
//  Main component
// ========================================= //

const ScrollShadow = forwardRef(
  ({ children, offset, height, border, ...props }, ref) => {
    // ========================================= //
    // State
    // ========================================= //

    const [atTop, setAtTop] = useState(true)
    // const [resizeListener, sizes] = useResizeAware()

    // ========================================= //
    // Functions
    // ========================================= //

    const checkScroll = (e) => {
      const isTop = e.target.scrollTop < 10
      const hasChanged = isTop !== atTop
      if (hasChanged) setAtTop(isTop)
    }

    // ========================================= //
    // Render
    // ========================================= //

    // console.log(sizes);

    return (
      <Container
        ref={ref}
        atTop={atTop ? 1 : 0}
        onScroll={checkScroll}
        height={height}
        border={border}
        offset={offset ? 1 : 0}
        {...props}
      >
        <Inner height={height} offset={offset ? 1 : 0}>
          {children}
        </Inner>
      </Container>
    )
  }
)

// ========================================= //
// Define prop types and defaults
// ========================================= //

ScrollShadow.propTypes = {
  /**
   * The height of the shadow to cast
   */
  height: PropTypes.number,
  /**
   * Should a border?
   */
  border: PropTypes.bool,
  /**
   * Should the first div be offset with a negative margin to slide under shadow?
   */
  offset: PropTypes.bool,
}

ScrollShadow.defaultProps = {
  height: 10,
  border: false,
  offset: true,
}

export default ScrollShadow
