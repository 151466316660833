import _ from "lodash"
import React from "react"
import styled from "styled-components"
import { useItem } from "../../store/items/selectors"
import { useApp } from "../../store/app/selectors"
import { useDomains } from "../../store/domains/selectors"
import DropDownMenu from "./drop_down_menu"

// ========================================= //
// Style
// ========================================= //

const DropDownMenuStyled = styled(DropDownMenu)`
  background: white;
  border-radius: 5px;
  margin: 6px 10px;
  cursor: pointer;
  transition: all 0.2s;
  & span {
    color: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -0, l: -20})};
  }
  &:hover {
    background: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -0})};
    color: ${props => props.theme.toHSL(props.theme.colors.primary, {s: -20, l: -40})};
    span,
    div {
      background: none;
    }
  }
  & * {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 10px;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const StatusTagSelector = ({ id, className }) => {
  
  // ========================================= //
  // State
  // ========================================= //

  // This should all be combined into a simple selector
  const [{ statusID }, { setStatusID }] = useItem([id])
  const [domainID] = useApp(["currentDomain", "id"])
  const [options] = useDomains([domainID, "statusTemps"], statuses => {
    return statuses.default
  })
  const index = _.findIndex(options, { id: statusID })

  // ========================================= //
  // Functions
  // ========================================= //

  const handleChange = status => {
    setStatusID({ id, statusID: status.id })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <DropDownMenuStyled
      className={className}
      options={options}
      activeIndex={index}
      onChange={handleChange}
    />
  )
}

export default StatusTagSelector
