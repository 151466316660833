import _ from "lodash"
import FlexSearch from "flexsearch"

/**
 * Kind of hacky, but the main redux search project looks abandoned
 */

const searchDB = FlexSearch.create("speed", {
  async: true,
})

export { searchDB }
