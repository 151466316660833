import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects"
import firebase from "../../firebase"

function* signOut(action) {
  try {
    console.log("signOut saga called", action)
    firebase.signOut(() => {
      console.log("signed out")
    })
  } catch (error) {}
}

export default signOut
