import _ from "lodash"
import withLinks from "./with_links"
import withAutoLinks from './with_auto_links'
import withInlineSearch from './with_inline_search'

// ========================================= //
//  Main component
// ========================================= //

const withItems = (editor) => {

  // ========================================= //
  // Register sub-plugins
  // ========================================= //

  return withInlineSearch(withAutoLinks(withLinks(editor)))
}

export default withItems
