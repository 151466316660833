import styled from "styled-components"
import Button from "@material-ui/core/Button"

export const LinkOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 5px 0;
  border-bottom: 1px solid ${(props) => props.theme.section.border};
  &:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: -5px;
  }
  .MuiTypography-root {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
  }
`

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Submit = styled(Button)`
  margin-top: 20px;
`
