import _ from "lodash"
import firebase from "../"

const analyticsMiddleware = store => next => action => {
  const analytics = firebase.analytics()

  if (action.type === "SET_USER" && action.payload.authenticated) {
    // add the user id to all analytic events
    analytics.setUserId(action.payload.id)
    analytics.logEvent("login", {})
  }
  else if (action.type === "CREATE_ITEM") {
    const total_items = _.size(store.getState().items)
    analytics.logEvent("create_item", {
      total_items
    })
  }

  return next(action)
}

export default analyticsMiddleware
